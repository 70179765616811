// React && Router-Dom
import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { requestMetaData } from '../../redux/action'
import { createStructuredSelector } from 'reselect'
import { selectNome } from '../../redux/checkout/checkout.selector'
// Components
import { PageTitle, Jumbotron, CardCotacao, DividerText, ButtonOnsurance } from '../../components'
// FontAwesom
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBarcode, faCreditCard, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// Ant Design
import { Row, Col, message } from 'antd'
// SCSS
import './payment-method.style.scss'

/**
 * Página de escolha do método de pagamento
 */

// Rota: /payment-method
const PaymentMethodPage = ({ history, requestMetaData, nome }) => {

  useEffect(() => {
    document.title = 'Método de pagamento - Onsurance'
    if (nome === '') {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      })
      history.replace('/');
    }
  },[nome, history])

  if(nome === '') return <></>
  return (
    <Row className="payment-method-page">
      <Col xs={24}>
        <PageTitle>
          Método de Pagamento
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          Selecione o meio desejado: <br />Realize os seus pagamentos à vista ou parcele em até 12x.
        </Jumbotron>
      </Col>
      <Col xs={24}>
        <div className="section">
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <div className="card-cotacao-container-payment">
                <CardCotacao
                  titulo='Boleto bancário'
                  icon={faBarcode}
                  color='verde'
                  id='opcaoBoleto'
                  onClick={async () => {
                    await requestMetaData('boleto');
                    window.scrollTo({ top: 0, behavior: 'smooth' })
                    history.push('/payment-method/boleto')
                  }}
                >
                  {/* Realize os seus pagamentos à vista ou parcele em até 12x. */}
                </CardCotacao>
                <DividerText sm />
              </div>
            </Col>
            <Col xs={24} md={12}>
            <div className="card-cotacao-container-payment">
              <CardCotacao
                titulo='Cartão de crédito'
                icon={faCreditCard}
                color='azul-escuro'
                id='opcaoCartao'
                onClick={async () => {
                  await requestMetaData('cartao');
                  window.scrollTo({top: 0, behavior: 'smooth'})
                  history.push('/payment-method/cartao')
                }}
              >
                {/* Realize os seus pagamentos à vista ou parcele em até 12x. */}
              </CardCotacao>
            </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24}>
        <div className='section'>
          <ButtonOnsurance
            bloc
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  nome: selectNome
})

const mapDispatchToProps = dispatch => ({
  requestMetaData: paymentMethod => dispatch(requestMetaData(paymentMethod))
})

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethodPage);
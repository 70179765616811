import { createSelector } from 'reselect'
import { selectMetaData } from '../checkout/checkout.selector'

export const selectAssistencia = state => state.assistencia;

export const selectIsAssistencia = createSelector(
  [selectAssistencia],
  data => data.isAssistencia
)

export const selectVeiculosCadastradosAndSelected = createSelector(
  [selectAssistencia],
  data => {
    const { veiculosCadastrados, veiculosSelecionados } = data
    return {
      veiculosCadastrados,
      veiculosSelecionados
    }
  }
)

export const validatingIfHasVehicle = createSelector( // Incluir na página :) !!!!!
  [selectAssistencia],
  data => {
    const { veiculosCadastrados, veiculosSelecionados } = data
    let MY_BOOLEAN = false;
    if (veiculosCadastrados.length !== 0) MY_BOOLEAN = true;
    if (veiculosSelecionados.length !== 0) MY_BOOLEAN = true;
    return MY_BOOLEAN;
  }
)

export const selectedVehicles = createSelector(
  [selectAssistencia],
  data => data.veiculosSelecionados
)

export const selectAssistValorMontante = createSelector(
  [selectedVehicles, selectMetaData],
  (itens, metaData) => {
    const { ass24 } = metaData
    return ass24 * itens.length
  }
)
import React from 'react'
// AntDesign
import { Col, Tooltip, Row } from 'antd'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectValueOfRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import './valor-em-creditos.style.scss'

const ValorEmCreditos = ({ valueOfRecharge }) => {
  return (
    <Col xs={24}>
      <div className='section bottom'>
        <div className='valor-credito-p'>
          <Tooltip title='Valor em Créditos' className='w-100'>
            <Row>
              <Col xs={24}>
                <div className='d-flex justify-center align-center'>
                  <FontAwesomeIcon className='wallet-icon' icon={faWallet} />
                  <span className='valor-creditos'>{(valueOfRecharge * 1000).toLocaleString('pt-BR')}</span>
                </div>
              </Col>
              <Col xs={24}>
              <div className='d-flex justify-center align-center'>
                <p style={{ color: '#1890ff' }}>Créditos</p>
              </div>
              <p style={{textAlign: 'center', fontSize: '12px', color: '#1890ffCC'}}><i>(Valor em Reais x 1000)</i></p>
              </Col>
            </Row>
          </Tooltip>
        </div>
      </div>
    </Col>
  )
}

const mapStateToProps = createStructuredSelector({
  valueOfRecharge: selectValueOfRecharge
})

export default connect(mapStateToProps)(ValorEmCreditos);
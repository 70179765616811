// Importando os tipos de actions
import { Types } from '../action'

// Nosso Estado defualt, ou seja o valor inicial.
// Iniciamos os valores para não ocorrer nenhum erro na hora em que fazemos a busca
// Desses valores.
const INITIAL_DATA = {
  creditCard: {
    isCreditCard: false,
    holderName: '',
    number: '',
    validThru: '',
    cvc: ''
  },
  cpf: '',
  endereco: '',
  estado: '',
  numero: '',
  cidade: '',
  bairro: '',
  complemento: '',
  isEnderecoEntrega: '',
  cep: '',
  enderecoEntrega: '',
  estadoEntrega: '',
  numeroEntrega: '',
  cidadeEntrega: '',
  bairroEntrega: '',
  complementoEntrega: '',
  isParcelado: '',
  qtdParcela: '2',
  estadoOpts: [
    {estado: 'Acre', sigla:'AC'}, {estado: 'Alagoas', sigla:'AL'}, {estado: 'Amapá', sigla:'AP'},
    {estado: 'Amazonas', sigla:'AM'}, {estado: 'Bahia', sigla:'BA'}, {estado: 'Ceará', sigla:'CE'},
    {estado: 'Distrito Federal', sigla:'DF'}, {estado: 'Espírito Santo', sigla:'ES'},
    {estado: 'Goiás', sigla:'GO'}, {estado: 'Maranhão', sigla:'MA'}, {estado: 'Mato Grosso', sigla:'MT'},
    {estado: 'Mato Grosso do Sul', sigla:'MS'}, {estado: 'Minas Gerais', sigla:'MG'},
    {estado: 'Pará', sigla:'PA'}, {estado: 'Paraíba', sigla:'PB'}, {estado: 'Paraná', sigla:'PR'},
    {estado: 'Pernambuco', sigla:'PE'}, {estado: 'Piauí', sigla:'PI'}, {estado: 'Rio de Janeiro', sigla:'RJ'},
    {estado: 'Rio Grande do Norte', sigla:'RN'}, {estado: 'Rio Grande do Sul', sigla:'RS'},
    {estado: 'Rondônia', sigla:'RO'}, {estado: 'Roraima', sigla:'RR'}, {estado: 'Santa Catarina', sigla:'SC'},
    {estado: 'São Paulo', sigla:'SP'}, {estado: 'Sergipe', sigla:'SE'}, {estado: 'Tocantins', sigla:'TO'}
  ],
  tipoFrete: {
    frete: '',
    valor: 0,
    dias: ''
  },
  loading: false,
  errors: {}
}
// Mock Data
// const INITIAL_DATA = {
//   creditCard: {
//     isCreditCard: false,
//     holderName: '',
//     number: '',
//     validThru: '',
//     cvc: ''
//   },
//   cpf: '059.099.411-51',
//   endereco: '',
//   estado: '',
//   numero: '',
//   cidade: '',
//   bairro: '',
//   complemento: '',
//   isEnderecoEntrega: '',
//   cep: '',
//   enderecoEntrega: '',
//   estadoEntrega: '',
//   numeroEntrega: '',
//   cidadeEntrega: '',
//   bairroEntrega: '',
//   complementoEntrega: '',
//   isParcelado: '',
//   qtdParcela: '2',
//   estadoOpts: [
//     {estado: 'Acre', sigla:'AC'}, {estado: 'Alagoas', sigla:'AL'}, {estado: 'Amapá', sigla:'AP'},
//     {estado: 'Amazonas', sigla:'AM'}, {estado: 'Bahia', sigla:'BA'}, {estado: 'Ceará', sigla:'CE'},
//     {estado: 'Distrito Federal', sigla:'DF'}, {estado: 'Espírito Santo', sigla:'ES'},
//     {estado: 'Goiás', sigla:'GO'}, {estado: 'Maranhão', sigla:'MA'}, {estado: 'Acre', sigla:'MT'},
//     {estado: 'Mato Grosso do Sul', sigla:'MS'}, {estado: 'Minas Gerais', sigla:'MG'},
//     {estado: 'Pará', sigla:'PA'}, {estado: 'Paraíba', sigla:'PB'}, {estado: 'Paraná', sigla:'PR'},
//     {estado: 'Pernambuco', sigla:'PE'}, {estado: 'Piauí', sigla:'PI'}, {estado: 'Rio de Janeiro', sigla:'RJ'},
//     {estado: 'Rio Grande do Norte', sigla:'RN'}, {estado: 'Rio Grande do Sul', sigla:'RS'},
//     {estado: 'Rondônia', sigla:'RO'}, {estado: 'Roraima', sigla:'RR'}, {estado: 'Santa Catarina', sigla:'SC'},
//     {estado: 'São Paulo', sigla:'SP'}, {estado: 'Sergipe', sigla:'SE'}, {estado: 'Tocantins', sigla:'TO'}
//   ],
//   tipoFrete: {
//     frete: '',
//     valor: 0,
//     dias: ''
//   },
//   loading: false,
//   errors: {}
// }

// Esse é o nosso Reducer.
// Recebe o estado(state) como primeiro parâmetro e a action como segundo parâmetro.
// Caso o tipo(type) da action for algum correspondente a esse reducer, o estado vai sofrer
// Alterações conforme especificado no bloco.
// Caso o tipo não corresponder, retornamos o estado sem nenhuma alteração.
const billingDataReducer = (state = INITIAL_DATA, action) => {
  const { type, payload } = action
  switch (type) {
    case Types.BILLING_DATA_CPF:
      return {
        ...state,
        cpf: payload.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
      }

    case Types.BILLING_DATA_ENDERECO:
      return {
        ...state,
        endereco: payload
      }

    case Types.BILLING_DATA_ESTADO:
      return {
        ...state,
        estado: payload
      }

    case Types.BILLING_DATA_NUMERO:
      return {
        ...state,
        numero: payload
      }

    case Types.BILLING_DATA_CIDADE:
      return {
        ...state,
        cidade: payload
      }

    case Types.BILLING_DATA_BAIRRO:
      return {
        ...state,
        bairro: payload
      }

    case Types.BILLING_DATA_COMPLEMENTO:
      return {
        ...state,
        complemento: payload
      }

    case Types.BILLING_DATA_IS_ENDERECO_ENTREGA:
      return {
        ...state,
        isEnderecoEntrega: payload,
        cep: '',
        enderecoEntrega: '',
        estadoEntrega: '',
        numeroEntrega: '',
        cidadeEntrega: '',
        bairroEntrega: '',
        complementoEntrega: '',
      }

    case Types.BILLING_DATA_CEP:
      return {
        ...state,
        cep: payload
      }

    case Types.BILLING_DATA_ENDERECO_ENTREGA:
      return {
        ...state,
        enderecoEntrega: payload
      }

    case Types.BILLING_DATA_ESTADO_ENTREGA:
      return {
        ...state,
        estadoEntrega: payload
      }

    case Types.BILLING_DATA_CIDADE_ENTREGA:
      return {
        ...state,
        cidadeEntrega: payload
      }

    case Types.BILLING_DATA_NUMERO_ENTREGA:
      return {
        ...state,
        numeroEntrega: payload
      }

    case Types.BILLING_DATA_BAIRRO_ENTREGA:
      return {
        ...state,
        bairroEntrega: payload
      }

    case Types.BILLING_DATA_COMPLEMENTO_ENTREGA:
      return {
        ...state,
        complementoEntrega: payload
      }

    case Types.BILLING_DATA_IS_PARCELADO:
      return {
        ...state,
        isParcelado: payload
      }

    case Types.BILLING_DATA_QTD_PARCELA:
      return {
        ...state,
        qtdParcela: payload
      }

    case Types.VIA_CEP:
      return {
        ...state,
        endereco: action.payload.logradouro,
        estado: action.payload.uf,
        cidade: action.payload.localidade,
        bairro: action.payload.bairro,
        complemento: action.payload.complemento
      }

    case Types.BILLING_DATA_ERROR:
      return {
        ...state,
        errors : action.payload
      }

    case Types.BILLING_DATA_TIPO_FRETE:
      return {
        ...state,
        tipoFrete: {
          ...state.tipoFrete,
          frete: action.payload
        }
      }

    case Types.BILLING_DATA_VALOR_FRETE: {
      const { valor, prazoCorreios } = action.payload
      return {
        ...state,
        tipoFrete: {
          ...state.tipoFrete,
          dias: prazoCorreios,
          valor
        }
      }
    }

    case Types.BILLING_DATA_LOADING:
      return {
        ...state,
        loading: action.payload
      }

    case Types.BILLING_DATA_CREDIT_CARD_NAME:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          holderName: action.payload
        }
      }

    case Types.BILLING_DATA_CREDIT_CARD_NUMBER:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          number: action.payload
        }
      }

    case Types.BILLING_DATA_CREDIT_CARD_VALIDTHRU:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          validThru: action.payload
        }
      }

    case Types.BILLING_DATA_CREDIT_CARD_CVV:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          cvc: action.payload
        }
      }
  
    case Types.PAYMENT_METHOD_CARD:
      return {
        ...state,
        creditCard: {
          ...state.creditCard,
          isCreditCard: action.payload
        }
      }

    case Types.LIMPAR_STATE_POR_COMPLETO:
      return INITIAL_DATA

    default:
      return state
  }
}

export default billingDataReducer;
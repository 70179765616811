// React
import React from 'react'
// Component
import { ButtonOnsurance } from '../../components'
// Ant Design
import { Result, Row, Col } from 'antd'
// SCSS
import './404.style.scss'

/**
 * Página de erro 404.
 */

const PageNotFound = () => {
  return (
    <Row className="page-not-found">
      <Col xs={24}>
        <Result
          title='404'
          status='404'
          subTitle='A página que você tentou acessar não foi encontrada e/ou foi deletada.'
          extra={
            <ButtonOnsurance
              type='primary'
              onClick={() => window.location.assign('https://onsurance.me')}
            >
              Página inicial
            </ButtonOnsurance>
          }
        />
      </Col>
    </Row>
  );
}

export default PageNotFound;
// React
import React from 'react'
// Ant Design
import { Row, Col } from 'antd'
// Imagens
import Carro from '../../../assets/carro.png'
import Assistencia from '../../../assets/assistencia.png'
import Moto from '../../../assets/moto.png'
import Caminhonete from '../../../assets/caminhonete.png'
import Vuc from '../../../assets/vuc.png'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons'
// SCSS
import './checkout-item.style.scss'

const CheckoutItem = ({ Item, onClick, active, recomenda }) => {
  const { tipo, nome, valor } = Item
  return (
    <div className={`checkout-iten-container ${active ? 'active' : ''}${recomenda ? 'recomenda' : ''}`}>
      <Row gutter={16}>
      <div className="checkout-iten-button-container">
          {
            active === undefined
            ?
              <button
                className='checkout-iten-remove-button'
                onClick={() => onClick(Item)}
              >
                <div className="d-flex align-center">
                  <span>Remover</span>&nbsp;
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </button>
            :
            active === true || active === false
            ?
              <button
                className='checkout-iten-add-button'
                onClick={() => onClick(Item)}
              >
                <div className="d-flex align-center">
                  <span>Adicionar</span>&nbsp;
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </button>
            :
              null
          }
        </div>
        <Col xs={8}>
          <div className={`checkout-iten-image-container ${active ? 'active' : ''}${recomenda ? 'recomenda' : ''}`}>
            {
              tipo === 'Carro'
              ?
                <img alt={tipo} src={Carro} className='checkout-iten-image' />
              :
              tipo === 'Ass24'
              ?
                <img alt={tipo} src={Assistencia} className='checkout-iten-image' />
              :
              tipo === 'Moto'
              ?
                <img alt={tipo} src={Moto} className='checkout-iten-image' />
              :
              tipo === 'Caminhonete'
              ?
                <img alt={tipo} src={Caminhonete} className='checkout-iten-image' />
              :
                <img alt={tipo} src={Vuc} className='checkout-iten-image' />
            }
          </div>
        </Col>
        <Col xs={16}>
          <Row className='checkout-iten-text-container'>
            <Col xs={24}>
              <div className='position-bottom'>
                <p className='checkout-iten-title'>{nome}</p>
              </div>
            </Col>
            <Col xs={24}>
              <p className='checkout-iten-value'>{parseFloat(valor).toLocaleString('pt-BR', { style:'currency', currency: 'BRL'})}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default CheckoutItem;
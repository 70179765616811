import React from 'react'
import { Row, Col, Card } from 'antd'
import ImgCarro from '../../../assets/carro.png'
import ImgMoto from '../../../assets/moto.png'
import ImgCaminhonete from '../../../assets/caminhonete.png'
import './veiculo-assistencia-item.style.scss'

const VeiculoAssistenciaItem = ({ veiculo, onClick, selected }) => {
  const { marca, placa, modelo, ano, tipo } = veiculo
  return (
    <Col xs={24}>
      <Card hoverable onClick={onClick} className={selected ? 'selected' : null}>
        <Row gutter={6}>
          <Col xs={24} sm={12}>
            <div className='assistencia-image-container'>
              {
                tipo === 'car'
                ?
                  <img src={ImgCarro} alt='Carro' />
                :
                tipo === 'motorcycle'
                ?
                  <img src={ImgMoto} alt='Moto' />
                :
                  <img src={ImgCaminhonete} alt='Caminhonete' />
              }
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className='assistencia-col-1'>
              <div>
                <p>
                  <b>Marca</b>: {marca}.
                </p>
                <p>
                  <b>Modelo</b>: {modelo}.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={6}>
            <div className='assistencia-col-2'>
              <div>
                <p>
                  <b>Placa</b>: {placa}.
                </p>
                <p>
                  <b>Ano</b>: {ano}.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24}>
            {
              selected
              ?
              <p className='assistencia-remove-button'>Remover</p>
              :
              <p className='assistencia-select-button'>Selecionar</p>
            }
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

export default VeiculoAssistenciaItem;
import React from 'react'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// SCSS
import './card-cotacao.style.scss'

export default ({ titulo, children, icon, color, ...props }) => {
  return (
    <div
      className={`cotacao-option-container ${color}`}
      {...props}
    >
      <div className='cotacao-option-header'>
        <p>{ titulo }</p>
        <FontAwesomeIcon icon={icon} size='2x' />
      </div>
      <div className="cotacao-option-text">
        <p>
          {
            children
          }
        </p>
      </div>
    </div>
  );
}
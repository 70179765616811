// React
import React, { useEffect } from 'react'
// Ant Design
import { Col, Row, message } from 'antd'
// Components
import { Jumbotron, CheckoutContainer, CheckoutItem, ButtonOnsurance, PageTitle } from '../../components'
// Redux
import { connect } from 'react-redux'
import {
  selectItens, selectRemovedItens, selectNome, selectItensRecomendados, selectValorTotalItens,
  selectIsAssistOnlyValue, selectIndicacoes, selectIndication
} from '../../redux/checkout/checkout.selector'
import { createStructuredSelector } from 'reselect'
import { onAddItemOnCart, onRecomendadoAdd } from '../../redux/action'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faCheck, faTag } from '@fortawesome/free-solid-svg-icons'
// SCSS
import './checkout.style.scss'

/**
 * Página do checkout.
 */

// Route = /checkout
const CheckoutPage = ({
  Itens, history, RemovedItens, Nome, onAddItemOnCart, ItensRecomendados, onRecomendadoAdd,
  total, isAss24OnlyValue, Indicacoes, selectedIndication
}) => {
  
  useEffect(() => {
    document.title = `${Itens.length} ${Itens.length === 1 ? 'Item' : 'Itens'} - Onsurance`
    if (Nome === '') {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      });
      history.replace('/');
    }
  }, [Nome, history, Itens]);

  if (Nome === '') return <></>
  return (
    <Row className="checkout-page">
      <Col xs={24}>
        <PageTitle>
          Carrinho
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          <b><FontAwesomeIcon icon={faCheck} style={{color: '#00B8FA'}} /> {Nome}: Cotação encontrada com sucesso!</b> <br/><br/>
          {
            Indicacoes !== null
            ? Indicacoes.length === 1
              ? <FontAwesomeIcon icon={faTag} style={{color: '#49e841'}} />
              : null
            : null
          }
          {
            Indicacoes !== null
            ? Indicacoes.length === 1
              ? ` Encontramos uma indicação de ${Indicacoes[0].Indicante.name}, com isso você receberá um bônus de ${Indicacoes[0].B_nus} horas para ligar e desligar o Onsurance!`
              : null
            : null
          }
          {
            Indicacoes !== null
            ? Indicacoes.length === 1
              ? <br/>
              : null
            : null
          }
          {
            Indicacoes !== null
            ? Indicacoes.length === 1
              ? <br/>
              : null
            : null
          }
          Verifique os itens abaixo:
        </Jumbotron>
      </Col>
      <Col xs={24}>
        <CheckoutContainer Itens={Itens} Title='Meus Itens' />
      </Col>
      {
        RemovedItens.length !== 0
        ?
          <Col xs={24}>
            <div className='add-itens-container'>
              <p className='add-itens-title'>Itens Removidos</p>
              {
                RemovedItens.map((item, index) =>
                  <CheckoutItem key={index} active Item={item} onClick={onAddItemOnCart} />
                )
              }
            </div>
          </Col>
        :
        null
      }
      {
        ItensRecomendados.length !== 0
        ?
          <Col xs={24}>
            <div className='itens-recomendados-container'>
              <p className='itens-recomendados-title'>A Onsurance recomenda incluir:</p>
              {
                ItensRecomendados.map((item, index) =>
                  <CheckoutItem key={index} recomenda active={false} Item={item} onClick={onRecomendadoAdd} />
                )
              }
            </div>
          </Col>
        :
        null
      }
      <Col xs={24}>
        <Jumbotron isDiv={true}>
          <div className="checkout-valor-total">
            <p className='checkout-valor-total-information'>Valor Total</p>
            <p>{ parseFloat(total).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</p>
            {
              selectedIndication !== null
              ? <small>
                  Mais bônus de {selectedIndication.B_nus} horas
                </small>
              : null
            }
          </div>
        </Jumbotron>
      </Col>
      <Col xs={24} md={12} className='voltar-md'>
        <div className='d-flex justify-center section top final-content pr-1'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='d-flex justify-center section top'>
          <div className='pl-1 w-100'>
            <ButtonOnsurance
              type='confirm'
              bloc
              onClick={() => {
                if (isAss24OnlyValue) {
                  message.warning({content: 'Não é possível comprar apenas Ass. 24h como crédito inicial.'})
                  return;
                }
                if(selectedIndication === null && Indicacoes !== null) {
                  console.log('Indicacoes =>', Indicacoes);
                  window.scrollTo({top: 0, behavior: 'smooth'});
                  history.push('/indicantes');
                  return;
                }
                window.scrollTo({top: 0, behavior: 'smooth'});
                history.push('/payment-method');
              }}
              disabled={Itens.length === 0}
            >
              Confirmar
            </ButtonOnsurance>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className='voltar-xs'>
        <div className='d-flex justify-center section top final-content'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  Itens: selectItens,
  RemovedItens: selectRemovedItens,
  Nome: selectNome,
  ItensRecomendados: selectItensRecomendados,
  total: selectValorTotalItens,
  isAss24OnlyValue: selectIsAssistOnlyValue,
  Indicacoes: selectIndicacoes,
  selectedIndication: selectIndication
})

const mapDispatchToProps = dispatch => ({
  onAddItemOnCart: item => dispatch(onAddItemOnCart(item)),
  onRecomendadoAdd: item => dispatch(onRecomendadoAdd(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
import React, { useState } from 'react'
// Ant-Design
import { Col, Row } from 'antd'
// Components
import InputGroup from '../onsurance-input/onsurance-input.component'
// Credit card
import Cards from 'react-credit-cards';
import 'react-credit-cards/lib/styles.scss'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectCreditCard, selectBillingDataErrors } from '../../redux/billing-data/billing-data.selector'
import { handleChange, Types } from '../../redux/action'
import InputError from '../input-error-messege/input-error-messege'

const CreditCard = ({ creditCard , handleChange, errors }) => {

  const { holderName, number, validThru, cvc } = creditCard

  const [ focusCard, setFocus ] = useState('');

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <div className='d-flex justify-center align-center h-100'>
          <Cards
            cvc={cvc}
            expiry={validThru}
            focused={focusCard}
            name={holderName}
            number={number}
            placeholders={{name: 'seu nome aqui'}}
            locale={{valid: 'Mês/Ano'}}
          />
        </div>
      </Col>
      <Col xs={24} md={12}>
        <Row gutter={16}>
          <Col xs={24}>
            <InputGroup
              title='Nome impresso no cartão:'
              type='text'
              value={holderName}
              autoComplete='cc-name'
              required
              onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_CREDIT_CARD_NAME)}
            />
          </Col>
          <Col xs={24}>
            <InputGroup
              title='Número do Cartão:'
              type='text'
              inputMode='numeric'
              required
              value={number}
              autoComplete='cc-number'
              placeholder='0000 0000 0000 0000'
              extra={typeof errors.cardNumber !== "undefined" ? <InputError message={errors.cardNumber} /> : null }
              onChange={e => {
                const NUMBER = e.target.value.replace(/\D/g, '')
                handleChange(NUMBER, Types.BILLING_DATA_CREDIT_CARD_NUMBER)
              }}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputGroup
              title='Mês/ano de expiração:'
              type='text'
              inputMode='numeric'
              value={validThru}
              required
              autoComplete='cc-exp'
              placeholder='00/00'
              extra={typeof errors.cardDate !== "undefined" ? <InputError message={errors.cardDate} /> : null }
              onChange={(e) => {
                const VALID = e.target.value.replace(/\D/g,'')
                .replace(/(\d{2})(\d)/, '$1/$2')
                .replace(/(\d{2})\d+?$/, '$1')
                handleChange(VALID, Types.BILLING_DATA_CREDIT_CARD_VALIDTHRU)
              }}
            />
          </Col>
          <Col xs={24} md={12}>
            <InputGroup
              title='CVV:'
              type='text'
              autoComplete='cc-csc'
              inputMode='numeric'
              required
              name='cvc'
              value={cvc}
              placeholder='000'
              onFocus={() => setFocus('cvc')}
              onBlur={() => setFocus('')}
              onChange={(e) => {
                const CVV = e.target.value.replace(/\D/g, '')
                .replace(/(\d{4})\d+?$/, '$1')
                handleChange(CVV, Types.BILLING_DATA_CREDIT_CARD_CVV)
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  creditCard: selectCreditCard,
  errors: selectBillingDataErrors
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item,type))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreditCard);
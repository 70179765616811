// Components
import {
  CheckoutPage, PurchasePage, CotacaoPage, PaymentMethodPage, BillingDataPage, PageNotFound, ResultPage,
  ConfirmarDadosPage, RecargaCreditosPage, Assistencia24HorasPage, CheckoutIndicantePage
} from '../app/pages'

const ROUTES = [
  {
    path: "/assistencia-24-horas",
    component: Assistencia24HorasPage
  },
  {
    path: "/pedido-confirmado",
    component: ResultPage
  },
  {
    path: "/checkout",
    component: CheckoutPage
  },
  {
    path: '/indicantes',
    component: CheckoutIndicantePage
  },
  {
    path: "/confirmar-dados",
    component: ConfirmarDadosPage
  },
  {
    path: '/recarga-de-creditos',
    component: RecargaCreditosPage
  },
  {
    path: "/cotacao",
    component: CotacaoPage
  },
  {
    path: "/payment-method/:paymentMethod",
    component: BillingDataPage
  },
  {
    path: "/payment-method",
    component: PaymentMethodPage
  },
  {
    path: "/",
    exact: true,
    component: PurchasePage
  },
  {
    path: "*",
    component: PageNotFound
  }
]

export default ROUTES
// React
import React from 'react'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
// SCSS
import './onsurance-button.style.scss'

import PropTypes from 'prop-types'

const ButtonOnsurance = ({ children, type, bloc, ...props }) => {
  const styles = [
    `confirm-button ${bloc}`,
    `primary-button ${bloc}`,
    `default-button ${bloc}`,
  ];
  return (
    <button
      className={
        type === 'confirm'
        ?
          styles[0]
        :
        type === 'primary'
        ?
          styles[1]
        :
          styles[2]
      }
      {...props}
    >
    {
      children
    }
    {
      props.loading
      ?
      <FontAwesomeIcon icon={faSpinner} style={{marginLeft: '10px'}} spin />
      :
      null
    }
    </button>
  );
}

ButtonOnsurance.propTypes = {
  type: PropTypes.oneOf(['confirm', 'default', 'primary']),
  bloc: PropTypes.bool,
}

export default ButtonOnsurance;
import React from 'react'
import { connect } from 'react-redux'
import { selectQuantidadeParcelas, selectIsPagamentoPorCartao, selectValorFrete } from '../../redux/billing-data/billing-data.selector'
import { selectDadosParcela, selectMetaData, selectItens, selectValorTotalItens } from '../../redux/checkout/checkout.selector'
import { selectIsAssistencia, selectedVehicles, selectAssistValorMontante } from '../../redux/assistencia24horas/assistencia.selector'
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { createStructuredSelector } from 'reselect'
// AntDesign
import { Col, Row, Card } from 'antd'
import './receipt-style.scss'

const Receipt = ({
  numeroPedido = 0, itens, valorFrete, valorTotalItens, parcela, dadosParcela, isCreditCard, metaData,
  isRecharge, status = 'default', isAssistencia, itensAssistencia, montanteAssist
}) => {

  const { valorMontante, juros } = dadosParcela
  const { qtdParcela, isParcelado } = parcela
  const { entrada, ass24, taxaTrocaHardware } = metaData
  
  const subTotalAssistencia = montanteAssist + valorFrete;
  const subTotalCR = valorTotalItens + valorFrete;

  return (
    <div className='justify-center d-flex receipt-div'>
      <Card style={{backgroundColor: '#FFFF99', boxShadow: '0px 3px 5px rgba(0,0,0,0.15)'}}>
        <Row className='recibo-container'>
          {
            numeroPedido === 0 ?
              <>
              <Col xs={16} sm={18}>
                <p style={{marginBottom: '0.55em'}}>Itens:</p>
              </Col>
              {
                isAssistencia // Caso for assistência, o array dos itens é diferente.
                ?
                  <Col xs={8} sm={6}>
                    <p style={{textAlign: 'right', marginBottom: '0.55em'}}>{itensAssistencia.length}</p>
                  </Col>
                :
                  <Col xs={8} sm={6}>
                    <p style={{textAlign: 'right', marginBottom: '0.55em'}}>{itens.length}</p>
                  </Col>
              }
              </>
            :
            <>
            <Col xs={24} sm={24}>
              <h3 className='numero-pedido'>Pedido Nº <span style={{textDecoration: 'underline'}}>{numeroPedido}</span></h3>
            </Col>
            <Col xs={24} sm={24}>
              <span className='receipt-divider'></span>
            </Col>
            </>
          }
          <Col xs={24}>
            {
              isAssistencia
              ?
              itensAssistencia.map((item, index) => {
                const { modelo } = item
                return (
                  <Row key={index}>
                    <Col xs={14} sm={18}>
                      <p className='receipt-itens'>{index + 1}. {modelo} - Assistência 24h</p>
                    </Col>
                    <Col xs={10} sm={6}>
                      <p className='receipt-itens' style={{textAlign: 'right'}}>{parseFloat(ass24).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                    </Col>
                  </Row>
                )
              })
              :
              itens.map((item, index) => {
                const { nome, valor } = item
                const isTaxed = item.isTaxed;
                return(
                  <Row key={index}>
                    <Col xs={14} sm={18}>
                      <p className='receipt-itens'>{index + 1}. {nome}</p>
                      {
                        isTaxed
                        ? <p className='receipt-itens'>{index + 2}. Taxa de remoção/instalação do dispositivo Onboard</p>
                        : null
                      }
                    </Col>
                    <Col xs={10} sm={6}>
                      <p className='receipt-itens' style={{textAlign: 'right'}}>{parseFloat(isTaxed ? valor - taxaTrocaHardware : valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                      {
                        isTaxed
                        ? <p className='receipt-itens' style={{textAlign: 'right'}}>{parseFloat(taxaTrocaHardware).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL'})}</p>
                        : null
                      }
                    </Col>
                  </Row>
                )
              })
            }
          </Col>
          {
            isRecharge || isAssistencia ?
            null
            :
            <>
              <Col xs={24} sm={24}>
                <span className='receipt-divider'></span>
              </Col>
              <Col xs={24} sm={24}>
                <Row>
                  <Col xs={14} sm={18}>
                    <p>Frete:</p>
                  </Col>
                  <Col xs={10} sm={6}>
                    <p style={{textAlign: 'right'}}>{valorFrete.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}</p>
                  </Col>
                </Row>
              </Col>
            </>
          }
          {
            isParcelado === 'SIM' ?
            <>
              <Col xs={24} sm={24}>
                <span className='receipt-divider'></span>
              </Col>
              <Col xs={24} sm={24}>
                <Row>
                  <Col xs={14} sm={18}>
                    <p>Subtotal:</p>
                  </Col>
                  {
                    isAssistencia
                    ?
                    <Col xs={10} sm={6}>
                      <p style={{textAlign: 'right'}}>{subTotalAssistencia.toLocaleString('pt-BR', {style:'currency', currency: 'BRL'})}</p>
                    </Col>
                    :
                    <Col xs={10} sm={6}>
                      <p style={{textAlign: 'right'}}>{subTotalCR.toLocaleString('pt-BR', {style:'currency', currency: 'BRL'})}</p>
                    </Col>
                  }
                </Row>
              </Col>
            </>
            :
            null
          }
          <Col xs={24} sm={24}>
            <span className='receipt-divider'></span>
          </Col>
          <Col xs={24} sm={24}>
          {
            isCreditCard ?
            <p style={{ marginBottom: 10 }}>{status === 'default' ? 'Pagar' : status === 'CONFIRMED' ? 'Pago' : 'Pagar' } com cartão:</p>
            :
            isAssistencia
            ?
              null
            :
            <p style={{ marginBottom: 10 }}>Pagar com boleto:</p>
          }
          </Col>
          <Col xs={24} sm={24}>
            {
              isCreditCard ?
                null
              :
              isParcelado === 'SIM' ?
                isRecharge || isAssistencia ?
                  null
                :
                  <Row>
                    <Col xs={14} sm={18}>
                      <p>Entrada de:</p>
                    </Col>
                    <Col xs={10} sm={6}>
                      <p style={{textAlign: 'right'}}>
                        {
                          isCreditCard === false // Quando for Boleto
                          ?
                            parseInt(qtdParcela) > 2 // Quando a quantidade de parcelas for maior que 2.
                            ?
                              (entrada + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                            :
                              entrada.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
                          :
                          null
                        }
                      </p>
                    </Col>
                  </Row>
              :
                null
            }
            {
              isParcelado === 'SIM'
              ?
              <Row>
                <Col xs={14} sm={18}>
                  <p>{qtdParcela} Parcela(s) de:</p>
                </Col>
                <Col xs={10} sm={6}>
                  <p
                    style={{textAlign: 'right'}}
                  >
                    {
                      isCreditCard ? // Caso seja parcelado com CARTÃO DE CRÉDITO
                        isAssistencia
                        ?
                          <>{ parseFloat(((montanteAssist + montanteAssist * ((parseInt(qtdParcela) * juros) / 100)) / parseInt(qtdParcela))).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                        :
                          <>{ parseFloat(((valorMontante + valorMontante * ((parseInt(qtdParcela) * juros) / 100)) / parseInt(qtdParcela))).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                      : // Caso seja parcelado com BOLETO
                        isRecharge
                        ? // Caso o produto for Recarga de créditos.
                          qtdParcela > 3 ? // Caso a quantidade de parcelas for maior que 3.
                            <>{ parseFloat(((valorMontante + valorMontante * ((parseInt(qtdParcela) * juros) / 100)) / parseInt(qtdParcela)) + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                            : // Quando a quantidade de parcelas for menor que 3.
                            <>{ parseFloat((valorMontante / parseInt(qtdParcela))).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                        :
                        isAssistencia
                        ? // Caso o produto for Assistência 24h
                          qtdParcela > 3 ? // Caso a quantidade de parcelas for maior que 3.
                            <>{ parseFloat(((montanteAssist + montanteAssist * ((parseInt(qtdParcela) * juros) / 100)) / parseInt(qtdParcela)) + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                            : // Quando a quantidade de parcelas for menor que 3.
                            <>{ parseFloat((montanteAssist / parseInt(qtdParcela))).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                        : // Caso o produto for créditos iniciais.
                          qtdParcela > 2 ? // Caso a quantidade de parcelas for maior que 2.
                            <>{ parseFloat(((valorMontante + valorMontante * ((parseInt(qtdParcela) * juros) / 100)) / parseInt(qtdParcela)) + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                            : // Quando a quantidade de parcelas for menor que 2.
                            <>{ parseFloat((valorMontante / parseInt(qtdParcela))).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }</>
                    }
                  </p>
                </Col>
              </Row>
              :
              isAssistencia
              ?
              <Row>
                <Col xs={14} sm={18}>
                  <p>Total: </p>
                </Col>
                <Col xs={10} sm={6}>
                  <p style={{textAlign: 'right'}}>{parseFloat(ass24 * itensAssistencia.length).toLocaleString('pt-BR', {style:'currency', currency: 'BRL'})}</p>
                </Col>
              </Row>
              :
              <Row>
                <Col xs={14} sm={18}>
                  <p>Total: </p>
                </Col>
                <Col xs={10} sm={6}>
                  <p style={{textAlign: 'right'}}>{parseFloat(valorTotalItens + valorFrete).toLocaleString('pt-BR', {style:'currency', currency: 'BRL'})}</p>
                </Col>
              </Row>
            }
          </Col>
        </Row>
      </Card>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  parcela: selectQuantidadeParcelas,
  dadosParcela: selectDadosParcela,
  metaData: selectMetaData,
  isRecharge: selectIsRecharge,
  isCreditCard: selectIsPagamentoPorCartao,
  valorFrete: selectValorFrete,
  itens: selectItens,
  valorTotalItens: selectValorTotalItens,
  isAssistencia: selectIsAssistencia,
  itensAssistencia: selectedVehicles,
  montanteAssist: selectAssistValorMontante
})

export default connect(mapStateToProps)(Receipt);
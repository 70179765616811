// React
import React from 'react'
// ANT-DESIGN
import { Row, Col } from 'antd'
// SCSS
import './onsurance-input.component.scss'

export default ({ id, title, extra, required, ...props }) => {
  return (
    <div className='onsurance-input'>
      <Row gutter={[10, 5]}>
        <Col xs={24}>
          <label htmlFor={id}>
            {
              required
              ?
                <span className='required-input'>* </span>
              :
                null
            }
            { title }
          </label>
        </Col>
        <Col xs={24}>
          <input id={id} { ...props } />
        </Col>
        {
          extra
          ?
            <Col xs={24}>
              { extra }
            </Col>
          :
            null
        }
      </Row>
    </div>
  );
}
import React from 'react'
// Antd
import { Row, Col, Card, Collapse } from 'antd'
// Components
import SelectValue from './select-value.component'
import ValorEmCreditos from './valor-em-creditos.component'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectTypeOfRecharge } from '../../redux/credit-recharge/credit-recharge.selector'

const { Panel } = Collapse;

const SimpleRecharge = ({ typeOfRecharge }) => {
  
  if (typeOfRecharge !== 'SIMPLES') return null;
  return (
    <>
      <Col xs={24} className='section'>
        <Card
          title='Perguntas frequentes'
          size='small'
          bordered={false}
          bodyStyle={{padding: '12px 0px'}}
          headStyle={{padding: '0'}}
        >
          <Collapse>
            <Panel header='Quando devo usar essa modalidade de recarga?' key='1'>
              <p>
                Quando seus créditos acabarem, você compra mais créditos para continuar aproveitando seu
                Onsurance.
              </p>
            </Panel>
            <Panel header='É preciso fazer uma nova cotação?' key='2'>
              <p>
                Não, basta comprar os créditos conforme a sua conveniência. Lembrando que a recarga mínima
                é de R$ 299,00.
              </p>
            </Panel>
          </Collapse>
        </Card>
      </Col>
      <Col xs={24} className='section top'>
        <Card title='Informe o valor desejado:' size='small'>
          <Row>
            <SelectValue />
            <ValorEmCreditos />
          </Row>
        </Card>
      </Col>
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  typeOfRecharge: selectTypeOfRecharge
})

export default connect(mapStateToProps)(SimpleRecharge);
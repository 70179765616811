import React from 'react'
import { Link } from 'react-router-dom'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectResultado, selectNome } from '../../redux/checkout/checkout.selector'
import { handleChange, Types } from '../../redux/action'
// AntDesign
import { Row, Col, Card, Comment, Avatar, Tooltip, Button } from 'antd'
// Components
import { PageTitle, Receipt } from '../../components'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
import { faPrint, faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
// Assets
import Image from '../../../assets/messenger-onsurance.png'
import OnsuranceAvatar from '../../../assets/onsurance-avatar.png'
// Moment
import moment from 'moment'
import './result.style.scss'


const PrimeiraCobrancaBoleto = ({ resultado, handleChange, nome }) => {

  const { cobranca: { pedidoWoo, boleto, parcelas } } = resultado

  return (
    <Row className='result-page'>
      <Col xs={24}>
        <PageTitle>
          Anote o número do pedido
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Receipt numeroPedido={pedidoWoo} />
      </Col>
      <Col xs={24}>
        <Card className='section top' style={{backgroundColor: '#F7F7F7'}}>
          <Comment
            author={<span style={{cursor: 'pointer'}}>Onsurance Inc.</span>}
            avatar={
              <Avatar
                src={OnsuranceAvatar}
                alt='Onsurance'
              />
            }
            content={
              <>
                <p style={{margin: '0.55em 0em'}}>
                  <b>{nome}</b>, seja bem vindo ao lado positivo da inovação.
                </p>
                <p style={{marginBottom: '0.55em'}}>
                  Agora você precisa <b>ativar a sua conta</b> do Messenger para acessar o Onsurance.
                </p>
                <p style={{marginBottom: '0.55em'}}>
                  {/* No Messenger você vai acompanhar o processamento do seu pagamento (anote o nº do pedido) e enviar a sua documentação para concluir a sua ativação do Seguro. */}
                  Use o número do pedido: <span style={{backgroundColor: 'rgb(255, 255, 153)'}}>{pedidoWoo}</span>, para realizar a sua ativação!
                </p>
              </>
            }
            datetime={
              <Tooltip title={moment().format('DD/MM HH:mm')}>
                <span>{moment().fromNow()}</span>
              </Tooltip>
            }
          />
        </Card>
      </Col>
      <Col xs={24}>
        <div className='section top'>
          <p style={{marginBottom: '0.55rem'}}>
            <small><span style={{color: '#FF8484'}}>* </span><b>Importante: O app <u>Messenger lite</u> não é suportado.</b></small>
          </p>
        </div>
      </Col>
      <Col xs={24}>
        <Row>
          <Col xs={24} sm={12}>
            <div className='arrows-container'>
              <div className='container-seta-vermelha section'>
                <div className='arrow_box'>
                  <p style={{fontSize: '15px'}}><b>Próximo passo:</b></p>
                  <p>(Clique no botão)</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='section messenger-button-container'>
              <Button
                href='https://m.me/onsurance.me?ref=orderCotacaoNumber'
                target='_blank'
                rel='noopener noreferrer'
                type='primary'
                id='send-to-messenger-button'
              >
                <FontAwesomeIcon icon={faFacebookMessenger} /><span style={{paddingLeft: '10px'}}><b>Continuar no Messenger</b></span>
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Card className='section top' style={{backgroundColor: '#F7F7F7'}}>
          <Row>
            <Col xs={24} md={12}>
              <div className='image-messenger-onsurance section top'>
                <img src={Image} alt='Imagem de exemplo' />
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className='section top bottom download-messeger-container'>
                <h3 className='h3-alternativo'>Não tem o Messenger?</h3>
                <div className='result-p-container'>
                  <p>
                    Acessar as funções de controle, <b>liga/desliga</b> da sua cobertura, ter suporte técnico, tratamento de dúvidas, fazer o acionamento da sua cobertura,
                    chamar a assistência 24h e até pedir socorro caso haja uma emergência… Tudo é feito pelo aplicativo Messenger, que funciona no iPhone e Android.
                  </p>
                </div>
                <div style={{paddingTop: '20px'}} className='only-small'>
                  <Button href='http://onelink.to/56tvmh' type='primary'>
                    <span style={{marginRight: '5px'}}>Fazer download</span>
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                  </Button>
                </div>
                <div style={{paddingTop: '20px'}} className='only-large'>
                  <Button href='https://www.messenger.com/' type='primary'>
                    <span style={{marginRight: '5px'}}>Fazer download</span>
                    <FontAwesomeIcon icon={faFacebookMessenger} />
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Card>
      </Col>
      {
        parcelas === '' || parcelas === undefined 
        ?
        null
        :
        <Col xs={24}>
          <div className='div-demais-parcelas section top bottom'>
            <p className='demais-parcelas-p'>Ao confirmar o pedido no Messenger você verá o boleto de todas as parcelas</p>
          </div>
          <div className="download-a section bottom">
            <a href={parcelas} rel='noopener noreferrer' target='_blank'>
              Ver parcelas
            </a>
          </div>
        </Col>
      }
      <Col xs={24}>
        <div className="section top">
          <iframe src={boleto} width="100%" height="600px" title="boletoGerado">
            <p>O seu navegador não suporta Iframes.</p>
          </iframe>
        </div>
      </Col>
      <Col xs={24}>
        <div className="download-a section bottom">
          <a href={boleto} rel='noopener noreferrer' target='_blank' download>
            Imprimir <FontAwesomeIcon icon={faPrint}/>
          </a>
        </div>
      </Col>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <div className="section">
              <Button
                block
                size='large'
                onClick={() => handleChange(null, Types.LIMPAR_STATE_POR_COMPLETO)}
              >
                <Link style={{transition: 'all 0s linear'}} to='/'>
                  <FontAwesomeIcon icon={faArrowLeft} /> Novo Pedido
                </Link>
              </Button>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className='section'>
              <Button
                block
                size='large'
                href='https://onsurance.me'
              >
                <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: '5px'}} /> Sair
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  resultado: selectResultado,
  nome: selectNome,
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item,type))
})

export default connect(mapStateToProps, mapDispatchToProps)(PrimeiraCobrancaBoleto);
// React/RouterDom
import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { Loading } from './components'
// Redux
import { connect } from 'react-redux'
import { handleChange, Types } from './redux/action'
// Cookies
import { useCookies } from 'react-cookie'
// Routes
import Routes from './router'
// Ant Design
import Logo from '../assets/logo.svg'
// SCSS
import './App.scss';

function App({ handleChange }) {
  // Base Url
  const baseUrl = process.env.PUBLIC_URL
  
  // cookie: Objeto com os cookies,
  // setCookie: função para criar/sobrescrever um cookie.
  const [cookie] = useCookies();
  
  // const findIp = async () => {
  //   return fetch('https://api.ipify.org?format=json', {method: 'GET'})
  //   .then(resp => resp.json())
  //   .then(data => console.log(data))
  // }

  // Método para verificar se existe cookies.
  // Ou sobrescrever um cookie caso exista um parâmetro 'ref' na url.
  useEffect(() => {
    // findIp()
    // Caso o affiliateId estiver indefinido ou nulo é forçada a saida desse bloco
    if(cookie.affiliateId === undefined || cookie.affiliateId === null) {
      return;
    }
    // Enviamos o valor do cookie para nosso state atravez da action handleChange.
    handleChange(cookie.affiliateId, Types.CHECKOUT_AFFILIATE_ID);
  }, [cookie, handleChange]);

  return (
    <>
      <div className="logo-container">
        <img src={Logo} alt='Onsurance Inc.' />
      </div>
      <div className='app'>
        <Loading />
        <Switch>
        {
          Routes.map((route, index) => <Route path={baseUrl + route.path} {...route} key={index} />)
        }
        </Switch>
      </div>
    </>
  );
}

// Mapeando nossas actions e injetando como props no nosso component.
const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type))
})

export default connect(null, mapDispatchToProps)(App);

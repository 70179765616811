import React from 'react'
// Ant Design
import { Row, Col, Select } from 'antd'
// Redux
import { connect } from 'react-redux'
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { selectIsAssistencia, selectAssistValorMontante } from '../../redux/assistencia24horas/assistencia.selector'
import { createStructuredSelector } from 'reselect'
// Types
import { Types } from '../../redux/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPiggyBank, faDollarSign } from '@fortawesome/free-solid-svg-icons'
// Components
import './quantidade-parcelas.scss'

const { Option } = Select

function useParcelas(paymentMethod) {
  const numeroDeParcelas = [ 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12 ];
  if (paymentMethod === 'boleto') {
    numeroDeParcelas.splice(0, 0, 1)
    numeroDeParcelas.splice(9, 3)
  }
  return numeroDeParcelas; 
}

const FormQuantidadeParcelas = ({
  handleChange, valorMontante, juros, entrada, paymentMethod, isRecharge, isAssistencia, montanteAssist
}) => {

  const numeroDeParcelas = useParcelas(paymentMethod);

  return (
    <Row className="select-onsurance">
      <Col xs={24}>
        <label htmlFor="qtd-parcelas"><span className='required-input'>* </span>Quantidade de parcelas:</label>
      </Col>
      {
        paymentMethod === 'boleto'
        ? // Quando for parcelado com BOLETO
          isRecharge || isAssistencia
          ? // Quando o produto for RECARGA DE CRÉDITOS e ou ASSISTÊNCIA 24h
          <>
            <Col xs={24} style={{marginBottom: '10px'}}>
              <Select
                className='select-qtd-parcelas'
                onChange={e => handleChange(e, Types.BILLING_DATA_QTD_PARCELA)}
              >
                {
                  numeroDeParcelas.map((parcela, i) =>
                    parcela > 3
                    ?
                      isAssistencia
                      ?
                        <Option key={i} value={parcela}>
                          <Row>
                            <Col xs={18}>
                              {parcela} parcelas de {parseFloat(((montanteAssist + montanteAssist * (parcela * juros / 100)) / parcela) + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}  <span style={{color: '#01B7FF'}}>(com juros)</span>
                            </Col>
                            <Col xs={6}>
                              <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                                <FontAwesomeIcon icon={faDollarSign} style={{color: '#2b961f'}} />
                              </div>
                            </Col>
                          </Row>
                        </Option>
                      :
                        <Option key={i} value={parcela}>
                          <Row>
                            <Col xs={18}>
                              {parcela} parcelas de {parseFloat(((valorMontante + valorMontante * (parcela * juros / 100)) / parcela) + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}  <span style={{color: '#01B7FF'}}>(com juros)</span>
                            </Col>
                            <Col xs={6}>
                              <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                                <FontAwesomeIcon icon={faDollarSign} style={{color: '#2b961f'}} />
                              </div>
                            </Col>
                          </Row>
                        </Option>
                    :
                      isAssistencia
                      ?
                      <Option key={i} value={parcela}>
                        <Row>
                          <Col xs={18}>
                            {parcela} parcela{parcela === 1 ? null : 's'} de {parseFloat(montanteAssist / parcela).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} <span style={{color: '#01B7FF', fontWeight: 'bold'}}>(sem juros)</span>
                          </Col>
                          <Col xs={6}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                              <FontAwesomeIcon icon={faPiggyBank} style={{color: '#2b961f'}} />
                            </div>
                          </Col>
                        </Row>
                      </Option>
                      :
                      <Option key={i} value={parcela}>
                        <Row>
                          <Col xs={18}>
                            {parcela} parcela{parcela === 1 ? null : 's'} de {parseFloat(valorMontante / parcela).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} <span style={{color: '#01B7FF', fontWeight: 'bold'}}>(sem juros)</span>
                          </Col>
                          <Col xs={6}>
                            <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                              <FontAwesomeIcon icon={faPiggyBank} style={{color: '#2b961f'}} />
                            </div>
                          </Col>
                        </Row>
                      </Option>
                  )
                }
              </Select>
            </Col>
          </>
          :
          <>
            <Col xs={24} style={{marginBottom: '10px'}}>
              <Select
                className='select-qtd-parcelas'
                onChange={e => handleChange(e, Types.BILLING_DATA_QTD_PARCELA)}
              >
                {
                  numeroDeParcelas.map((parcela, i) => 
                    parcela > 2 ?
                    <Option key={i} value={parcela}>
                      <Row>
                        <Col xs={18}>
                          Primeira parcela de {parseFloat(entrada).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} + {parcela} parcelas de {parseFloat(((valorMontante + valorMontante * (parcela * juros / 100)) / parcela) + 3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}  <span style={{color: '#01B7FF'}}>(com juros)</span>
                        </Col>
                        <Col xs={6}>
                          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                            <FontAwesomeIcon icon={faDollarSign} style={{color: '#2b961f'}} />
                          </div>
                        </Col>
                      </Row>
                    </Option>
                    :
                    <Option key={i} value={parcela}>
                      <Row>
                        <Col xs={18}>
                          Primeira parcela de {parseFloat(entrada).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} + {parcela} parcela{parcela === 1 ? null : 's'} de {parseFloat(valorMontante / parcela).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} <span style={{color: '#01B7FF', fontWeight: 'bold'}}>(sem juros)</span>
                        </Col>
                        <Col xs={6}>
                          <div style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '100%'}}>
                            <FontAwesomeIcon icon={faPiggyBank} style={{color: '#2b961f'}} />
                          </div>
                        </Col>
                      </Row>
                    </Option>
                  )
                }
              </Select>
            </Col>
          </>
        : // Quando for parcelado com CARTÃO DE CRÉDITO
          isAssistencia
          ?
            <>
              <Col xs={24} style={{marginBottom: '10px'}}>
                <Select
                  className='select-qtd-parcelas'
                  onChange={e => handleChange(e, Types.BILLING_DATA_QTD_PARCELA)}
                >
                  {numeroDeParcelas.map((parcela, i) =>
                    <Option key={i} value={parcela}>
                      {parcela} parcelas de {parseFloat((montanteAssist + montanteAssist * ((parcela * juros) / 100)) / parcela).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
                    </Option>
                  )}
                </Select>
              </Col>
            </>
          :
            <>
              <Col xs={24} style={{marginBottom: '10px'}}>
                <Select
                  className='select-qtd-parcelas'
                  onChange={e => handleChange(e, Types.BILLING_DATA_QTD_PARCELA)}
                >
                  {numeroDeParcelas.map((parcela, i) =>
                    <Option key={i} value={parcela}>
                      {parcela} parcelas de {parseFloat((valorMontante + valorMontante * ((parcela * juros) / 100)) / parcela).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
                    </Option>
                  )}
                </Select>
              </Col>
            </>
      }
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  isRecharge: selectIsRecharge,
  isAssistencia: selectIsAssistencia,
  montanteAssist: selectAssistValorMontante
})

export default connect(mapStateToProps)(FormQuantidadeParcelas);
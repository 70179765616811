// React
import React from 'react'
// SCSS
import './onsurance-radio-button.style.scss'

const OnsuranceRadioButton = ({ id, value, name, checkValue, ...props }) => {
  return (
    <div className='radio-button-container'>
      <input
        type="radio"
        id={id}
        value={value}
        name={name}
        checked={checkValue === value}
        {...props}
      />
      <label htmlFor={id} style={{cursor: 'pointer'}}>{ value }</label>
    </div>
  );
}

export default OnsuranceRadioButton;
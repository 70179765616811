import React from 'react'
// AntDesign
import { Col } from 'antd'
// Components
import { ButtonOnsurance } from '../../components'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const VoltarMd = ({ redirectObj, backFunction }) => {
  const { possuiCotacao, isRecargaCreditos, ass24h } = redirectObj
  return (
    !possuiCotacao && !isRecargaCreditos && !ass24h
    ?
    <Col xs={24} md={12} className='voltar-md pr-1'>
      <div className='d-flex justify-center section top final-content'>
        <ButtonOnsurance
          bloc
          onClick={() => window.location.assign('https://onsurance.me')}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
        </ButtonOnsurance>
      </div>
    </Col>
    :
    <Col xs={24} md={12} className='voltar-md pr-1'>
      <div className='d-flex justify-center section top final-content'>
        <ButtonOnsurance
          bloc
          onClick={backFunction}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
        </ButtonOnsurance>
      </div>
    </Col>
  )
}

const VoltarPurchase = ({ isMd, redirectObj, backFunction }) => {
  const { possuiCotacao, isRecargaCreditos, ass24h } = redirectObj
  if (isMd !== undefined ) {
    return <VoltarMd redirectObj={redirectObj} backFunction={backFunction} />
  }
  return (
    !possuiCotacao && !isRecargaCreditos && !ass24h
    ?
    <Col xs={24} md={12} className='voltar-xs'>
      <div className='d-flex justify-center section top final-content'>
        <ButtonOnsurance
          bloc
          onClick={() => window.location.assign('https://onsurance.me')}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
        </ButtonOnsurance>
      </div>
    </Col>
    :
    <Col xs={24} md={12} className='voltar-xs'>
      <div className='d-flex justify-center section top final-content'>
        <ButtonOnsurance
          bloc
          onClick={backFunction}
        >
          <FontAwesomeIcon icon={faArrowLeft} /> Voltar
        </ButtonOnsurance>
      </div>
    </Col>
  )
}

export default VoltarPurchase;
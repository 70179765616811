// React
import React from 'react'
// Ant Design
import { Empty } from 'antd'
// Redux
import { connect } from 'react-redux'
import { onRemoveItemFromCart } from '../../redux/action'
// Components
import CheckoutItem from '../checkout-item/checkout-item.component'
// SCSS
import './checkout-container.style.scss'
// Empty cart image
import emptyCart from '../../../assets/empty-cart.png'

const CheckoutContainer = ({ Itens, Title, onRemoveItemFromCart }) => {
  return (
    <div className="checkout-container">
      <p className='checkout-container-title'>{ Title }</p>
      {
        Itens.length !== 0
        ?
          Itens.map((Item, i) => <CheckoutItem Item={Item} key={i} onClick={onRemoveItemFromCart} />)
        :
          <div className='no-data-container'>
            <Empty description='Nenhum item no carrinho' image={emptyCart} />
          </div>
      }
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  onRemoveItemFromCart: item => dispatch(onRemoveItemFromCart(item))
})

export default connect(null, mapDispatchToProps)(CheckoutContainer);
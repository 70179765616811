import React, { useEffect } from 'react';
import { Col, Row, message } from 'antd';
import { Jumbotron, ButtonOnsurance, PageTitle, IndicanteItem } from '../../components';

import { connect } from 'react-redux';
import {
    selectItens, selectRemovedItens, selectNome, selectItensRecomendados, selectValorTotalItens,
    selectIsAssistOnlyValue, selectIndicacoes, selectIndication
  } from '../../redux/checkout/checkout.selector';
import { createStructuredSelector } from 'reselect';
import { handleChange } from '../../redux/action'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import './checkout.indicante.style.scss';

// Path: /indicantes
const CheckoutIndicantePage = ({
    Itens, history, Nome, setIndicante, Indicacoes, selectedIndication
}) => {

    useEffect(() => {
        document.title = `Selecione o indicante - Onsurance`
        if (Nome === '') {
            message.open({
                content: 'Atualize os dados para continuar',
                duration: 4,
                type: 'info',
            });
            history.replace('/');
        }
    }, [Nome, history, Itens]);

    if (Nome === '') return <></>
  return (
    <Row className="checkout-page">
      <Col xs={24}>
        <PageTitle>
          Indicantes
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          <b>{Nome}, você possuí indicações de {Indicacoes.length} amigos!</b> <br/><br/>
          Você ganha e seu amigo também, selecione para quem vai o bônus:
        </Jumbotron>
      </Col>
      <Col xs={24}>
        <div className="indications-container">
            <p className="indications-container-title">Selecione um Indicante</p>
            <div className="indications-content">
                {
                    Indicacoes.map((item, index) =>
                      <IndicanteItem item={item} active={selectedIndication !== null && selectedIndication.id === item.id}
                      key={index} onClick={setIndicante} />
                    )
                }
            </div>
        </div>
      </Col>
      {
          selectedIndication !== null
          ?<Col xs={24}>
                <Jumbotron isDiv={true}>
                    <div className="checkout-valor-total">
                    <p className='checkout-valor-total-information'>Bônus adquirido</p>
                    <p>{selectedIndication.B_nus} horas</p>
                    </div>
                </Jumbotron>
            </Col>
          : null
      }
      <Col xs={24} md={12} className='voltar-md'>
        <div className='d-flex justify-center section top final-content pr-1'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/checkout')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='d-flex justify-center section top'>
          <div className='pl-1 w-100'>
            <ButtonOnsurance
              type='confirm'
              bloc
              onClick={() => {
                window.scrollTo({top: 0, behavior: 'smooth'});
                history.push('/payment-method');
              }}
              disabled={selectedIndication === null}
            >
              Confirmar
            </ButtonOnsurance>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className='voltar-xs'>
        <div className='d-flex justify-center section top final-content'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/checkout')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  );

}

const mapStateToProps = createStructuredSelector({
    Itens: selectItens,
    RemovedItens: selectRemovedItens,
    Nome: selectNome,
    ItensRecomendados: selectItensRecomendados,
    total: selectValorTotalItens,
    isAss24OnlyValue: selectIsAssistOnlyValue,
    Indicacoes: selectIndicacoes,
    selectedIndication: selectIndication
  })
  
  const mapDispatchToProps = dispatch => ({
    setIndicante: (item, type) => dispatch(handleChange(item, type))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(CheckoutIndicantePage);
import RechargeTypes from './credit-recharge.types'
import { base64Decoder } from '../action'

const INITIAL_STATE = {
  isRecharge: false,
  emailRecharge: '',
  cotacaoNumberRecharge: '',
  typeOfRecharge: 'SIMPLES',
  valueOfRecharge: 299,
  isTaxed: false,
  minValueRecharge: null,
  loadingRecharge: false,
  vehicles: [],
  vehicleSelectForChange: null,
  walletCreditsRecharge: null,
  quotes: [],
  selectedQuote: null
}

// Mock Data:
// const INITIAL_STATE = {
//   isRecharge: true,
//   emailRecharge: 'teste@onsurance.me',
//   cotacaoNumberRecharge: '1592315373',
//   typeOfRecharge: 'TROCA DE VEICULO',
//   valueOfRecharge: 718,
//   minValueRecharge: 718,
//   loadingRecharge: false,
//   vehicles: [
//     {
//       ano: '2019',
//       marca: 'Tesla',
//       modelo: 'Model S',
//       placa: 'ons2019',
//       tipo: 'car'
//     },
//     {
//       ano: '2020',
//       marca: 'Porshe',
//       modelo: '911 Turbo S',
//       placa: 'PRE2020',
//       tipo: 'car'
//     }
//   ],
//   vehicleSelectForChange: null,
//   vehicleOfNewCot: null,
//   walletCreditsRecharge: null
// }

const CreditRechargeReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action
  switch (type) {
    case RechargeTypes.CHECKOUT_DADOS_PARA_RECARGA:
      return {
        ...state,
        isRecharge: true,
        vehicles: payload.veiculos.map(veiculo => {
          return {
            ano: base64Decoder(veiculo.ano),
            marca: base64Decoder(veiculo.marca),
            modelo: base64Decoder(veiculo.modelo),
            placa: base64Decoder(veiculo.placa),
            tipo: base64Decoder(veiculo.tipo),
          }
        }),
        vehicleSelectForChange: {
          ano: base64Decoder(payload.veiculos[0].ano),
          marca: base64Decoder(payload.veiculos[0].marca),
          modelo: base64Decoder(payload.veiculos[0].modelo),
          placa: base64Decoder(payload.veiculos[0].placa),
          tipo: base64Decoder(payload.veiculos[0].tipo),
        },
        quotes: payload.cotacoes
      }

    case RechargeTypes.EMAIL_RECHARGE:
      return {
        ...state,
        emailRecharge: payload
      }

    case RechargeTypes.TYPE_OF_RECHARGE:

      let defaultVehicle = {
        ano: state.vehicles[0].ano,
        marca: state.vehicles[0].marca,
        modelo: state.vehicles[0].modelo,
        placa: state.vehicles[0].placa,
        tipo: state.vehicles[0].tipo,
      }

      return {
        ...state,
        typeOfRecharge: payload,
        vehicleSelectForChange: defaultVehicle
      }

    case RechargeTypes.RESET_OPTS_QUOTE_RECARGA:
      return {
        ...state,
        selectedQuote: null,
        cotacaoNumberRecharge: ''
      }

    case RechargeTypes.SELECT_QUOTE_FROM_QUOTES_ARRAY:
      return {
        ...state,
        selectedQuote: payload
      }

    case RechargeTypes.VALUE_OF_RECHARGE:
      return {
        ...state,
        valueOfRecharge: payload
      }

    case RechargeTypes.MIN_VALUE_RECHARGE:
      return {
        ...state,
        minValueRecharge: payload
      }

    case RechargeTypes.LOADING_RECHARGE:
      return {
        ...state,
        loadingRecharge: !state.loadingRecharge
      }

    case RechargeTypes.COTACAO_NUMBER_RECHARGE:
      return {
        ...state,
        cotacaoNumberRecharge: payload
      }

    case RechargeTypes.RESET_OPTS_RECARGA:
      return {
        ...state,
        valueOfRecharge: 299,
        minValueRecharge: null,
        cotacaoNumberRecharge: '',
        vehicleOfNewCot: null,
        walletCreditsRecharge: null,
        vehicleSelectForChange: null
      }

    case RechargeTypes.SELECT_VEHICLE_FROM_VEHICLES_ARRAY:
      return {
        ...state,
        vehicleSelectForChange: payload
      }

    case RechargeTypes.NEW_VEHICLE_FROM_COT:
      return {
        ...state,
        vehicleOfNewCot: payload
      }

    case RechargeTypes.SET_WALLET_CREDITS_RECHARGE:
      return {
        ...state,
        walletCreditsRecharge: payload
      }

    case RechargeTypes.SET_IS_TAXED:
      return {
        ...state,
        isTaxed: payload
      }

    case RechargeTypes.LIMPAR_STATE_POR_COMPLETO:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default CreditRechargeReducer;
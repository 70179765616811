// Nossa Rest POST method baseada na library fetch.
export const fetchPost = async (url, body) => {
  return await fetch(
    url,
    {
      method: 'POST',
      body: JSON.stringify(body),
      mode: 'cors',
      headers: new Headers({'content-type': 'application/json'})
    }
  ).then(resp => resp.json());
}
// Nossa Rest GET method baseada na library fetch.
export const fetchGet = async (url) => {
  return await fetch(
    url,
    {
      method: 'GET',
      mode: 'cors',
      headers: new Headers({'content-type': 'application/json'})
    }
  ).then(resp => resp.json());
}
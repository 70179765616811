import { Types } from '../action'

const INITIAL_STATE = {
  assistenciaEmail: '',
  isAssistencia: false,
  veiculosCadastrados: [],
  veiculosSelecionados: []
}
// Mock Data
// const INITIAL_STATE = {
//   assistenciaEmail: 'teste@onsurance.me',
//   isAssistencia: false,
//   veiculosCadastrados: [],
//   veiculosSelecionados: []
// }

const Assistencia24Reducer = (state = INITIAL_STATE, action) => {
  const { type } = action
  switch (type) {
    case Types.ASSISTENCIA_EMAIL: {
      return {
        ...state,
        assistenciaEmail: action.payload
      }
    }

    case Types.DATA_EMAIL_ASSISTENCIA:
      return {
        ...state,
        isAssistencia: true,
        veiculosCadastrados: action.payload.veiculos
      }

    case 'VEICULO_CADASTRADO_REMOVE':
      return {
        ...state,
        veiculosCadastrados: action.payload,
      }

    case 'VEICULO_CADASTRADO_ADD':
      return {
        ...state,
        veiculosCadastrados: [...state.veiculosCadastrados, action.payload]
      }

    case 'VEICULO_SELECTED_REMOVE':
      return {
        ...state,
        veiculosSelecionados: action.payload
      }

    case 'VEICULO_SELECTED_ADD':
      return {
        ...state,
        veiculosSelecionados: [...state.veiculosSelecionados, action.payload]
      }

    case Types.LIMPAR_STATE_POR_COMPLETO:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default Assistencia24Reducer;
import React from 'react'
// AntDesign
import { Row, Col, Card, Collapse, Form, Input, Button, Typography } from 'antd'
// Redux
import { connect } from 'react-redux'
import { handleChange } from '../../redux/action'
import RechargeTypes from '../../redux/credit-recharge/credit-recharge.types'
import { createStructuredSelector } from 'reselect'
import { selectCreditRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { onVehicleOfChangeRechargeSelected, unselectVehicleFromRecharge,
  onQuoteClickOfChangeRechargeSelected, unselectQuoteFromRecharge } from '../../redux/credit-recharge/credit-recharge.actions'
import { VeiculoAssistenciaItem, OnsuranceRadioButton, QuoteItem } from '../../components'
import './change-vehicle-recharge.style.scss'

const { Panel } = Collapse

const ChangeVehicleRecharge = ({
  handleChange, creditRecharge, onVehicleOfChangeRechargeSelected, unselectVehicleFromRecharge,
  confirmChange, setChange, onQuoteClickOfChangeRechargeSelected, unselectQuoteFromRecharge
}) => {

  const {
    minValueRecharge, loadingRecharge, valueOfRecharge, typeOfRecharge,
    vehicles, vehicleSelectForChange, isTaxed, quotes, selectedQuote
  } = creditRecharge

  if (typeOfRecharge !== 'TROCA DE VEICULO') return null;
  return (
    <>
      <Col xs={24} className='section'>
        <Card
          title='Perguntas frequentes'
          size='small'
          bordered={false}
          bodyStyle={{padding: '12px 0px'}}
          headStyle={{padding: '0'}}
        >
          <Collapse>
            <Panel header='Quando devo usar essa modalidade de recarga?' key='1'>
              <p>
                Para quem já é nosso cliente e quer trocar de veículo no sistema aproveitando o saldo
                de créditos existentes.
              </p>
            </Panel>
            <Panel header='É preciso fazer uma nova cotação?' key='2'>
              <p>
                Sim, será necessário fazer uma cotação para o seu novo carro. Informe o número da sua nova
                cotação para prosseguir.
              </p>
            </Panel>
          </Collapse>
        </Card>
      </Col>
      {
        vehicleSelectForChange !== null
        ?
          <Col xs={24} className='section'>
            <Card
              title='Veículo Selecionado'
              bordered={false}
              size='small'
              bodyStyle={{padding: '12px 0px'}}
              headStyle={{padding: '0'}}
            >
              <VeiculoAssistenciaItem
                veiculo={vehicleSelectForChange}
                onClick={() => {
                  setChange('');
                  unselectVehicleFromRecharge();
                }}
                selected
              />
            </Card>
          </Col>
        :
          <Col xs={24} className='section'>
            <Card
              title='Selecione o veículo que deseja trocar.'
              bordered={false}
              size='small'
              bodyStyle={{padding: '12px 0px'}}
              headStyle={{padding: '0'}}
            >
              <Row gutter={[0, 16]}>
                {
                  vehicles.map((vehicle, i) =>
                    <VeiculoAssistenciaItem
                      veiculo={vehicle}
                      key={i}
                      onClick={() => onVehicleOfChangeRechargeSelected(vehicle)}
                    />
                  )
                }
              </Row>
            </Card>
          </Col>
      }
      {
        vehicleSelectForChange !== null
        ?
          selectedQuote !== null
          ?<Col xs={24} className='section'>
            <Card
              title='Cotação Selecionada'
              bordered={false}
              size='small'
              bodyStyle={{padding: '12px 0px'}}
              headStyle={{padding: '0'}}
            >
              <QuoteItem
                cotacao={selectedQuote}
                onClick={() => {
                  setChange('');
                  unselectQuoteFromRecharge();
                }}
                selected
              />
            </Card>
          </Col>
          :<Col xs={24} className='section'>
            <Card
              title='Selecione a cotação desejada.'
              bordered={false}
              size='small'
              extra={
                <Button href='https://cotacao.onsurance.me/' target='_blank' type='primary'>
                  Fazer nova cotação
                </Button>
              }
              bodyStyle={{padding: '12px 0px'}}
              headStyle={{padding: '0'}}
            >
              <Row gutter={[0, 16]}>
                {
                  quotes.map((quote, i) =>
                    <QuoteItem
                      cotacao={quote}
                      key={i}
                      onClick={() =>  {
                        onQuoteClickOfChangeRechargeSelected(quote);
                        handleChange(quote.Subject, RechargeTypes.COTACAO_NUMBER_RECHARGE);
                      }}
                    />
                  )
                }
              </Row>
            </Card>
          </Col>
        :
        null
      }
      {
        (selectedQuote !== null && minValueRecharge !== null) || loadingRecharge
        ?
          <Col xs={24} className='section top'>
            <Card
              title='Confirmação'
              bordered={false}
              size='small'
              bodyStyle={{padding: '12px 0px'}}
              headStyle={{padding: '0'}}
              loading={loadingRecharge}
            >
              {
                selectedQuote !== null
                ?
                <p style={{marginBottom: '10px'}}>
                  Você deseja confirmar a troca do veículo 
                  <span className='selected-vehicle-recharge'> {vehicleSelectForChange.marca} {vehicleSelectForChange.modelo} </span> 
                  para o veículo 
                  <span className='selected-vehicle-recharge'> {selectedQuote.Brand} {selectedQuote.Model}</span>
                  ?
                </p>
                :
                null
              }
              <Row gutter={16}>
                <Col xs={12}>
                  <OnsuranceRadioButton
                    id='change-vehicle-confirm-button'
                    value='SIM'
                    name={'confirmChange'}
                    checkValue={confirmChange}
                    onChange={e => setChange(e.target.value)}
                  />
                </Col>
                <Col xs={12}>
                  <OnsuranceRadioButton
                    id='change-vehicle-not-confirm-button'
                    value='NÃO'
                    name={'confirmChange'}
                    checkValue={confirmChange}
                    onChange={e => setChange(e.target.value)}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        :
        null
      }
      {
        confirmChange === 'SIM'
        ?
          <Col xs={24} className='section top'>
            <Card
            size='small'
            >
              <Form layout='vertical'>
                <Form.Item label='Digite o valor da recarga de troca:' extra={`Valor Mínimo: ${parseFloat(minValueRecharge).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`}>
                  <Input
                    onChange={e => {
                      const VALOR = e.target.value.replace(/\D/g, '')
                      handleChange(VALOR === '' ? 0 : parseInt(VALOR), RechargeTypes.VALUE_OF_RECHARGE)
                    }}
                    inputMode='numeric'
                    onBlur={e => parseInt(e.target.value.replace(/\D/g, '')) < minValueRecharge ? handleChange(minValueRecharge, RechargeTypes.VALUE_OF_RECHARGE) : null }
                    onPressEnter={e => parseInt(e.target.value.replace(/\D/g, '')) < minValueRecharge ? handleChange(minValueRecharge, RechargeTypes.VALUE_OF_RECHARGE) : null }
                    value={parseFloat(valueOfRecharge).toLocaleString('pt-BR')}
                    prefix='R$'
                    suffix=',00'
                  />
                </Form.Item>
                {
                  isTaxed
                  ? <Typography.Text>OBS: A taxa de remoção/instalação do dispositivo (R$ 149,00) não será incluída nos seus créditos, conforme descrito no item 4.5.3.2 do <a href="https://onsurance.me/termos-de-uso#onboard" target="_blank" rel="noopener noreferrer">Termo de Uso</a>.</Typography.Text>
                  : null
                }
              </Form>
            </Card>
          </Col>
        :
          null
      }
    </>
  )
}

const mapStateToProps = createStructuredSelector({
  creditRecharge: selectCreditRecharge
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type)),
  onVehicleOfChangeRechargeSelected: item => dispatch(onVehicleOfChangeRechargeSelected(item)),
  unselectVehicleFromRecharge: () => dispatch(unselectVehicleFromRecharge()),
  onQuoteClickOfChangeRechargeSelected: quote => dispatch(onQuoteClickOfChangeRechargeSelected(quote)),
  unselectQuoteFromRecharge: () => dispatch(unselectQuoteFromRecharge())
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeVehicleRecharge);
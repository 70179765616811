// React
import React from 'react'
// SCSS
import './divider-text.style.scss'

const DividerWithText = ({ children }) => {
  return (
    <>
      <span className='divider' />
        <p>
        {
          children
        }
        </p>
      <span className='divider' />
    </>
  )
};

const DividerWithoutText = ({ sm }) => (
  <span className={`divider ${sm}`}></span>
);

export default ({ children, sm }) => {
  return (
    <div className='d-flex justify-center divider-container'>
    {
      children
      ?
        <DividerWithText children={children} />
      :
        <DividerWithoutText sm={sm} />
    }
    </div>
  );
}
import React from 'react'
// AntDesign
import { Col, Input, Form } from 'antd'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectValueOfRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { handleChange } from '../../redux/action'
import RechargeTypes from '../../redux/credit-recharge/credit-recharge.types'
import './select-value.style.scss'

const SelectValueComponent = ({ valueOfRecharge, handleChange }) => {
  return (
    <Col xs={24}>
      <Form className='section form-select-value-container' layout='vertical'>
        <Form.Item label='Digite o valor:' extra='Valor Mínimo e Valor Máximo: R$300,00 / R$5.500,00'>
          <Input
            onChange={e => {
              const VALUE = e.target.value.replace(/\D/g, '')
              handleChange(parseInt(VALUE) > 5500 ? 5500 : VALUE === '' ? 0 : parseInt(VALUE), RechargeTypes.VALUE_OF_RECHARGE)
            }}
            inputMode='numeric'
            onBlur={e => e.target.value < 299 ? handleChange(299, RechargeTypes.VALUE_OF_RECHARGE) : null }
            onPressEnter={e => e.target.value < 299 ? handleChange(299, RechargeTypes.VALUE_OF_RECHARGE) : null }
            value={valueOfRecharge}
            prefix='R$'
            suffix=',00'
          />
        </Form.Item>
      </Form>
    </Col>
  )
}

const mapStateToProps = createStructuredSelector({
  valueOfRecharge: selectValueOfRecharge
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item,type))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectValueComponent);
import React from 'react';
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
// AntDesign
import { Tag } from 'antd';

// Colunas para o AntDesign
export const columnsBoleto = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <Tag color={
        status === 'PENDING' // Pendente
        ?
          'orange'
        :
        status === 'CONFIRMED' || status === 'RECEIVED' // Confirmada
        ?
          'green'
        :
        status === 'OVERDUE' // Vencida
        ?
          'magenta'
        :
          'red' // Nenhuma das opções anteriores
      }>{status}</Tag>
    )
  },
  {
    title: 'Vencimento',
    dataIndex: 'vencimento',
    key: 'vencimento',
  },
  {
    title: 'Boleto',
    dataIndex: 'boleto',
    key: 'boleto',
    render: boleto => (
      <a
        href={boleto}
        target='_blank'
        rel="noopener noreferrer"
        alt='boleto da parcela'
        className='table-boleto'
      >
        Ver boleto <FontAwesomeIcon icon={faEye}/>
      </a>
    )
  }
]

// Colunas para tabela do Credit Card.
export const columnsCreditCard = [
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: status => (
      <Tag color={
        status === 'PENDING' // Pendente
        ?
          'orange'
        :
        status === 'CONFIRMED' || status === 'RECEIVED' // Confirmada
        ?
          'green'
        :
        status === 'OVERDUE' // Vencida
        ?
          'magenta'
        :
          'red' // Nenhuma das opções anteriores
      }>{status}</Tag>
    )
  },
  {
    title: 'Vencimento',
    dataIndex: 'dueDate',
    key: 'dueDate',
  },
  {
    title: 'Valor',
    dataIndex: 'value',
    key: 'value',
    render: valor => (
      <Tag color='blue'>
        {parseFloat(valor).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
      </Tag>
    )
  }
]

// DataSource AntDesign
export const gerarDataSource = cobrancas => {
  try {
    if(cobrancas !== undefined) {
      return cobrancas.map((c, i) => ({key: i, ...c}));
    } else {
      return [];
    }
  } catch(e) {
    return [];
  }
}
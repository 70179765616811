import React from 'react'
// Ant-Design
import { Col, Row } from 'antd'
// redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { selectIsAssistencia } from '../../redux/assistencia24horas/assistencia.selector'
import { selectIsAssistOnlyValue } from '../../redux/checkout/checkout.selector'
// Components
import DividerText from '../divider-text/divider-text.component'
import OnsuranceRadioButton from '../onsurance-radio-button/onsurance-radio-button.component'
import FormEnderecoEntrega from './endereco-entrega'

const MesmoEndereco = ({
  isEnderecoEntrega, handleChange, types, errors, isRecharge, isAssistencia,
  isAss24OnlyOnInitialCredits
}) => {
  if (isRecharge || isAssistencia || isAss24OnlyOnInitialCredits) {
    return null
  }
  return (
    <>
      <Col xs={24}>
        <div className="section top bottom">
          <DividerText>
            O endereço informado é o mesmo para a entrega?
          </DividerText>
        </div>
      </Col>
      <Col xs={24}>
        <div className="section bottom">
          <Row gutter={16}>
            <Col xs={12}>
              <OnsuranceRadioButton
                id="billing-data-is-entrega-sim"
                value='SIM'
                name={'isEnderecoEntrega'}
                checkValue={isEnderecoEntrega}
                onChange={e => handleChange(e.target.value, types.BILLING_DATA_IS_ENDERECO_ENTREGA)}
              />
            </Col>
            <Col xs={12}>
              <OnsuranceRadioButton
                id="billing-data-is-entrega-nao"
                value='NAO'
                name={'isEnderecoEntrega'}
                checkValue={isEnderecoEntrega}
                onChange={e => handleChange(e.target.value, types.BILLING_DATA_IS_ENDERECO_ENTREGA)}
              />
            </Col>
          </Row>
        </div>
      </Col>
      {
        isEnderecoEntrega === 'NAO'
        ?
        <>
          <Col xs={24}>
            <div className="section">
              <p>Informe seu endereço de entrega.</p>
            </div>
          </Col>
          <Col xs={24}>
            <div className="section">
              <FormEnderecoEntrega handleChange={handleChange} errors={errors} />
            </div>
          </Col>
        </>
        :
        null
      }
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  isRecharge: selectIsRecharge,
  isAssistencia: selectIsAssistencia,
  isAss24OnlyOnInitialCredits: selectIsAssistOnlyValue
})

export default connect(mapStateToProps)(MesmoEndereco);
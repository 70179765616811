import React, { useState, useEffect } from 'react'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectDadosClienteRecarga } from '../../redux/checkout/checkout.selector'
import { selectCpf } from '../../redux/billing-data/billing-data.selector'
import { selectIsAssistencia } from '../../redux/assistencia24horas/assistencia.selector'
import { requestAssistenciaValue } from '../../redux/action'
// Ant Design
import { Row, Col, Tag, Card, Form, Input, message, Descriptions } from 'antd'
// Components
import { PageTitle, ButtonOnsurance, Jumbotron } from '../../components'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import './confirmar-dados.style.scss'

// Route: /confirmar-dados
const ConfirmarDadosPage = ({ history, nomeTelefone, cpf, isAssistencia, requestAssistenciaValue }) => {
  const { nome, telefone, sobrenome } = nomeTelefone

  useEffect(() => {
    document.title = 'Confirmar dados - Onsurance'
    if (nome === '') {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      })
      history.replace('/')
    }
  }, [nome, history])

  const [localState, setLocalState] = useState({ cpf: '', telefone: '' })

  return (
    <Row className='confirmar-dados-page'>
      <Col xs={24}>
        <PageTitle>
          Confirme seus dados
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          {
            `Confirme seus dados antes de presseguir com o pedido de ${isAssistencia ? 'Assisetência 24h' : 'Recarga de Crédios'}. Se os ultimos números não
            coincidirem com seus dados, verifique seu e-mail na página anterior. Caso o erro persistir, entre
            em contato com nosso suporte.`
          }
        </Jumbotron>
      </Col>
      <Col xs={24}>
        <div className="section top div-table-container">
          <Card bodyStyle={{padding: '0px'}} bordered={false}>
            <Descriptions bordered>
              <Descriptions.Item label='Nome'>
                {`${nome} ${sobrenome}`}
              </Descriptions.Item>
              <Descriptions.Item label='CPF'>
              {
                cpf === undefined || cpf === ''
                ?
                  <Tag color='orange'>
                    Não informado
                  </Tag>
                :
                (/^\d{11}$/).test(cpf)
                ? // Quando o cpf vier como: 12345678909; Retorna => ***.***.789-09
                  <Tag color='blue'>
                    {cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '***.***.$3-$4')}
                  </Tag>
                : // Quando o cpf vier como: 123.456.789-09; Retorna => ***.***.789-09
                  <Tag color='blue'>
                    {cpf.replace(/(\d{3}).(\d{3}).(\d{3})-(\d{2})/, '***.***.$3-$4')}
                  </Tag>
              }
              </Descriptions.Item>
              <Descriptions.Item label='Telefone'>
                <Tag color='blue'>
                  {
                    telefone === undefined || telefone === ''
                    ?
                      <>Não cadastrado</>
                    :
                    (/^\d{11}$/).test(telefone)
                    ? // Quando o telefone vier como: 12345678909; Retorna => (**) *****-8909
                    telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '(**) *****-$3')
                    : // Quando o telefone vier como: (61) 34567-8909; Retorna => (**) *****-8909
                    telefone.replace(/(\(\d{2}\)) (\d{5})-(\d{4})/, '(**) *****-$3')
                  }
                </Tag>
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </div>
      </Col>
      {
        cpf === '' ?
          <Col xs={24}>
            <div className='section top'>
              <Form layout='vertical'>
                <Form.Item
                  label='Digite o seu número de Telefone.'
                  extra='Precisa ser o mesmo número que está sendo exibido acima.'
                >
                  <Input
                    inputMode='tel'
                    onChange={e => {
                      const VALOR = e.target.value.replace(/\D/g, '')
                      .replace(/(\d{2})(\d)/, '($1) $2')
                      .replace(/(\d{4})(\d)/, '$1-$2')
                      .replace(/(\d{4})-(\d)(\d{4})/, '$1$2-$3')
                      .replace(/(-\d{4})\d+?$/, '$1')
                      setLocalState({...localState, telefone: VALOR});
                    }}
                    value={localState.telefone}
                    onPressEnter={async () => {
                      if(isAssistencia) { // Se for assistência 24h
                        await requestAssistenciaValue();
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        history.push('/assistencia-24-horas');
                        return;
                      }
                      // Se o Telefone for por exemplo 12345678909
                      if ((/^\d{11}$/).test(telefone)) {
                        // Se quando o Telefone formatado for igual ao Telefone armazenado do usuário.
                        if ( telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3') === localState.telefone ) {
                          history.push('/recarga-de-creditos');
                          window.scrollTo({top: 0, behavior: 'smooth'});
                          return;
                        }
                      } else {
                        // se o Telefone for igual ao que temos no state.
                        if (telefone === localState.telefone) {
                          history.push('/recarga-de-creditos');
                          window.scrollTo({top: 0, behavior: 'smooth'});
                          return;
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          </Col>
        :
          <Col xs={24}>
            <div className='section top'>
              <Form layout='vertical'>
                <Form.Item label='Digite o seu número de CPF.' extra='Precisa ser o mesmo número que está sendo exibido acima.'>
                  <Input
                    inputMode='numeric'
                    onChange={e => {
                      const VALOR = e.target.value.replace(/\D/g, '')
                      .replace(/(\d{3})(\d)/, '$1.$2')
                      .replace(/(\d{3})(\d)/, '$1.$2')
                      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
                      .replace(/(-\d{2})\d+?$/, '$1')
                      setLocalState({...localState, cpf: VALOR});
                    }}
                    value={localState.cpf}
                    onPressEnter={async () => {
                      if(isAssistencia) { // Se for assistência 24h
                        await requestAssistenciaValue();
                        window.scrollTo({top: 0, behavior: 'smooth'});
                        history.push('/assistencia-24-horas');
                        return;
                      }
                      // Se o CPF for por exemplo 12345678909
                      if ((/^\d{11}$/).test(cpf)) {
                        // Se quando o cpf formatado for igual ao cpf armazenado do usuário.
                        if ( cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4') === localState.cpf ) {
                          window.scrollTo({top: 0, behavior: 'smooth'});
                          history.push('/recarga-de-creditos');
                          return;
                        }
                      } else {
                        // se o CPF for igual ao que temos no state.
                        if (cpf === localState.cpf) {
                          window.scrollTo({top: 0, behavior: 'smooth'});
                          history.push('/recarga-de-creditos');
                          return;
                        }
                      }
                    }}
                  />
                </Form.Item>
              </Form>
            </div>
          </Col>
      }
      <Col xs={24} md={12} className='voltar-md'>
        <div className='d-flex justify-center section top final-content pr-1'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
      {
        cpf === '' ?
          <Col xs={24} md={12}>
            <div className='d-flex justify-center section top'>
              <div className='pl-1 w-100'>
                <ButtonOnsurance
                  type='confirm'
                  bloc
                  onClick={async () => {
                    if(isAssistencia) {
                      await requestAssistenciaValue();
                      window.scrollTo({top: 0, behavior: 'smooth'});
                      history.push('/assistencia-24-horas');
                      return;
                    }
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    history.push('/recarga-de-creditos');
                  }}
                  disabled={
                    (/^\d{11}$/).test(telefone) ?
                      telefone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3') !== localState.telefone
                    :
                      telefone !== localState.telefone
                  }
                >
                  Confirmar
                </ButtonOnsurance>
              </div>
            </div>
          </Col>
        :
          <Col xs={24} md={12}>
            <div className='d-flex justify-center section top'>
              <div className='pl-1 w-100'>
                <ButtonOnsurance
                  type='confirm'
                  bloc
                  onClick={async () => {
                    if(isAssistencia) {
                      await requestAssistenciaValue();
                      window.scrollTo({top: 0, behavior: 'smooth'});
                      history.push('/assistencia-24-horas');
                      return;
                    }
                    window.scrollTo({top: 0, behavior: 'smooth'});
                    history.push('/recarga-de-creditos');
                  }}
                  disabled={
                    (/^\d{11}$/).test(cpf) ?
                      cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4') !== localState.cpf :
                      cpf !== localState.cpf
                  }
                >
                  Confirmar
                </ButtonOnsurance>
              </div>
            </div>
          </Col>
      }
      <Col xs={24} md={12} className='voltar-xs'>
        <div className='d-flex justify-center section top final-content'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  )
}

const mapStateToProps = createStructuredSelector({
  nomeTelefone: selectDadosClienteRecarga,
  cpf: selectCpf,
  isAssistencia: selectIsAssistencia
});

const mapDispatchToProps = dispatch => ({
  requestAssistenciaValue: () => dispatch(requestAssistenciaValue())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmarDadosPage);
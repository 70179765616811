// React
import React from 'react'
// SCSS
import './jumbotron.style.scss'

const DivJumbotron = ({ children }) => (
  <div className="jumbotron">
    {
      children
    }
  </div>
)

export default ({ children, isDiv }) => {
  if (isDiv === undefined) return (
    <div className='jumbotron'>
      <p>
      {
        children
      }
      </p>
    </div>
  );
  return (
    <DivJumbotron>
    {
      children
    }
    </DivJumbotron>
  )
}
// Redux
import { applyMiddleware, createStore } from 'redux';
// RootReducer
import RootReducer from './root-reducer'
// Middlewares
import logger from 'redux-logger'
import thunk from 'redux-thunk'

// Criando um array com os middlewares utilizados.
const middlewares = [thunk];

// Caso o serviço não estiver em produção vamos incluir o logger nos middlewares
if (process.env.NODE_ENV === 'development') middlewares.push(logger);

// Criando o nosso store.
const store = createStore(
  RootReducer,
  applyMiddleware(...middlewares)
);

export default store;
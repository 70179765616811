// Ant-Design
import { notification, message } from 'antd'
// Post Method
import { fetchPost, fetchGet } from './utils'
// Validating credit card
import valid from 'card-validator'

const ambienteEnum = {
  DESENVOLVIMENTO: 'DESENVOLVIMENTO',
  PRODUCAO: 'PRODUCAO'
}
const ambiente = ambienteEnum.PRODUCAO;

export const baseURL = ambiente === ambienteEnum.PRODUCAO
?
'https://us-central1-onsurance-new.cloudfunctions.net/paymentService'
:
'https://us-central1-onsurance-homologacao.cloudfunctions.net/paymentService'

// Armazena todos os Tipos de actions possíveis dos nossos reducers.
export const Types = {
  COTACAO_NUMBER: 'COTACAO_NUMBER',
  COTACAO_EMAIL: 'COTACAO_EMAIL',
  COTACAO_WITH_PARAMETERS: 'COTACAO_WITH_PARAMETERS',
  SET_CHECKOUT_ITENS: 'SET_CHECKOUT_ITENS',
  REQUEST_END: 'REQUEST_END',
  REQUEST_START: 'REQUEST_START',
  CHECKOUT_VALOR_MONTANTE: 'CHECKOUT_VALOR_MONTANTE',
  BILLING_DATA_CPF: 'BILLING_DATA_CPF',
  BILLING_DATA_ENDERECO: 'BILLING_DATA_ENDERECO',
  BILLING_DATA_ESTADO: 'BILLING_DATA_ESTADO',
  BILLING_DATA_NUMERO: 'BILLING_DATA_NUMERO',
  BILLING_DATA_CIDADE: 'BILLING_DATA_CIDADE',
  BILLING_DATA_BAIRRO: 'BILLING_DATA_BAIRRO',
  BILLING_DATA_COMPLEMENTO: 'BILLING_DATA_COMPLEMENTO',
  BILLING_DATA_IS_ENDERECO_ENTREGA: 'BILLING_DATA_IS_ENDERECO_ENTREGA',
  BILLING_DATA_CEP: 'BILLING_DATA_CEP',
  BILLING_DATA_ENDERECO_ENTREGA: 'BILLING_DATA_ENDERECO_ENTREGA',
  BILLING_DATA_ESTADO_ENTREGA: 'BILLING_DATA_ESTADO_ENTREGA',
  BILLING_DATA_NUMERO_ENTREGA: 'BILLING_DATA_NUMERO_ENTREGA',
  BILLING_DATA_CIDADE_ENTREGA: 'BILLING_DATA_CIDADE_ENTREGA',
  BILLING_DATA_BAIRRO_ENTREGA: 'BILLING_DATA_BAIRRO_ENTREGA',
  BILLING_DATA_COMPLEMENTO_ENTREGA: 'BILLING_DATA_COMPLEMENTO_ENTREGA',
  BILLING_DATA_IS_PARCELADO: 'BILLING_DATA_IS_PARCELADO',
  BILLING_DATA_QTD_PARCELA: 'BILLING_DATA_QTD_PARCELA',
  REQUEST_JUROS_ENTRADA_END: 'REQUEST_JUROS_ENTRADA_END',
  CHECKOUT_CONFIRMACAO_PEDIDO: 'CHECKOUT_CONFIRMACAO_PEDIDO',
  CHECKOUT_REMOVE_CART_ITEM: 'CHECKOUT_REMOVE_CART_ITEM',
  CHECKOUT_ADD_REMOVED_ITEM: 'CHECKOUT_ADD_REMOVED_ITEM',
  CHECKOUT_ADD_CART_ITEM: 'CHECKOUT_ADD_CART_ITEM',
  CHECKOUT_REMOVE_REMOVED_ITEM: 'CHECKOUT_REMOVE_REMOVED_ITEM',
  VIA_CEP: 'VIA_CEP',
  BILLING_DATA_ERROR: 'BILLING_DATA_ERROR',
  CHECKOUT_RECOMENDADOS_ADD: 'CHECKOUT_RECOMENDADOS_ADD',
  BILLING_DATA_TIPO_FRETE: 'BILLING_DATA_TIPO_FRETE',
  BILLING_DATA_LOADING: 'BILLING_DATA_LOADING',
  BILLING_DATA_VALOR_FRETE: 'BILLING_DATA_VALOR_FRETE',
  CHECKOUT_AFFILIATE_ID: 'CHECKOUT_AFFILIATE_ID',
  BILLING_DATA_CREDIT_CARD_NAME: 'BILLING_DATA_CREDIT_CARD_NAME',
  BILLING_DATA_CREDIT_CARD_NUMBER: 'BILLING_DATA_CREDIT_CARD_NUMBER',
  BILLING_DATA_CREDIT_CARD_VALIDTHRU: 'BILLING_DATA_CREDIT_CARD_VALIDTHRU',
  BILLING_DATA_CREDIT_CARD_CVV: 'BILLING_DATA_CREDIT_CARD_CVV',
  PAYMENT_METHOD_CARD: 'PAYMENT_METHOD_CARD',
  LIMPAR_STATE_POR_COMPLETO: 'LIMPAR_STATE_POR_COMPLETO',
  COTACAO_EMAIL_RECARGA: 'COTACAO_EMAIL_RECARGA',
  CHECKOUT_DADOS_PARA_RECARGA: 'CHECKOUT_DADOS_PARA_RECARGA',
  CHECKOUT_TIPO_DE_RECARGA: 'CHECKOUT_TIPO_DE_RECARGA',
  CHECKOUT_VALOR_DA_RECARGA: 'CHECKOUT_VALOR_DA_RECARGA',
  COTACAO_NUMBER_RECARGA: 'COTACAO_NUMBER_RECARGA',
  COTACAO_SET_MIN_VALUE: 'COTACAO_SET_MIN_VALUE',
  COTACAO_LOADING_MIN_VALUE: 'COTACAO_LOADING_MIN_VALUE',
  ASSISTENCIA_EMAIL: 'ASSISTENCIA_EMAIL',
  DATA_EMAIL_ASSISTENCIA: 'DATA_EMAIL_ASSISTENCIA',
  IS_NOT_ASSISTENCIA: 'IS_NOT_ASSISTENCIA',
  SET_INDICATED: 'SET_INDICATED'
}

// Verificando os erros enviados pelo servidor
const billingDataVerifyErrors = (errors) => {
  let ERRORS = {};
  errors.forEach(erro => {
    switch(erro.location){
      case "cpf":
        ERRORS = {...ERRORS, cpf: erro.message};
        break;

      case "endereco":
        ERRORS = {...ERRORS, endereco: erro.message};
        break;

      case "estado":
        ERRORS = {...ERRORS, estado: erro.message};
        break;

      case "numero":
        ERRORS = {...ERRORS, numero: erro.message};
        break;

      case "cidade":
        ERRORS = {...ERRORS, cidade: erro.message};
        break;

      case "bairro":
        ERRORS = {...ERRORS, bairro: erro.message};
        break;

      case "complemento":
        ERRORS = {...ERRORS, complemento: erro.message};
        break;

      case "cepEntrega":
        ERRORS = {...ERRORS, cepEntrega: erro.message};
        break;

      case "enderecoEntrega":
        ERRORS = {...ERRORS, enderecoEntrega: erro.message};
        break;

      case "estadoEntrega":
        ERRORS = {...ERRORS, estadoEntrega: erro.message};
        break;

      case "numeroEntrega":
        ERRORS = {...ERRORS, numeroEntrega: erro.message};
        break;

      case "cidadeEntrega":
        ERRORS = {...ERRORS, cidadeEntrega: erro.message};
        break;

      case "bairroEntrega":
        ERRORS = {...ERRORS, bairroEntrega: erro.message};
        break;

      case "complementoEntrega":
        ERRORS = {...ERRORS, complementoEntrega: erro.message};
        break;
      
      default:
        break;
    }
  });
  return ERRORS;
}

// Verifica se o cartão é válido.
export const verificaValidadeCartao = CreditCard => {
  const { number, validThru } = CreditCard
  let VALID = {
    number: true,
    date: true
  }
  if(!valid.number(number).isValid) {
    VALID.number = false
  }
  if(!valid.expirationDate(validThru).isValid) {
    VALID.date = false
  }
  return VALID
}

// Action para Trocar o valor dos campos no state
// Recebe o item como primeiro parâmetro e o tipo(type) como segundo parâmetro.
export const handleChange = (item, type) => ({
  type,
  payload: item
});

// Action para buscar por uma cotação existente no back-end.
export const requestCotacao = (cotacao, history) => {
  const { email, cotacaoNumber } = cotacao;
  return dispatch => {
    document.body.style.overflow = 'hidden';
    dispatch(handleChange({email, cotacaoNumber} ,Types.COTACAO_WITH_PARAMETERS))
    dispatch(handleChange(null, Types.REQUEST_START))
    const body = {
      email,
      cotacao: cotacaoNumber
    }
    return fetchPost(`${baseURL}/recuperarcot`, body)
    .then(data => {
      const { code, message } = data
      switch (code) {
        case 404:
          notification.error({message: message, duration: 4});
          document.body.style.overflow = 'unset';
          dispatch(handleChange(null ,Types.REQUEST_END))
          break;

        case 500:
          notification.error({message: message, duration: 4});
          document.body.style.overflow = 'unset';
          dispatch(handleChange(null ,Types.REQUEST_END))
          break;
      
        default:
          document.body.style.overflow = 'unset';
          dispatch(handleChange(data, Types.SET_CHECKOUT_ITENS))
          window.scrollTo({ top: 0, behavior: 'smooth' })
          history.push('/checkout')
          break;
      }
    })
    .catch(e => {
      notification.error({message: 'Falha ao buscar sua cotação', duration: 4});
      document.body.style.overflow = 'unset';
      dispatch(handleChange(null ,Types.REQUEST_END))
    })
  }
}

// Decodificador da base64
export const base64Decoder = item => {
  try {
    let buff = Buffer.from(item, 'base64');
    return buff.toString('ascii');
  } catch(e) {
    // Caso não ter informado o item.
    if(item === undefined || item === null) return 'Não informado'
    // caso ter informado porêm ocorreu algum outro erro.
    else return item
  }
}

// Busca no back-end o valor da Assistência 24h
export const requestAssistenciaValue = () => async dispatch => {
  document.body.style.overflow = 'hidden';
  dispatch({type: Types.REQUEST_START});
  await fetchGet(`${baseURL}/juros`)
  .then(data => {
    const { ass24 } = data
    dispatch(handleChange({ ass24 }, Types.REQUEST_JUROS_ENTRADA_END))
    document.body.style.overflow = 'unset';
    dispatch({type: Types.REQUEST_END})
  })
  .catch(e => {
    notification.error({
      message: 'Erro interno',
      description: 'Foi identificada uma falha na comunicação com nosso servidor, tente novamente mais tarde.'
    });
    document.body.style.overflow = 'unset';
    dispatch({type: Types.REQUEST_END})
  });
}

// Busca no back-end os dados necessários para comprar a assistência 24h
export const requestDadosParaAssistencia = (email, history) => dispatch => {
  document.body.style.overflow = 'hidden';
  dispatch(handleChange(null, Types.REQUEST_START))
  if (email === '') email = 'seuemail@onsurance.me'
  fetchGet(`${baseURL}/checkclient/${email}`)
  .then(data => {
    const { code, message } = data
    switch (code) {
      case 400:
        notification.error({
          message: 'Usuário não encontrado',
          description: message
        });
        dispatch(handleChange(null, Types.REQUEST_END));
        document.body.style.overflow = 'unset';
        break;
    
      default:
        if(data.veiculos.length === 0) {
          notification.error({
            message: `${base64Decoder(data.nome)}, não é possível adicionar assistência 24 horas.`,
            description: 'Nosso sistema identificou que você possui assistência 24 horas em todos os seus veículos',
            duration: 4
          });
          dispatch(handleChange(null, Types.REQUEST_END));
          document.body.style.overflow = 'unset';
          return;
        }
        const BASE64_DECODE_DATA = {
          cep: base64Decoder(data.cep),
          nome: base64Decoder(data.nome),
          sobrenome: base64Decoder(data.sobrenome),
          telefone: base64Decoder(data.telefone),
          veiculos: data.veiculos.map(veiculo => {
            return {
              ano: base64Decoder(veiculo.ano),
              marca: base64Decoder(veiculo.marca),
              modelo: base64Decoder(veiculo.modelo),
              placa: base64Decoder(veiculo.placa),
              tipo: base64Decoder(veiculo.tipo),
            }
          })
        }
        dispatch(handleChange(data.cpf === undefined ? '' : base64Decoder(data.cpf), Types.BILLING_DATA_CPF))
        dispatch(handleChange(BASE64_DECODE_DATA, Types.DATA_EMAIL_ASSISTENCIA));
        dispatch(handleChange(null, Types.REQUEST_END));
        document.body.style.overflow = 'unset';
        history.push('/confirmar-dados')
        break;
    }
  })
  .catch(e => {
    notification.error({
      message: 'Falha na comunicação',
      description: 'Estamos com um problema ao conectar com nossos servidores, tente novamente mais tarde'
    });
    dispatch(handleChange(null, Types.REQUEST_END))
    document.body.style.overflow = 'unset';
  });
}

// Action para buscar os valores de juros e entrada
export const requestMetaData = paymentMethod => async (dispatch, getState) => {
  document.body.style.overflow = 'hidden';
  dispatch(handleChange(null, Types.REQUEST_START));
  dispatch(handleChange('', Types.BILLING_DATA_IS_PARCELADO));

  if (paymentMethod === 'cartao') {
    dispatch(handleChange(true, Types.PAYMENT_METHOD_CARD));
  }

  const { checkout: { cep }, creditRecharge: { isRecharge } } = getState()
  await fetchGet(`${baseURL}/juros`)
  .then(data => {
    dispatch(handleChange(data, Types.REQUEST_JUROS_ENTRADA_END));
    const { checkout: { itens, metaData: { entrada } }, billingData: { tipoFrete: { valor } } } = getState()
    const valorTotal = itens.reduce((total, current) => {
      return total += current.valor
    }, 0)
    if (isRecharge) {
      dispatch(handleChange(valorTotal, Types.CHECKOUT_VALOR_MONTANTE))
    } else {
      if (paymentMethod === 'boleto') {
        dispatch(handleChange(((valorTotal + valor) - entrada), Types.CHECKOUT_VALOR_MONTANTE))
        // if (process.env.NODE_ENV === 'development') notification.info({message: '(valorTotal + valorFrete) - entrada', description: 'Caiu no valor montante do boleto'})
      } else if (paymentMethod === 'cartao') {
        dispatch(handleChange((valorTotal + valor), Types.CHECKOUT_VALOR_MONTANTE))
        // if (process.env.NODE_ENV === 'development') notification.info({message: 'valorTotal + valorFrete', description: 'Caiu no valor montante do cartão'})
      }
    }
  })
  .catch(e => {
    notification.error({message: 'Erro interno', duration: 4});
    document.body.style.overflow = 'unset';
    dispatch(handleChange(null ,Types.REQUEST_END))
  });
  await fetchGet(`https://viacep.com.br/ws/${cep.replace('-', '')}/json/`)
  .then(data => {
    dispatch(handleChange(data, Types.VIA_CEP))
  })
  .catch(e => {
    notification.error({message: 'Erro interno', duration: 4});
    document.body.style.overflow = 'unset';
    dispatch(handleChange(null ,Types.REQUEST_END))
  });
  document.body.style.overflow = 'unset';
}

// Action para gerar o boleto para o usuário
export const onClickComprar = (history, paymentMethod) => (dispatch, getState) => {
  document.body.style.overflow = 'hidden';
  dispatch(handleChange(null, Types.REQUEST_START));
  const { checkout, billingData, cotacao, assistencia, creditRecharge } = getState();
  const { cotacaoNumber, email } = cotacao
  const {
    cpf, endereco, estado, numero, cidade, bairro, complemento, isEnderecoEntrega,
    enderecoEntrega, estadoEntrega, numeroEntrega, cidadeEntrega, bairroEntrega, complementoEntrega,
    isParcelado, qtdParcela, tipoFrete: { frete }, creditCard, ...props
  } = billingData
  const { nome, sobrenome, cep, telefone, itens, referralId, metaData: { ass24 }, selectedIndication } = checkout
  const { isAssistencia, veiculosSelecionados, assistenciaEmail } = assistencia
  const { isRecharge, emailRecharge, cotacaoNumberRecharge, isTaxed } = creditRecharge
  const { holderName, number, validThru, cvc } = creditCard

  if(paymentMethod === 'cartao') {
    if(!verificaValidadeCartao(creditCard).number) {
      notification.warning({message: 'Número do cartão inválido.', description: 'Verifique os dados do seu cartão de crédito e tente novamente.'});
      dispatch(handleChange({cardNumber: 'O número do cartão está inválido.'}, Types.BILLING_DATA_ERROR))
      document.body.style.overflow = 'unset';
      dispatch(handleChange(null, Types.REQUEST_END));
      return;
    }
    if(!verificaValidadeCartao(creditCard).date) {
      notification.warning({message: 'Data de vencimento é inválida.', description: 'Verifique os dados do seu cartão de crédito e tente novamente.'});
      dispatch(handleChange({cardDate: 'A data do cartão está inválida.'}, Types.BILLING_DATA_ERROR))
      document.body.style.overflow = 'unset';
      dispatch(handleChange(null, Types.REQUEST_END));
      return;
    }
  }

  const dadosEntrega = {
    endereco: enderecoEntrega,
    numero: numeroEntrega,
    cep: props.cep,
    cidade: cidadeEntrega,
    uf: estadoEntrega,
    bairro: bairroEntrega,
    complemento: complementoEntrega
  }
  const dadosCartao = {
    ccv: cvc,
    holderName,
    number,
    expiryMonth: validThru.replace(/(\d{2}\/)\d+?$/, '$1').replace(/\//g, ''),
    expiryYear: validThru.replace(/(\d{2}\/)/, '')
  }
  const gerarCobrancaBody = {
    nome,
    sobrenome,
    email: isRecharge ? emailRecharge : isAssistencia ? assistenciaEmail : email,
    cpf,
    telefone,
    cotacaoId: isRecharge ? cotacaoNumberRecharge : cotacaoNumber,
    recarga: isRecharge,
    isTaxado: isTaxed,
    referralId,
    hasIndicante: selectedIndication !== null ? true : false,
    indicacaoId: selectedIndication !== null ? selectedIndication.id : null,
    isAssistencia,
    forcarError: false,
    endereco: {
      pagamento: {
        endereco,
        numero,
        cep,
        cidade,
        uf: estado,
        bairro,
        complemento
      },
      entrega: isEnderecoEntrega === 'NAO' ? dadosEntrega : {
        endereco,
        numero,
        cep,
        cidade,
        uf: estado,
        bairro,
        complemento
      }
    },
    pagamento: {
      tipo: paymentMethod === 'boleto' ? 'BOLETO' : paymentMethod === 'cartao' ? 'CREDIT_CARD' : null,
      parcelado: isParcelado === 'SIM' ? true : false,
      qtdParcelas: parseInt(qtdParcela),
      creditCard: paymentMethod === 'boleto' ? {} : dadosCartao
    },
    itens: [],
    cotacao: {},
    sedex: frete === 'SEDEX' ? true : false
  }
  if(isAssistencia) {
    veiculosSelecionados.forEach(item => {
      const { modelo, placa, tipo } = item
      gerarCobrancaBody.itens.push({
        produtoId: 386,
        qtd: 1,
        desc: `${modelo} - Assistência 24Hrs`,
        valor: ass24,
        desconto: 0.0,
        placa,
        tipo
      });
    })
  } else {
    itens.forEach(item => {
      const { tipo, valor } = item
      switch (tipo) {
        case 'Ass24':
          gerarCobrancaBody.itens.push({
            produtoId: 386,
            qtd: 1,
            desc: 'Assistência 24Hrs anual',
            valor,
            desconto: 0.0
          });
          break;
  
        case 'Moto':
          gerarCobrancaBody.itens.push({
            produtoId: 11598,
            qtd: 1,
            desc: 'Crédito inicial - Onsurance moto',
            valor,
            desconto: 0.0
          });
          break;
  
        case 'RecargaSimples':
          gerarCobrancaBody.itens.push({
            produtoId: 485,
            qtd: 1,
            desc: 'Recarga Simples - Onsurance',
            valor,
            desconto: 0.0
          });
          break;
  
        case 'RecargaAjusteInicial':
          gerarCobrancaBody.itens.push({
            produtoId: ambiente === ambienteEnum.DESENVOLVIMENTO ? 15089 : 16159,
            qtd: 1,
            desc: 'Recarga de Ajuste Inicial - Onsurance',
            valor,
            desconto: 0.0
          });
          break;
  
        case 'RecargaTrocaDeVeiculo':
          gerarCobrancaBody.itens.push({
            produtoId: ambiente === ambienteEnum.DESENVOLVIMENTO ? 15088 : 16158,
            qtd: 1,
            desc: 'Recarga de Troca de Veículo - Onsurance',
            valor,
            desconto: 0.0
          });
          break;
  
        default:
          gerarCobrancaBody.itens.push({
            produtoId: 1513,
            qtd: 1,
            desc: 'Crédito inicial - Onsurance auto',
            valor,
            desconto: 0.0
          });
          break;
      }
    });
  }
  const END_POINTS = {
    cartao: `${baseURL}/criarpedidocartao`,
    boleto: `${baseURL}/criarpedido`
  }
  try {
    return fetchPost(paymentMethod === 'boleto' ? END_POINTS.boleto : END_POINTS.cartao, gerarCobrancaBody)
    .then(data => {
      const { code } = data
      switch (code) {
        case 500:
          notification.error({message: data.message, duration: 4});
          document.body.style.overflow = 'unset';
          dispatch(handleChange(null, Types.REQUEST_END));
          break;

        case 400:
          notification.error({message: data.message, description: data.errors[0].message, duration: 6});
          document.body.style.overflow = 'unset';
          dispatch(handleChange(billingDataVerifyErrors(data.errors), Types.BILLING_DATA_ERROR));
          dispatch(handleChange(null, Types.REQUEST_END));
          break;

        default:
          document.body.style.overflow = 'unset';
          dispatch(handleChange(data, Types.CHECKOUT_CONFIRMACAO_PEDIDO));
          window.scrollTo({ top: 0, behavior: 'smooth' })
          if (isRecharge) notification.success({
            message: 'Cobrança realizada',
            description: 'Seu pedido de recarga foi gerado com sucesso'
          }) 
          else
            notification.success({
              message: 'Cobrança realizada',
              description: 'Seu pedido foi gerado com sucesso, siga os próximos passos'
            })
          history.push('/pedido-confirmado')
          break;
      }
    })
    .catch(e => {
      notification.error({message: 'Falha na comunicação', description: 'Houve um falha na comunicação com os nossos servidores. Sua compra não foi processada.', duration: 6});
      document.body.style.overflow = 'unset';
      dispatch(handleChange(null, Types.REQUEST_END));
    });
  } catch(e) {
    notification.error({message: 'Falha na requisição', description: 'Sua compra não foi processada.', duration: 4});
    document.body.style.overflow = 'unset';
    dispatch(handleChange(null, Types.REQUEST_END));
  }
}

// Action para remover o item do carrinho de compras
export const onRemoveItemFromCart = item => (dispatch, getState) => {
  const { checkout: { itens } } = getState()
  // Novo Array
  const newItens = itens.filter(i => !(i === item))
  dispatch(handleChange(newItens, Types.CHECKOUT_REMOVE_CART_ITEM))
  // Adicionando item no Array de arrays removidos
  dispatch(handleChange(item, Types.CHECKOUT_ADD_REMOVED_ITEM))
}

// Action para adicionar o item no carrinho de compras
export const onAddItemOnCart = item => (dispatch, getState) => {
  const { checkout: { removedItens } } = getState()
  // Novo Array
  const newRemovedItens = removedItens.filter(i => !(i === item))
  dispatch(handleChange(newRemovedItens, Types.CHECKOUT_REMOVE_REMOVED_ITEM))
  // Adicionando item no Array de arrays removidos
  dispatch(handleChange(item, Types.CHECKOUT_ADD_CART_ITEM))
}

// Action para adicionar o item recomendado no carrinho de compras
export const onRecomendadoAdd = item => dispatch => {
  // Adiciona o Item recomendado no carrinho de compra
  dispatch(handleChange(item, Types.CHECKOUT_RECOMENDADOS_ADD))
}

// Action para Fazer a consulta no back-end e retornar o valor do frete
export const onTipoEntregaPressed = (value, paymentMethod) => async (dispatch, getState) => {
  dispatch(handleChange(true, Types.BILLING_DATA_LOADING))
  dispatch(handleChange(value, Types.BILLING_DATA_TIPO_FRETE))
  const { billingData: { isEnderecoEntrega, cep, tipoFrete: { frete } }, checkout: { ...props } } = getState();
  const cepUser = isEnderecoEntrega === 'NAO' ? cep : props.cep
  await fetchGet(`${baseURL}/frete/${frete.toLowerCase()}/${cepUser}`)
  .then(data => {
    const { code, message } = data
    switch (code) {
      case 500:
        notification.error({message: 'Falha na consulta do frete', description: message})
        dispatch(handleChange({valor: 9999, prazoCorreios: ''}, Types.BILLING_DATA_VALOR_FRETE))
        break;

      default:
        const { checkout: { itens, metaData: { entrada } } } = getState()
        const valorTotal = itens.reduce((total, current) => {
          return total += current.valor
        }, 0)
        const onlyAssFilter = itens.filter(item => item.tipo !== 'Ass24')
        if (onlyAssFilter.length > 0) {
          if (paymentMethod === 'cartao') {
            dispatch(handleChange((valorTotal + data.valor), Types.CHECKOUT_VALOR_MONTANTE))
          } else {
            dispatch(handleChange(((valorTotal + data.valor) - entrada), Types.CHECKOUT_VALOR_MONTANTE))
          }
        } else {
          if (paymentMethod === 'cartao') {
            dispatch(handleChange((valorTotal), Types.CHECKOUT_VALOR_MONTANTE))
          } else {
            dispatch(handleChange((valorTotal - entrada), Types.CHECKOUT_VALOR_MONTANTE))
          }
        }
        dispatch(handleChange(data, Types.BILLING_DATA_VALOR_FRETE))
        break;
    }
  })
  dispatch(handleChange(false, Types.BILLING_DATA_LOADING))
}

// Action para remover o item do carrinho de compras
export const onVeiculoCadastradoToSelected = item => (dispatch, getState) => {
  const { assistencia: { veiculosCadastrados } } = getState()
  // Novo Array
  const newItens = veiculosCadastrados.filter(i => !(i === item))
  dispatch(handleChange(newItens, 'VEICULO_CADASTRADO_REMOVE'))
  // Adicionando item no Array de arrays removidos
  dispatch(handleChange(item, 'VEICULO_SELECTED_ADD'))
  const { modelo } = item
  message.success({
    content: `${modelo} selecionado`,
    duration: 2
  })
}

// Action para adicionar o item no carrinho de compras
export const onVeiculoSelectedToCadastrado = item => (dispatch, getState) => {
  const { assistencia: { veiculosSelecionados } } = getState()
  // Novo Array
  const newRemovedItens = veiculosSelecionados.filter(i => !(i === item))
  dispatch(handleChange(newRemovedItens, 'VEICULO_SELECTED_REMOVE'))
  // Adicionando item no Array de arrays removidos
  dispatch(handleChange(item, 'VEICULO_CADASTRADO_ADD'))
  const { modelo } = item
  message.info({
    content: `${modelo} removido`,
    duration: 2
  })
}
import { Types } from '../action'

// Nosso Estado defualt, ou seja o valor inicial.
// Iniciamos os valores para não ocorrer nenhum erro na hora em que fazemos a busca
// Desses valores.
const INITIAL_STATE = {
  cotacaoNumber: '',                // Número da cotação
  email: '',                        // Email da cotação
}
// const INITIAL_STATE = {
//   cotacaoNumber: '',                // Número da cotação
//   email: '',                        // Email da cotação
// }

// Esse é o nosso Reducer.
// Recebe o estado(state) como primeiro parâmetro e a action como segundo parâmetro.
// Caso o tipo(type) da action for algum correspondente a esse reducer, o estado vai sofrer
// Alterações conforme especificado no bloco.
// Caso o tipo não corresponder, retornamos o estado sem nenhuma alteração.
const CotacaoReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case Types.COTACAO_NUMBER:
      return {
        ...state,
        cotacaoNumber: action.payload
      }

    case Types.COTACAO_EMAIL:
      return {
        ...state,
        email: action.payload
      }

    case Types.COTACAO_WITH_PARAMETERS:
      return {
        ...state,
        email: action.payload.email,
        cotacaoNumber: action.payload.cotacaoNumber
      }

    case Types.LIMPAR_STATE_POR_COMPLETO:
      return INITIAL_STATE

    default:
      return state;
  }
}

export default CotacaoReducer;
// React/RouterDom
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// Redux
import { Provider } from 'react-redux'
import store from './app/redux/store'
// App
import App from './app/App';
// Cookie
import { CookiesProvider } from 'react-cookie';
// SCSS
import './index.scss';

ReactDOM.render(
  <CookiesProvider>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </CookiesProvider>,
  document.getElementById('root')
);
import React, { useState } from 'react'
// Ant-Design
import { Col, Row, Skeleton } from 'antd'
import DividerText from '../divider-text/divider-text.component'
import OnsuranceRadioButton from '../onsurance-radio-button/onsurance-radio-button.component'
import ModalConfirmation from '../modal-confirmation/modal-confirmation'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { onTipoEntregaPressed, handleChange, Types } from '../../redux/action'
import { selectBillingData } from '../../redux/billing-data/billing-data.selector'
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { selectIsAssistOnlyValue } from '../../redux/checkout/checkout.selector'
import { selectIsAssistencia } from '../../redux/assistencia24horas/assistencia.selector'

const PodeTerFrete = ({
  billingData, onTipoEntregaPressed, isOnlyAss24, handleChange, paymentMethod,
  isRecharge, isAssistencia
}) => {

  const { estado, estadoEntrega, tipoFrete, loading, isParcelado } = billingData
  const { frete, valor } = tipoFrete
  const [ modalVisible, setModalVisible ] = useState(false);

  return (
    <>
      {
        isRecharge || isAssistencia || isOnlyAss24 ?
          null
        :
        <>
          <Col xs={24}>
            <div className="section top bottom">
              <DividerText>
                Tipo de Frete
              </DividerText>
            </div>
          </Col>
          <Col xs={24}>
            <div className="section bottom">
              <Row gutter={16}>
                <Col xs={
                  (estado === 'DF' && estadoEntrega === '') ||
                  (estado !== 'DF' && estadoEntrega === 'DF') ||
                  (estado === 'DF' && estadoEntrega === 'DF') 
                  ? 24 : 12
                }>
                  <OnsuranceRadioButton
                    id="billing-data-tipo-frete-sedex"
                    value='SEDEX'
                    name={'frete'}
                    checkValue={frete}
                    onChange={e => onTipoEntregaPressed(e.target.value, paymentMethod)}
                  />
                </Col>
                {
                  (estado === 'DF' && estadoEntrega === '') ||
                  (estado !== 'DF' && estadoEntrega === 'DF') ||
                  (estado === 'DF' && estadoEntrega === 'DF') 
                  ?
                  null
                  :
                  <Col xs={12}>
                    <OnsuranceRadioButton
                      id="billing-data-tipo-frete-pac"
                      value='PAC'
                      name={'frete'}
                      checkValue={frete}
                      onChange={e => onTipoEntregaPressed(e.target.value, paymentMethod)}
                    />
                  </Col>
                }
              </Row>
            </div>
          </Col>
        </>
      }
      {
        frete === '' ?
        null
        :
        loading ?
          <div className="section top d-flex justify-center w-100">
            <Skeleton.Input style={{ width: '100%' }} active size='default' />
          </div>
        :
          <Col xs={24}>
            <Row justify='space-between' className='section'>
              <Col xs={12}>
                <p className='billing-data-valor-frete'>
                  Valor do frete:
                </p>
              </Col>
              <Col xs={12}>
                <p className='billing-data-valor-frete rigth'>
                  { parseFloat((valor)).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }
                </p>
              </Col>
            </Row>
          </Col>
      }
      {
        !isOnlyAss24 || isAssistencia
        ?
          <>
            <Col xs={24}>
              <div className="section top bottom">
                <DividerText>
                  Parcelar a cobrança
                </DividerText>
              </div>
            </Col>
            <Col xs={24}>
              <div className="section bottom">
                <Row gutter={16}>
                  <Col xs={12}>
                    <OnsuranceRadioButton
                      id="billing-data-is-parcelado-sim"
                      value='SIM'
                      name={'isParcelado'}
                      checkValue={isParcelado}
                      onChange={() => setModalVisible(!modalVisible)}
                    />
                  </Col>
                  <Col xs={12}>
                    <OnsuranceRadioButton
                      id="billing-data-is-parcelado-nao"
                      value='NAO'
                      name={'isParcelado'}
                      checkValue={isParcelado}
                      onChange={e => handleChange(e.target.value, Types.BILLING_DATA_IS_PARCELADO)}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
            <ModalConfirmation
              visible={modalVisible}
              setVisible={() => setModalVisible(!modalVisible)}
              paymentMethod={paymentMethod}
            />
          </>
        :
          null
      }
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  billingData: selectBillingData,
  isOnlyAss24: selectIsAssistOnlyValue,
  isRecharge: selectIsRecharge,
  isAssistencia: selectIsAssistencia,
})

const mapDispatchToProps = dispatch => ({
  onTipoEntregaPressed: (value, paymentMethod) => dispatch(onTipoEntregaPressed(value, paymentMethod)),
  handleChange: (item, type) => dispatch(handleChange(item, type)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PodeTerFrete);
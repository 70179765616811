import React from 'react'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectBillingData } from '../../redux/billing-data/billing-data.selector'
import { Types } from '../../redux/action'
// Ant Design
import { Row, Col, Select } from 'antd'
// Components
import InputGroup from '../onsurance-input/onsurance-input.component'
import InputError from '../input-error-messege/input-error-messege'
import './endereco-cobranca.scss'

const { Option } = Select

const FormEnderecoCobranca = ({ billingData, handleChange, errors }) => {
  const { cpf, endereco, estado, numero, cidade, bairro, complemento, estadoOpts } = billingData
  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <InputGroup
          title='CPF:'
          id='billing-data-input-cpf'
          type='text'
          inputMode='numeric'
          value={cpf}
          required
          onChange={(e) => {
            const CPF = e.target.value.replace(/\D/g, '')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1.$2')
            .replace(/(\d{3})(\d)/, '$1-$2')
            .replace(/(-\d{2})\d+?$/, '$1')
            handleChange(CPF, Types.BILLING_DATA_CPF)
          }}
          extra={typeof errors.cpf !== "undefined" ? <InputError message={errors.cpf} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Endereço:'
          id='billing-data-input-endereco'
          type='text'
          required
          value={endereco}
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_ENDERECO)}
          extra={typeof errors.endereco !== "undefined" ? <InputError message={errors.endereco} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <div className="billing-data-select-container">
          <label><span className='required-input'>* </span>Estado:</label>
          <Select
            className='select-estados-combobox'
            onChange={(e) => handleChange(e, Types.BILLING_DATA_ESTADO)}
            placeholder='Selecione o seu estado'
            value={estado}
            showSearch
            filterOption={(input, option) => 
            option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
          {
            estadoOpts.map((uf, index) => <Option key={index} value={uf.sigla}>{uf.estado} - {uf.sigla}</Option>)
          }
          </Select>
          {typeof errors.uf !== "undefined" ? <InputError message={errors.uf} /> : null }
        </div>
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Número:'
          id='billing-data-input-numero'
          type='text'
          inputMode='numeric'
          value={numero}
          required
          onChange={(e) => {
            const NUMERO = e.target.value.replace(/\D/g, '')
            .replace(/(\d{4})\d+?$/, '$1')
            handleChange(NUMERO, Types.BILLING_DATA_NUMERO)
          }}
          extra={typeof errors.numero !== "undefined" ? <InputError message={errors.numero} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Cidade:'
          id='billing-data-input-cidade'
          type='text'
          required
          value={cidade}
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_CIDADE)}
          extra={typeof errors.cidade !== "undefined" ? <InputError message={errors.cidade} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Bairro:'
          id='billing-data-input-bairro'
          type='text'
          required
          value={bairro}
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_BAIRRO)}
          extra={typeof errors.bairro !== "undefined" ? <InputError message={errors.bairro} /> : null }
        />
      </Col>
      <Col xs={24}>
        <InputGroup
          title='Complemento:'
          id='billing-data-input-complemento'
          type='text'
          required
          value={complemento}
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_COMPLEMENTO)}
          extra={typeof errors.complemento !== "undefined" ? <InputError message={errors.complemento} /> : null }
        />
      </Col>
    </Row>
  )
}

const mapStateToProps = createStructuredSelector({
  billingData: selectBillingData,
})

export default connect(mapStateToProps)(FormEnderecoCobranca);
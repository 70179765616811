// React & Router-Dom
import React, { useState, useEffect } from 'react'
import { withRouter, Link } from 'react-router-dom'
// Components
import { PageTitle, Jumbotron, ButtonOnsurance, DividerText, InputGroup } from '../../components'
// ANT-Design
import { Row, Col, Card, Button, Divider, Collapse } from 'antd'
// Redux
import { connect } from 'react-redux'
import { selectCotacao } from '../../redux/cotacao/cotacao.selector'
import { selectLoading, selectError } from '../../redux/checkout/checkout.selector'
import { selectRechargeEmail } from '../../redux/credit-recharge/credit-recharge.selector'
import RechargeTypes from '../../redux/credit-recharge/credit-recharge.types'
import { selectAssistencia } from '../../redux/assistencia24horas/assistencia.selector'
import { Types } from '../../redux/action'
import {
  handleChange, requestCotacao, requestDadosParaAssistencia
} from '../../redux/action'
import { requestDadosParaRecarga } from '../../redux/credit-recharge/credit-recharge.actions'
import { createStructuredSelector } from 'reselect'
// FontAwesome
import VoltarPurchase from './voltar-purchase.component'
// Assets
import AssistenciaImg from '../../../assets/assistencia-300x300.png'
import CreditoInicialImg from '../../../assets/credito-inicial-300x300.jpg'
import RecargaImg from '../../../assets/recarga-300x300.jpg'
// SCSS
import './purchase.style.scss'

/**
 * Página root da aplicação.
 */

const { Meta } = Card
const { Panel } = Collapse

// Função que verifica se existe os parâmetros do email e da cotação para fazer a requisição diretamente.
async function coletarParâmetrosURL(history, requestCotacao) {
  const { location: { search } } = history
  const params = new URLSearchParams(search);
  if (params.has('email') && params.has('cotacao')) {
    await requestCotacao({
      email: params.get('email'),
      cotacaoNumber: params.get('cotacao')
    }, history)
  }
}

// Rota: /
const PurchasePage = ({
  cotacao, handleChange, requestCotacao, requestDadosParaRecarga, assistencia,
  requestDadosParaAssistencia, rechargeEmail, ...props
}) => {
  const { assistenciaEmail } = assistencia
  const { cotacaoNumber, email } = cotacao
  const { history, loading } = props
  const [ localState, setLocalState ] = useState({
    possuiCotacao: false,
    isRecargaCreditos: false,
    ass24h: false
  })

  useEffect(() => {
    document.title = 'Comprar - Onsurance';
    handleChange(null, Types.LIMPAR_STATE_POR_COMPLETO);
    coletarParâmetrosURL(history, requestCotacao);
  }, [history, requestCotacao, handleChange])

  return (
    <Row className="purchase-page">
      <Col xs={24}>
        <PageTitle>
          Seguro liga/desliga
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron isDiv>
          <p style={{fontSize: '14px', marginBottom: '0.6em'}}>
            Na Onsurance é necessário fazer uma cotação (gratuita) para gerar os seus dados de cobrança,
            é simples e rápido!
          </p>
          <p style={{fontSize: '14px'}}>
            Caso já tenha uma cotação, verifique seu e-mail (caixa de entrada e spam).
          </p>
        </Jumbotron>
      </Col>
      {
        !localState.possuiCotacao && !localState.isRecargaCreditos && !localState.ass24h
        ?
          <Col xs={24} className='product-col'>
            <Divider orientation='left'>Produtos:</Divider>
          </Col>
        :
          null
      }
      {
        !localState.possuiCotacao && !localState.isRecargaCreditos && !localState.ass24h
        ?
        <>
          <Col xs={24} sm={12} md={8} className='section top product-col'>
            <div>
              <Card
                onClick={() => setLocalState({...localState, possuiCotacao: true})}
                className='product-card'
                hoverable
                style={{width: '100%'}}
                cover={
                  <div className='image-item-cover'>
                    <img alt='Crédito Inicial Onsurance' src={CreditoInicialImg} />
                    <Button type='primary' size='large' className='button-purchase-item'>Comprar</Button>
                  </div>
                }
              >
                <Meta
                  title='Crédito Inicial Onsurance'
                  description='Crédito inicial para ativar seu onsurance e usufruir da cobertura que liga/desliga.'
                />
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} className='section top product-col'>
            <div>
              <Card
                onClick={() => setLocalState({...localState, isRecargaCreditos: true})}
                className='product-card'
                hoverable
                style={{width: '100%'}}
                cover={
                  <div className='image-item-cover'>
                    <img alt='Recarga de Créditos' src={RecargaImg} />
                    <Button type='primary' size='large' className='button-purchase-item'>Comprar</Button>
                  </div>
                }
              >
                <Meta
                  title='Recarga de Créditos'
                  description='Precisa de mais créditos? Deseja trocar de veículo? Veja as opções de recarga aqui.'
                />
              </Card>
            </div>
          </Col>
          <Col xs={24} sm={12} md={8} className='section top product-col'>
            <div>
              <Card
                onClick={() => setLocalState({...localState, ass24h: true})}
                className='product-card'
                hoverable
                style={{width: '100%'}}
                cover={
                  <div className='image-item-cover'>
                    <img alt='Assistência 24h' src={AssistenciaImg} />
                    <Button type='primary' size='large' className='button-purchase-item'>Comprar</Button>
                  </div>
                }
              >
                <Meta
                  title='Adicionar Assistência 24h'
                  description='Serviços de Assistência 24h em todo o Brasil. Assinatura anual para sua comodidade.'
                />
              </Card>
            </div>
          </Col>
        </>
        :
        null
      }
      {
        localState.possuiCotacao
        ?
        <>
          <Col xs={24}>
            <div className='section-ja-possuo-cotacao'>
              <DividerText>
                Informe sua cotação
              </DividerText>
            </div>
          </Col>
          <Col className='section-numero-cotacao' xs={24}>
            <div className='d-flex justify-center'>
              <div style={{ maxWidth: '450px' }}>
                <Row>
                  <Col xs={24}>
                    <InputGroup
                      inputMode='numeric'
                      id='numeroCotacao'
                      title='Número da sua cotação:'
                      type='text'
                      value={cotacaoNumber}
                      required
                      onChange={(e) => {
                        const NUMERO_COTACAO = e.target.value.replace(/\D/g, '')
                        .replace(/(\d{10})\d+?$/, '$1')
                        handleChange(NUMERO_COTACAO, Types.COTACAO_NUMBER)
                      }}
                    />
                  </Col>
                  <Col xs={24}>
                    <InputGroup
                      inputMode='email'
                      id='emailCotacao'
                      required
                      title='E-mail:'
                      type='text'
                      extra='O mesmo e-mail utilizado na sua cotação'
                      value={email}
                      onChange={(e) => handleChange(e.target.value, Types.COTACAO_EMAIL)}
                    />
                  </Col>
                  <Col xs={24}>
                    <div className='d-flex justify-center'>
                      <ButtonOnsurance
                        type='confirm'
                        bloc
                        onClick={() => requestCotacao(cotacao, history)}
                        disabled={loading}
                      >
                        Confirmar
                      </ButtonOnsurance>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </>
      :
      localState.isRecargaCreditos ?
        <>
          <Col xs={24}>
            <div className='section-ja-possuo-cotacao'>
              <DividerText>
                Informe seu e-mail
              </DividerText>
            </div>
          </Col>
          <Col className='section-numero-cotacao'>
            <div className='d-flex justify-center'>
              <div style={{ maxWidth: '450px' }}>
                <Row>
                  <Col xs={24}>
                    <InputGroup
                      inputMode='email'
                      id='emailUser'
                      required
                      title='E-mail:'
                      type='text'
                      extra='Informe seu e-mail.'
                      value={rechargeEmail}
                      onChange={(e) => handleChange(e.target.value, RechargeTypes.EMAIL_RECHARGE)}
                      onKeyPress={e => e.key === 'Enter' ? requestDadosParaRecarga(rechargeEmail, history) : null}
                    />
                  </Col>
                  <Col xs={24}>
                    <div className='d-flex justify-center'>
                      <ButtonOnsurance
                        type='confirm'
                        bloc
                        onClick={() => requestDadosParaRecarga(rechargeEmail, history)}
                        disabled={loading}
                      >
                        Confirmar
                      </ButtonOnsurance>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </>
      :
      localState.ass24h ?
        <>
          <Col xs={24}>
            <div className='section-ja-possuo-cotacao'>
              <DividerText>
                Informe seu e-mail
              </DividerText>
            </div>
          </Col>
          <Col className='section-numero-cotacao'>
          <div className='d-flex justify-center'>
            <div style={{ maxWidth: '450px' }}>
              <Row>
                <Col xs={24}>
                  <InputGroup
                    inputMode='email'
                    id='emailUser'
                    required
                    title='E-mail:'
                    type='text'
                    extra='Informe seu e-mail.'
                    value={assistenciaEmail}
                    onChange={(e) => handleChange(e.target.value, Types.ASSISTENCIA_EMAIL)}
                    onKeyPress={e => e.key === 'Enter' ? requestDadosParaAssistencia(assistenciaEmail, history) : null}
                  />
                </Col>
                <Col xs={24}>
                  <div className='d-flex justify-center'>
                    <ButtonOnsurance
                      type='confirm'
                      bloc
                      onClick={() => {
                        requestDadosParaAssistencia(assistenciaEmail, history);
                      }}
                    >
                      Confirmar
                    </ButtonOnsurance>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          </Col>
        </>
      :
      null
      }
      {
        !localState.possuiCotacao && !localState.isRecargaCreditos && !localState.ass24h
        ?
        <Col xs={24}>
          <Divider orientation='left' style={{ marginBottom: 30, marginTop: 30 }}>Perguntas frequentes</Divider>
          <Collapse>
            <Panel header='Comprei um carro novo, como faço para trocar o Onsurance de veículo?' key='1'>
              <p>
                É simples! Basta fazer uma cotação para seu veículo novo. Feito isso volte nessa página e 
                escolha a opção Recarga. Na próxima tela escolha troca de veículo. Então basta selecionar o 
                veículo que deseja trocar (se ainda não estiver selecionado) e a cotação do novo veículo. Nosso 
                sistema fará os cálculos do crédito complementar caso seja necessário. Daí basta seguir as 
                orientações no sistema.
              </p>
            </Panel>
            <Panel header='Vendi meu carro, posso transferir o Onsurance junto?' key='2'>
              <p>
                Sem problemas, basta solicitar a transferência via e-mail para fale.conosco@onsurance.me
                juntamente com o e-mail do novo proprietário. (Manda a mesma mensagem para os 2 e-mails ao mesmo
                tempo, ok?) Então nosso time responderá com as orientações para confirmar a transferência.
              </p>
            </Panel>
            <Panel header='Já sou cliente. Posso comprar a assistência 24h agora ou é só durante a ativação do Onsurance?' key='3'>
              <p>
                Pode comprar sim. A assistência 24h é um opcional e vale por 1 ano. Então renove a sua para não
                ficar sem socorro de emergência.
              </p>
            </Panel>
          </Collapse>
        </Col>
        :
          null
      }
      <Col xs={24}>
        <Divider />
      </Col>
      <VoltarPurchase isMd={true} redirectObj={localState} backFunction={() => setLocalState({isRecargaCreditos: false, possuiCotacao: false})} />
      <Col xs={24} md={12}>
        <div className='d-flex justify-center pl-1'>
          <Link
            to='/cotacao'
            className='w-100 section top'
          >
            <ButtonOnsurance
              type='primary'
              bloc
            >
              Fazer nova cotação
            </ButtonOnsurance>
          </Link>
        </div>
      </Col>
      <VoltarPurchase redirectObj={localState} backFunction={() => setLocalState({isRecargaCreditos: false, possuiCotacao: false})} />
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  cotacao: selectCotacao,
  loading: selectLoading,
  error: selectError,
  assistencia: selectAssistencia,
  rechargeEmail: selectRechargeEmail
});

const mapDispathToProps =  dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type)),
  requestCotacao: (cotacao, history) => dispatch(requestCotacao(cotacao, history)),
  requestDadosParaRecarga: (email, history) => dispatch(requestDadosParaRecarga(email, history)),
  requestDadosParaAssistencia: (email, history) => dispatch(requestDadosParaAssistencia(email,history))
})

export default withRouter(connect(mapStateToProps, mapDispathToProps)(PurchasePage));
import { createSelector } from 'reselect'

/** 
 * Selectors do CheckoutReducer, essas funções retornam algum campo específico
 * do nosso estado(state).
*/

// Envia todos os campos do state CheckoutReducer
export const selectCheckout = state => state.checkout;

// Envia o campo loading baseado no seletor (selectCheckout)
export const selectLoading = createSelector(
  [selectCheckout],
  checkout => checkout.loading
);

// Envia o campo nome baseado no seletor (selectCheckout)
export const selectNome = createSelector(
  [selectCheckout],
  checkout => checkout.nome
);

// Envia o campo valorMontante baseado no seletor (selectCheckout)
export const selectValorMontante = createSelector(
  [selectCheckout],
  checkout => checkout.valorMontante
)

// Envia o campo itens baseado no seletor (selectCheckout)
export const selectItens = createSelector(
  [selectCheckout],
  checkout => checkout.itens
);

// Envia o campo Indicações baseado no seletor (selectCheckout)
export const selectIndicacoes = createSelector(
  [selectCheckout],
  checkout => checkout.indicacoes
);

// Envia a indicação selecionada baseado no seletor (selectCheckout)
export const selectIndication = createSelector(
  [selectCheckout],
  checkout => checkout.selectedIndication
);

// Envia os dados do cliente(nome, sobrenome e telefone).
export const selectDadosClienteRecarga = createSelector(
  [selectCheckout],
  data => {
    const { nome, sobrenome, telefone } = data
    return {nome, sobrenome, telefone}
  }
)

// Envia o valor montante e o juros em um objeto.
export const selectDadosParcela = createSelector(
  [selectCheckout],
  data => {
    const { valorMontante, metaData: { juros } } = data
    return {
      valorMontante,
      juros
    }
  }
)

// Envia um boolean indicando se existe apenas a assistência 24h no array de itens
export const selectIsAssistOnlyValue = createSelector(
  [selectItens],
  itens => {
    const itensNotAss24 = itens.filter(item => item.tipo !== 'Ass24')
    return itensNotAss24.length > 0 ? false : true
  }
);

// Envia um valor computado baseado no seletor (selectItens)
export const selectValorTotalItens = createSelector(
  [selectItens],
  itens => itens.reduce((total, current) => {
    return total += current.valor
  }, 0)
);

// Envia o campo removedItens baseado no seletor (selectCheckout)
export const selectRemovedItens = createSelector(
  [selectCheckout],
  checkout => checkout.removedItens
);

// Envia o campo metaData baseado no seletor (selectCheckout)
export const selectMetaData = createSelector(
  [selectCheckout],
  checkout => checkout.metaData
)

// Envia o campo confirmacaoPedido baseado no seletor (selectCheckout)
export const selectResultado = createSelector(
  [selectCheckout],
  checkout => checkout.confirmacaoPedido
)

// Envia o campo error baseado no seletor (selectCheckout)
export const selectError = createSelector(
  [selectCheckout],
  checkout => checkout.error
);

// Envia o campo itensRecomendados baseado no seletor (selectCheckout)
export const selectItensRecomendados = createSelector(
  [selectCheckout],
  checkout => checkout.itensRecomendados
)
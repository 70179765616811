import React from 'react'
import { Link } from 'react-router-dom'
// AntDesign
import { Row, Col, Table, Button } from 'antd'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons'
// Redux
import { connect } from 'react-redux'
import { selectNome, selectResultado } from '../../redux/checkout/checkout.selector'
import { createStructuredSelector } from 'reselect'
import { handleChange, Types } from '../../redux/action'
// Componentes
import { PageTitle, Receipt } from '../../components'
// Table column
import { columnsCreditCard, gerarDataSource } from './table-column'
import './result.style.scss'

const CobrancaExistenteCartao = ({ nome, resultado, handleChange }) => {

  const { cobranca: { pedidoWoo }, cobrancas } = resultado
  const dataSource = gerarDataSource(cobrancas);
  
  return (
     // Se for Cartão e já ter existido uma cobrança.
     <Row className='result-page'>
      <Col xs={24}>
        <PageTitle>
          Cobrança para {nome}
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Receipt numeroPedido={pedidoWoo} />
      </Col>
      <Col xs={24}>
        <div className='section top'>
          <p className='section'>Localizamos a cobrança em aberto no sistema:</p>
        </div>
      </Col>
      <Col xs={24}>
        <div className='section top bottom'>
          <Table columns={columnsCreditCard} dataSource={dataSource} pagination={{defaultPageSize: 4}} />
        </div>
      </Col>
      <Col xs={24}>
        <div className='section top'>
          <p style={{marginBottom: '0.55rem'}}>
            <small><span style={{color: '#FF8484'}}>* </span><b>Importante: O app <u>Messenger lite</u> não é suportado.</b></small>
          </p>
        </div>
      </Col>
      <Col xs={24}>
        <Row>
          <Col xs={24} sm={12}>
            <div className='arrows-container'>
              <div className='container-seta-vermelha section'>
                <div className='arrow_box'>
                  <p style={{fontSize: '15px'}}><b>Próximo passo:</b></p>
                  <p>(Clique no botão)</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={12}>
            <div className='section messenger-button-container'>
              <Button
                href='https://m.me/onsurance.me?ref=orderCotacaoNumber'
                target='_blank'
                rel='noopener noreferrer'
                type='primary'
                id='send-to-messenger-button'
              >
                <FontAwesomeIcon icon={faFacebookMessenger} /><span style={{paddingLeft: '10px'}}><b>Continuar no Messenger</b></span>
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
      <Col xs={24}>
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <div className="section">
              <Button
                block
                style={{transition: 'all 0.3s linear'}}
                size='large'
                onClick={() => handleChange(null, Types.LIMPAR_STATE_POR_COMPLETO)}
              >
                <Link style={{transition: 'all 0s linear'}} to='/'>
                  <FontAwesomeIcon icon={faArrowLeft} /> Novo Pedido
                </Link>
              </Button>
            </div>
          </Col>
          <Col xs={24} md={12}>
            <div className='section'>
              <Button
                block
                size='large'
                href='https://onsurance.me'
              >
                <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: '5px'}} /> Sair
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  nome: selectNome,
  resultado: selectResultado,
});

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(CobrancaExistenteCartao)
import React from 'react'
// Redux
import { connect } from 'react-redux'
import { selectLoading } from '../../redux/checkout/checkout.selector'
import { createStructuredSelector } from 'reselect'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
// SCSS
import './loading.style.scss'

const Loading = ({ loading }) => {
  return (
    <div className={`loading-section ${loading ? 'active' : ''}`}>
      <div className='spinner'>
        <FontAwesomeIcon icon={faSpinner} className='spin' size='4x' />
      </div>
    </div>
  );
}

const mapStateToProps = createStructuredSelector({
  loading: selectLoading
})

export default connect(mapStateToProps)(Loading);
import React, { useEffect } from 'react'
// Components
import CobrancaExistenteCartao from './cobranca-existente-cartao'
import CobrancaExistenteBoleto from './cobranca-existente-boleto'
import PrimeiraCobrancaBoleto from './primeira-cobranca-boleto'
import PrimeiraCobrancaCartao from './primeira-cobranca-cartao'
import CompraRecargaSimples from './compra-recarga-simples'
import CompraAssistencia from './compra-assistencia-24horas'
// Redux
import { connect } from 'react-redux'
import { selectResultado } from '../../redux/checkout/checkout.selector'
import { selectIsPagamentoPorCartao } from '../../redux/billing-data/billing-data.selector'
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { selectIsAssistencia } from '../../redux/assistencia24horas/assistencia.selector'
import { createStructuredSelector } from 'reselect'
import { message } from 'antd'
// SCSS
import './result.style.scss'

/**
 * Página de resultados.
 */

// Route: /pedido-confirmado
const ResultPage = ({ resultado, history, isCreditCard, isRecharge, isAssistencia }) => {

  const { initial, touched } = resultado
  // A variável touched é enviada pelo back-end quando uma cobrança já tiver sido efetuada.

  useEffect(() => {
    document.title = 'Resultado da compra - Onsurance'
    if (initial) {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      })
      history.replace('/');
    }
  }, [initial, history]);

  if (initial) return <></>; // initial é um boolean que verifica se o usuário tem os requisitos para estar nessa página
  /**
   * Essa informação vem do back-end, caso o usuário ja tiver feito um pedido com essa
   * cotação, esse atributo vai vir junto com a response do back-end
   * e vai devolver as telas de cobrança ja existente.
   */
  return touched !== undefined
  ?
    isCreditCard ?  // Se for Cartão e já ter existido uma cobrança.
      <CobrancaExistenteCartao />
      : // Se for Boleto e já ter existido uma cobrança.
      <CobrancaExistenteBoleto />
  : isRecharge ? // Se for um uma compra de recarga
    <CompraRecargaSimples />
  : isAssistencia ?
    <CompraAssistencia />
  : // se não for compra de recarga
    !isCreditCard ? // Se for Boleto e não ter existido uma cobrança prévia
        <PrimeiraCobrancaBoleto />
      : // Se for Boleto e não ter existido uma cobrança prévia
        <PrimeiraCobrancaCartao />
  
}

const mapStateToProps = createStructuredSelector({
  resultado: selectResultado,
  isCreditCard: selectIsPagamentoPorCartao,
  isRecharge: selectIsRecharge,
  isAssistencia: selectIsAssistencia
})

export default connect(mapStateToProps)(ResultPage);
import React from 'react'
import './page-title.style.scss'

export default ({ children }) => {
  return (
    <div className='d-flex justify-center'>
      <h2 className='page-title-h2'>
        { children }
      </h2>
    </div>
  );
}
import React, { useEffect } from 'react'
// AntDesign
import { Row, Col, Divider, message, Statistic } from 'antd'
// Components
import { PageTitle, Jumbotron, VeiculoAssistenciaItem, ButtonOnsurance } from '../../components'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectNome, selectMetaData } from '../../redux/checkout/checkout.selector'
import { selectVeiculosCadastradosAndSelected, validatingIfHasVehicle } from '../../redux/assistencia24horas/assistencia.selector'
import { onVeiculoCadastradoToSelected, onVeiculoSelectedToCadastrado } from '../../redux/action'
import './assistencia24horas.style.scss'


// Route: /assistencia-24-horas
const Assistencia24HorasPage = ({
  nome, veiculosCadastradosAndSelected, onVeiculoCadastradoToSelected, onVeiculoSelectedToCadastrado,
  history, validated, metaData
}) => {

  const { ass24 } = metaData
  const { veiculosCadastrados, veiculosSelecionados } = veiculosCadastradosAndSelected

  useEffect(() => {
    document.title = 'Assistência 24h - Onsurance'
    if (!validated) {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      })
      history.replace('/')
    }
  }, [validated, history]);

  return (
    <Row className='assistencia-24-page'>
      <Col xs={24}>
        <PageTitle>Assistência 24 Horas</PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          { nome }, Selecione o veículo em que deseja incluir a assistência 24 Horas. É necessário que
          inclua ao menos 1 veículo
        </Jumbotron>
      </Col>
      {
        veiculosSelecionados.length > 0
        ?
        <Col xs={24} className='section top'>
          <Divider orientation='left' style={{fontWeight: '400'}}>
            Veículos Selecionados:
          </Divider>
          <Row gutter={[0,16]}>
            {
              veiculosSelecionados.map((veiculo, key) => 
                <VeiculoAssistenciaItem
                  veiculo={veiculo}
                  key={key}
                  onClick={() => onVeiculoSelectedToCadastrado(veiculo)}
                  selected
                />
              )
            }
          </Row>
        </Col>
        :
        null
      }
      {
        veiculosCadastrados.length > 0
        ?
          <Col xs={24} className='section top'>
            <Divider orientation='left' style={{fontWeight: '400'}}>
              Veículos Cadastrados:
            </Divider>
            <Row gutter={[16,16]}>
              {
                veiculosCadastrados.map((veiculo, key) => 
                  <VeiculoAssistenciaItem
                    veiculo={veiculo}
                    key={key}
                    onClick={() => onVeiculoCadastradoToSelected(veiculo)}
                  />
              )}
            </Row>
          </Col>
        :
        null
      }
      <Col xs={24} className='section bottom'>
      <div className='d-flex justify-center'>
        <Statistic
          title='Valor Total'
          value={ass24 * veiculosSelecionados.length}
          formatter={value => value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}
        />
      </div>
      </Col>
      <Col xs={24} md={12} className='voltar-md'>
        <div className='d-flex justify-center section top final-content pr-1'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/confirmar-dados')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='d-flex justify-center section top'>
          <div className='pl-1 w-100'>
            <ButtonOnsurance
              type='confirm'
              bloc
              onClick={() => {
                history.push('/payment-method');
              }}
              disabled={veiculosSelecionados.length === 0}
            >
              Confirmar
            </ButtonOnsurance>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className='voltar-xs'>
        <div className='d-flex justify-center section top final-content'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/confirmar-dados')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  nome: selectNome,
  veiculosCadastradosAndSelected: selectVeiculosCadastradosAndSelected,
  validated: validatingIfHasVehicle,
  metaData: selectMetaData
})

const mapDispatchToProps = dispatch => ({
  onVeiculoCadastradoToSelected: item => dispatch(onVeiculoCadastradoToSelected(item)),
  onVeiculoSelectedToCadastrado: item => dispatch(onVeiculoSelectedToCadastrado(item))
})

export default connect(mapStateToProps, mapDispatchToProps)(Assistencia24HorasPage);
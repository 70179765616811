// Redux
import { combineReducers } from 'redux';
// Reducers
import CheckoutReducer from './checkout/checkout.reducer'
import CotacaoReducer from './cotacao/cotacao.reducer'
import BillingDataReducer from './billing-data/billing-data.reducer'
import Assistencia24Reducer from './assistencia24horas/assistencia.reducer'
import CreditRechargeReducer from './credit-recharge/credit-recharge.reducer'

// RootReducer, utilizamos o combineReducers, para combinar todos os reducers em 1 estado(state)
const RootReducer = combineReducers({
  checkout: CheckoutReducer,
  cotacao: CotacaoReducer,
  billingData: BillingDataReducer,
  assistencia: Assistencia24Reducer,
  creditRecharge: CreditRechargeReducer
});

export default RootReducer;
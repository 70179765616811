import React, { useState, useEffect } from 'react'
// Ant Design
import { Row, Col, Card, message } from 'antd'
// Components
import {
  OnsuranceRadioButton, PageTitle, Jumbotron, ButtonOnsurance, SimpleRecharge, ChangeVehicleRecharge,
  InitialAdjustRecharge
} from '../../components'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectNome } from '../../redux/checkout/checkout.selector'
import {
  selectLoadingRecharge, selectCotacaoNumberRecharge, selectTypeOfRecharge, selectMinValueOfRecharge
} from '../../redux/credit-recharge/credit-recharge.selector'
import { handleChange } from '../../redux/action'
import { onConfirmRechardPressed } from '../../redux/credit-recharge/credit-recharge.actions'
import RechargeTypes from '../../redux/credit-recharge/credit-recharge.types'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import './recarga-creditos.style.scss'

// Route: /recarga-de-creditos
const RecargaCreditosPage = ({
  history, nome, handleChange, typeOfRecharge, onConfirmRechardPressed, loading,
  cotacaoNumberRecharge, minValueRecharge
}) => {

  useEffect(() => {
    document.title = 'Recarga de Creditos - Onsurance';
    if (nome === '') {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      })
      history.replace('/')
    }
  }, [nome, history])

  const [ confirmChange, setChange ] = useState('');

  if(nome === '') return <></>
  return (
    <Row className='recarga-credito-page'>
      <Col xs={24}>
        <PageTitle>Recarga de Créditos</PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          { nome }, Informe a quantidade que deseja recarregar deslizando o slide ou inserindo o valor a baixo em reais.
          O valor é convertido automaticamente para você acompanhar o quanto está colocando de créditos.
        </Jumbotron>
      </Col>
      <Col xs={24} className='section top'>
        <Card
          title='Selecione o tipo da recarga:'
          size='small'
          bordered={false}
          bodyStyle={{padding:'12px 0px'}}
          headStyle={{padding: '0px'}}
        >
          <Row gutter={[16, 16]}>
            <Col xs={24} sm={8}>
              <OnsuranceRadioButton
                id='checkout-recarga-simples'
                checkValue={typeOfRecharge}
                name='typeOfRecharge'
                value='SIMPLES'
                onChange={e => {
                  handleChange(null, RechargeTypes.RESET_OPTS_RECARGA);
                  handleChange(e.target.value, RechargeTypes.TYPE_OF_RECHARGE);
                  setChange('');
                }}
              />
            </Col>
            <Col xs={24} sm={8}>
              <OnsuranceRadioButton
                id='checkout-recarga-troca-veiculo'
                checkValue={typeOfRecharge}
                name='typeOfRecharge'
                value='TROCA DE VEICULO'
                onChange={e => {
                  handleChange(null, RechargeTypes.RESET_OPTS_RECARGA);
                  handleChange(e.target.value, RechargeTypes.TYPE_OF_RECHARGE);
                  setChange('');
                }}
              />
            </Col>
            <Col xs={24} sm={8}>
              <OnsuranceRadioButton
                id='checkout-recarga-ajuste'
                checkValue={typeOfRecharge}
                name='typeOfRecharge'
                value='AJUSTE INICIAL'
                onChange={e => {
                  handleChange(null, RechargeTypes.RESET_OPTS_RECARGA);
                  handleChange(e.target.value, RechargeTypes.TYPE_OF_RECHARGE);
                  setChange('');
                }}
              />
            </Col>
          </Row>
        </Card>
      </Col>
      <SimpleRecharge />
      <ChangeVehicleRecharge confirmChange={confirmChange} setChange={setChange} />
      <InitialAdjustRecharge />
      <Col xs={24} md={12} className='voltar-md'>
        <div className='d-flex justify-center section top final-content pr-1'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/confirmar-dados')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
      <Col xs={24} md={12}>
        <div className='d-flex justify-center section top'>
          <div className='pl-1 w-100'>
            <ButtonOnsurance
              type='confirm'
              bloc
              onClick={() => {
                onConfirmRechardPressed(history);
              }}
              disabled={
                loading ||
                typeOfRecharge === 'TROCA DE VEICULO'
                ?
                  cotacaoNumberRecharge === ''
                  ?
                    true
                  :
                  cotacaoNumberRecharge.length > 0 && minValueRecharge === null
                  ?
                    false
                  :
                  confirmChange === '' || confirmChange === 'NÃO'
                  ?
                    true
                  :
                    false
                :
                typeOfRecharge === 'AJUSTE INICIAL'
                ?
                  cotacaoNumberRecharge === ''
                  ?
                    true
                  :
                    false
                :
                  false
              }
            >
              Confirmar
            </ButtonOnsurance>
          </div>
        </div>
      </Col>
      <Col xs={24} md={12} className='voltar-xs'>
        <div className='d-flex justify-center section top final-content'>
          <ButtonOnsurance
            bloc
            onClick={() => history.replace('/confirmar-dados')}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  )
}

const mapStateToProps = createStructuredSelector({
  nome: selectNome,
  typeOfRecharge: selectTypeOfRecharge,
  loading: selectLoadingRecharge,
  cotacaoNumberRecharge: selectCotacaoNumberRecharge,
  minValueRecharge: selectMinValueOfRecharge
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type)),
  onConfirmRechardPressed: history => dispatch(onConfirmRechardPressed(history))
})

export default connect(mapStateToProps, mapDispatchToProps)(RecargaCreditosPage);
import React from 'react'
// Ant Design
import { Row, Col, Select } from 'antd'
// Component
import InputGroup from '../onsurance-input/onsurance-input.component'
import InputError from '../input-error-messege/input-error-messege'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { Types } from '../../redux/action'
import { selectBillingData } from '../../redux/billing-data/billing-data.selector'
import './endereco-entrega.scss'

const { Option } = Select

const FormEnderecoEntrega = ({ billingData, handleChange, errors }) => {

  const {
    cep, enderecoEntrega, estadoEntrega, numeroEntrega, cidadeEntrega, bairroEntrega, complementoEntrega,
    estadoOpts
  } = billingData

  return (
    <Row gutter={16}>
      <Col xs={24} md={12}>
        <InputGroup
          title='CEP:'
          id='billing-data-input-cep'
          type='text'
          value={cep}
          required
          onChange={(e) => {
            const CEP = e.target.value.replace(/\D/g, '')
            .replace(/(\d{5})(\d)/, '$1-$2')
            .replace(/(-\d{3})\d+?$/, '$1')
            handleChange(CEP, Types.BILLING_DATA_CEP)
          }}
          extra={typeof errors.cepEntrega !== "undefined" ? <InputError message={errors.cepEntrega} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Endereço para entrega:'
          id='billing-data-input-endereco-entrega'
          type='text'
          required
          value={enderecoEntrega}
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_ENDERECO_ENTREGA)}
          extra={typeof errors.enderecoEntrega !== "undefined" ? <InputError message={errors.enderecoEntrega} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <div className="billing-data-select-container">
          <label><span className='required-input'>* </span>Estado para entrega:</label>
          <Select
            className='select-estados-combobox'
            onChange={(e) => handleChange(e, Types.BILLING_DATA_ESTADO_ENTREGA)}
            placeholder='Selecione o seu estado'
            value={estadoEntrega}
            showSearch
            filterOption={(input, option) => 
            option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children[1].toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
            option.children[2].toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
          {
            estadoOpts.map((uf, index) => <Option key={index} value={uf.sigla}>{uf.estado} - {uf.sigla}</Option>)
          }
          </Select>
          {typeof errors.ufEntrega !== "undefined" ? <InputError message={errors.ufEntrega} /> : null }
        </div>
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Número para entrega:'
          id='billing-data-input-numero-entrega'
          type='text'
          value={numeroEntrega}
          required
          onChange={(e) => {
            const NUMERO = e.target.value.replace(/\D/g, '')
            .replace(/(\d{4})\d+?$/, '$1')
            handleChange(NUMERO, Types.BILLING_DATA_NUMERO_ENTREGA)
          }}
          extra={typeof errors.numeroEntrega !== "undefined" ? <InputError message={errors.numeroEntrega} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Cidade para entrega:'
          id='billing-data-input-cidade-entrega'
          type='text'
          value={cidadeEntrega}
          required
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_CIDADE_ENTREGA)}
          extra={typeof errors.cidadeEntrega !== "undefined" ? <InputError message={errors.cidadeEntrega} /> : null }
        />
      </Col>
      <Col xs={24} md={12}>
        <InputGroup
          title='Bairro para entrega:'
          id='billing-data-input-bairro-entrega'
          type='text'
          value={bairroEntrega}
          required
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_BAIRRO_ENTREGA)}
          extra={typeof errors.bairroEntrega !== "undefined" ? <InputError message={errors.bairroEntrega} /> : null }
        />
      </Col>
      <Col xs={24} md={24}>
        <InputGroup
          title='Complemento para entrega:'
          id='billing-data-input-complemento-entrega'
          type='text'
          required
          value={complementoEntrega}
          onChange={(e) => handleChange(e.target.value, Types.BILLING_DATA_COMPLEMENTO_ENTREGA)}
          extra={typeof errors.complementoEntrega !== "undefined" ? <InputError message={errors.complementoEntrega} /> : null }
        />
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  billingData: selectBillingData
})

export default connect(mapStateToProps)(FormEnderecoEntrega);
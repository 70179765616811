import React from 'react'
import { withRouter } from 'react-router-dom'
// Ant-Design
import { Col, Row, notification } from 'antd'
// Components
import {
  FormEnderecoCobranca, FormQuantidadeParcelas, MesmoEndereco, PodeTerFrete, CreditCard,
  DividerText, ButtonOnsurance, Receipt
} from '../../components'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { handleChange, Types, onClickComprar } from '../../redux/action'
import {
  selectBillingData, selectBillingDataErrors , selectCobrancaValid, selectEntregaValid,
  selectParcelar, selectCreditCartValidation
} from '../../redux/billing-data/billing-data.selector'
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import { selectIsAssistOnlyValue } from '../../redux/checkout/checkout.selector'
import { selectMetaData, selectValorMontante, selectItens, selectValorTotalItens } from '../../redux/checkout/checkout.selector'
import { selectIsAssistencia } from '../../redux/assistencia24horas/assistencia.selector'
// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

const PagamentoCartao = ({
  billingData, valorMontante, errors, metaData, handleChange, paymentMethod, history,
  cobrancaValid, entregaValid, parcelarValid, isOnlyAss24, cartaoValid, onClickComprar,
  isRecharge, isAssistencia
}) => {

  const { isParcelado, isEnderecoEntrega, tipoFrete: { valor } } = billingData
  const { juros, entrada } = metaData

  return (
    <>
      <Col xs={24}>
        <div className='section top bottom'>
          <DividerText>
            Dados do cartão
          </DividerText>
        </div>
      </Col>
      <Col xs={24}>
        {/* Dados do cartão de crédito */}
        <div className="section">
          <CreditCard />
        </div>
      </Col>
      <Col xs={24}>
        <div className='section top bottom'>
          <DividerText>
            Endereço de pagamento
          </DividerText>
        </div>
      </Col>
      <Col xs={24}>
        <div className="section">
          <FormEnderecoCobranca handleChange={handleChange} errors={errors} />
        </div>
      </Col>
      <MesmoEndereco
        types={Types}
        handleChange={handleChange}
        isEnderecoEntrega={isEnderecoEntrega}
        errors={errors}
      />
      <PodeTerFrete paymentMethod={paymentMethod} />
      {
        isParcelado === 'SIM'
        ?
        <Col xs={24}>
          <div className="section bottom">
            <FormQuantidadeParcelas
              handleChange={handleChange}
              paymentMethod='cartao'
              juros={juros}
              valorMontante={valorMontante}
              entrada={entrada}
            />
          </div>
        </Col>
        :
        null
      }
      <Col xs={24}>
        <Receipt />
      </Col>
      <Col xs={24}>
        <div className='section top bottom'>
          <Row gutter={32}>
            <Col xs={12}>
              <ButtonOnsurance
                bloc
                onClick={() => {
                  handleChange(false, Types.PAYMENT_METHOD_CARD)
                  history.goBack();
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Voltar
              </ButtonOnsurance>
            </Col>
            <Col xs={12}>
              {
                isRecharge || isAssistencia ? // Caso o produto for RECARGA DE CRÉDITOS e ou ASSISTÊNCIA 24h
                <ButtonOnsurance
                  bloc
                  type='primary'
                  id='gerar-cobranca'
                  title='Confirmar a compra'
                  onClick={async () => {
                    const condition = (cartaoValid && cobrancaValid && parcelarValid)
                    if (condition) {
                      await onClickComprar(history, paymentMethod);
                    } else {
                      notification.warning({
                        message: 'Preencha os campos obrigatórios'
                      });
                    }
                  }}
                >
                  Gerar Cobrança
                </ButtonOnsurance>
                : // Caso o produto não for RECARGA DE CRÉDITOS
                <ButtonOnsurance
                  bloc
                  title='Confirmar a compra'
                  type='primary'
                  id='gerar-cobranca'
                  onClick={async () => {
                    const condition = (
                      cartaoValid ||
                      cobrancaValid ||
                      entregaValid ||
                      parcelarValid ||
                      !isOnlyAss24 // Caso os créditos iniciais não possuir somente Ass24h.
                      ?
                        valor === 0
                        ? 
                          false
                        :
                          (parcelarValid && entregaValid && cobrancaValid && cartaoValid)
                      :
                        false
                    )
                    if (condition) {
                      await onClickComprar(history, paymentMethod);
                    } else {
                      notification.warning({
                        message: 'Preencha os campos obrigatórios'
                      });
                    }
                  }}
                >
                  Gerar Cobrança
                </ButtonOnsurance>
              }
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  billingData: selectBillingData,
  valorMontante: selectValorMontante,
  metaData: selectMetaData,
  errors: selectBillingDataErrors,
  cobrancaValid: selectCobrancaValid,
  entregaValid: selectEntregaValid,
  parcelarValid: selectParcelar,
  cartaoValid: selectCreditCartValidation,
  isOnlyAss24: selectIsAssistOnlyValue,
  itens: selectItens,
  valorTotalItens: selectValorTotalItens,
  isRecharge: selectIsRecharge,
  isAssistencia: selectIsAssistencia
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type)),
  onClickComprar: (history, paymentMethod) => dispatch(onClickComprar(history, paymentMethod))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PagamentoCartao));
import { createSelector } from 'reselect'
import { selectIsAssistOnlyValue } from '../checkout/checkout.selector'
import { selectIsAssistencia } from '../assistencia24horas/assistencia.selector'
import { selectIsRecharge } from '../credit-recharge/credit-recharge.selector'
import { notification } from 'antd'

/** 
 * Selectors do BillingDataReducer, essas funções retornam algum campo específico
 * do nosso estado(state).
*/

// Envia todos os campos do state billingData
export const selectBillingData = state => state.billingData;

// Envia o valor de loading
export const selectLoadingBilling = createSelector(
  [selectBillingData],
  billing => billing.loading
)

// Envia o objeto errors do state billingData
export const selectBillingDataErrors = createSelector(
  [selectBillingData],
  data => data.errors
)

// Envia os dados do cartão de crédito
export const selectCreditCard = createSelector(
  [selectBillingData],
  data => data.creditCard
)

// Envia o boolean informando se a forma de pagamento é por cartão.
export const selectIsPagamentoPorCartao = createSelector(
  [selectCreditCard],
  data => data.isCreditCard
)

export const selectQuantidadeParcelas = createSelector(
  [selectBillingData],
  data => {
    const { qtdParcela, isParcelado} = data
    return {
      qtdParcela,
      isParcelado
    }
  }
)

export const selectCpf = createSelector(
  [selectBillingData],
  data => data.cpf
)

// Seleciona o tipo do frete.
const selectTipoFrete = createSelector(
  [selectBillingData],
  data => data.tipoFrete
)

export const selectValorFrete = createSelector(
  [selectTipoFrete],
  data => data.valor
)

// Envia um boolean informando se os dados da seção endereço de cobrança estão válidos
export const selectCreditCartValidation = createSelector(
  [selectCreditCard],
  data => {
    let MY_BOOLEAN = true;
    try {
      if (data.nome === '') MY_BOOLEAN = false
      if (data.number === '') MY_BOOLEAN = false
      if (data.validThru === '') MY_BOOLEAN = false
      if (data.validThru) {
        if (data.validThru.length < 5) MY_BOOLEAN = false
      }
      if (data.cvc === '') MY_BOOLEAN = false
      if (data.cvc) {
        if (data.cvc.length < 3) MY_BOOLEAN = false
      }
    } catch(e) {
      notification.error({message: 'Erro interno', description: 'Ocorreu um erro no sistema, tente novamente mais tarde.'})
      MY_BOOLEAN = false
    }
    return MY_BOOLEAN
  }
)

// Envia um boolean informando se os dados da seção endereço de cobrança estão válidos
export const selectCobrancaValid = createSelector(
  [selectBillingData],
  data => {
    let MY_BOOLEAN = true;
    try {
      if (data.cpf) {
        if (data.cpf.length < 14) MY_BOOLEAN = false
      }
      if (data.endereco === '') MY_BOOLEAN = false
      if (data.endereco) {
        if (data.endereco.length < 5) MY_BOOLEAN = false
      }
      if (data.estado === '') MY_BOOLEAN = false
      if (data.numero === '') MY_BOOLEAN = false
      if (data.cidade === '') MY_BOOLEAN = false
      if (data.cidade) {
        if (data.cidade.length < 3) MY_BOOLEAN = false
      }
      if (data.bairro === '') MY_BOOLEAN = false
      if (data.bairro) {
        if (data.bairro.length < 2) MY_BOOLEAN = false
      }
      if (data.complemento === '') MY_BOOLEAN = false
    } catch(e) {
      notification.error({message: 'Erro interno', description: 'Ocorreu um erro no sistema, tente novamente mais tarde.'})
    }
    return MY_BOOLEAN
  }
)

// Envia um boolean informando se os dados da seção endereço de entrega estão válidos
export const selectEntregaValid = createSelector(
  [selectBillingData, selectIsAssistOnlyValue],
  (data, IsAss24OnlyValue) => {
    let MY_BOOLEAN = true;
    if (data.isEnderecoEntrega === 'SIM') return true;
    if (IsAss24OnlyValue && data.isEnderecoEntrega === '') return true;
    if (data.cep === '') MY_BOOLEAN = false;
    if (data.enderecoEntrega === '') MY_BOOLEAN = false;
    if (data.estadoEntrega === '') MY_BOOLEAN = false;
    if (data.numeroEntrega === '') MY_BOOLEAN = false;
    if (data.cidadeEntrega === '') MY_BOOLEAN = false;
    if (data.bairroEntrega === '') MY_BOOLEAN = false;
    if (data.complementoEntrega === '') MY_BOOLEAN = false;
    return MY_BOOLEAN
  }
)

// Envia um boolean informando se os dados da seção parcelar boleto e tipo de frete estão válidos
export const selectParcelar = createSelector(
  [selectBillingData, selectIsAssistOnlyValue, selectIsRecharge, selectIsAssistencia],
  (data, isAssistOnlyValue, isRecharge, isAssistencia) => {
    let MY_BOOLEAN = true;

    // a var isAssistOnlyValue representa se nos créditos inicials possui apenas ass24h

    if (data.isParcelado === '') {
      if (isRecharge || isAssistencia || !isAssistOnlyValue) {
        MY_BOOLEAN = false
      } else {
      }
    }
    return MY_BOOLEAN;
  }
)
// React
import React, { useEffect } from 'react';
// Components
import { PageTitle, Jumbotron, DividerText, CardCotacao, ButtonOnsurance } from '../../components'
// FontAwesom
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCar, faCalendarAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// Ant Design
import { Row, Col } from 'antd'
// SCSS
import './cotacao.style.scss'

/**
 * Página das cotações.
 */

// Rota: /cotacao
const CotacaoPage = ({ history }) => {

  useEffect(() => {
    document.title = 'Cotações - Onsurance'
  }, [])

  return (
    <Row className="cotacao-page">
      <Col xs={24}>
        <PageTitle>
          Serviços
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          Selecione a Cotação desejada.
        </Jumbotron>
      </Col>
      <Col xs={24}>
        <div className="section">
          <Row gutter={32}>
            <Col xs={24} md={12}>
              <div>
                <a href='https://cotacao.onsurance.me/' target='_blank' rel="noopener noreferrer">
                  <CardCotacao
                    titulo='Automóvel'
                    icon={faCar}
                    color='azul'
                  >
                    Realize uma cotação para seu veículo pessoal e/ou da sua organização.
                  </CardCotacao>
                </a>
              </div>
              <DividerText sm />
            </Col>
            <Col xs={24} md={12}>
              <div>
                  <CardCotacao
                    titulo='Em breve'
                    icon={faCalendarAlt}
                    color='cinza'
                  >
                    Mais uma novidade na Onsurance para você, fique atento!
                  </CardCotacao>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
      <Col xs={24}>
        <div className='section'>
          <ButtonOnsurance
            bloc
            onClick={() => history.goBack()}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Voltar
          </ButtonOnsurance>
        </div>
      </Col>
    </Row>
  );
}

export default CotacaoPage;
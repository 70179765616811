import React from 'react'
// AntDesign
import { Row, Col, Card, Collapse, Form, Input, Button } from 'antd'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { handleChange } from '../../redux/action'
import { selectCreditRecharge } from '../../redux/credit-recharge/credit-recharge.selector'
import RechargeTypes from '../../redux/credit-recharge/credit-recharge.types'
import './initial-adjust-recharge.style.scss'

const { Panel } = Collapse

const InitialAdjustRecharge = ({ creditRecharge, handleChange, confirmChange, setChange }) => {

  const {
    cotacaoNumberRecharge, minValueRecharge, loadingRecharge, typeOfRecharge, valueOfRecharge,
    walletCreditsRecharge
  } = creditRecharge

  if (typeOfRecharge !== 'AJUSTE INICIAL') return null;
  return (
    <>
    <Col xs={24} className='section'>
      <Card
        title='Perguntas frequentes'
        size='small'
        bordered={false}
        bodyStyle={{padding: '12px 0px'}}
        headStyle={{padding: '0'}}
      >
        <Collapse>
          <Panel header='Quando devo usar essa modalidade de recarga?' key='1'>
            <p>
              Caso haja algum erro na compra dos seus créditos iniciais, sendo necessária a compra de
              mais créditos para ajustar o saldo de ativação do seu Onsurance ao mínimo necessário.
            </p>
          </Panel>
          <Panel header='É preciso fazer uma nova cotação?' key='2'>
            <p>
              Sim, normalmente nosso time de análise já realiza os ajustes necessários e cria uma nova
              cotação para você, que chega no seu e-mail. Daí basta digitar o número abaixo para prosseguir.
            </p>
          </Panel>
        </Collapse>
      </Card>
    </Col>
    <Col xs={24} className='section top'>
      <Card size='small'
        title='Ajuste inicial'
        extra={
          <Button href='https://cotacao.onsurance.me/' target='_blank' type='primary'>
            Fazer nova cotação
          </Button>
        }
      >
        <Form layout='vertical'>
          <Row>
            <Col xs={24}>
              <Form.Item
                label='Número da nova cotação:'
                htmlFor='numeroCotacaoDeAjuste'
              >
                <Input
                  id='numeroCotacaoDeAjuste'
                  type='text'
                  inputMode='numeric'
                  value={cotacaoNumberRecharge}
                  required
                  onChange={(e) => {
                    const NUMBER_COTACAO = e.target.value.replace(/\D/g, '')
                    .replace(/(\d{10})\d+?$/, '$1')
                    handleChange(NUMBER_COTACAO, RechargeTypes.COTACAO_NUMBER_RECHARGE)
                  }}
                  style={{width: '100%'}}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Col>
    {
        minValueRecharge !== null || loadingRecharge ?
        <Col xs={24} className='section top'>
          <Card
          size='small'
          loading={loadingRecharge}
          >
            <Form className='form-select-value-container' layout='vertical'>
              {
                walletCreditsRecharge !== null
                ?
                  <p style={{marginBottom: '10px'}}>
                    O seu saldo atual é de <span className='initial-adjust-rechard-wallet'>{(walletCreditsRecharge * 1000).toFixed(2)}</span> créditos. Para realizar uma recarga de ajuste inicial
                    é necessário fazer uma recarga mímima de {minValueRecharge.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}.
                  </p>
                :
                  null
              }
              <Form.Item label='Digite o valor:' extra={`Valor Mínimo: ${parseFloat(minValueRecharge).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})}`}>
                <Input
                  onChange={e => {
                    const VALOR = e.target.value.replace(/\D/g, '')
                    handleChange(VALOR === '' ? 0 : parseInt(VALOR), RechargeTypes.VALUE_OF_RECHARGE)
                  }}
                  inputMode='numeric'
                  onBlur={e => parseInt(e.target.value.replace(/\D/g, '')) < minValueRecharge ? handleChange(minValueRecharge, RechargeTypes.VALUE_OF_RECHARGE) : null }
                  onPressEnter={e => parseInt(e.target.value.replace(/\D/g, '')) < minValueRecharge ? handleChange(minValueRecharge, RechargeTypes.VALUE_OF_RECHARGE) : null }
                  value={parseFloat(valueOfRecharge).toLocaleString('pt-BR')}
                  prefix='R$'
                  suffix=',00'
                />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        :
        null
      }
    </>
  );
}

const mapStateToProps = createStructuredSelector({
  creditRecharge: selectCreditRecharge,
})

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(InitialAdjustRecharge);
import React from 'react'
// Componentes / antd
import { Link } from 'react-router-dom'
import { Row, Col, Button } from 'antd'
import { PageTitle, Jumbotron, Receipt } from '../../components'
// Fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt, faArrowLeft } from '@fortawesome/free-solid-svg-icons'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectResultado } from '../../redux/checkout/checkout.selector'
import { handleChange, Types } from '../../redux/action'


const CompraAssistencia = ({ resultado, handleChange }) => {
  const { cobranca: { pedidoWoo, boleto, invoiceUrl, status } } = resultado

  return (
    <Row className='result-page'>
      <Col xs={24}>
        <PageTitle>
          Compra de Assistência 24h
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          Após a confirmação do pagamento você irá receber pelo messenger os detalhes da sua compra.
        </Jumbotron>
      </Col>
      <Col xs={24}>
        <Receipt numeroPedido={pedidoWoo} status={status} />
      </Col>
      <Col xs={24}>
        <div className="section top">
          <iframe src={boleto !== undefined ? boleto : invoiceUrl } width="100%" height="600px" title="boletoGerado">
            <p>O seu navegador não suporta Iframes.</p>
          </iframe>
        </div>
      </Col>
      <Col xs={24}>
        <div className="section top">
          <Row gutter={[16,16]}>
            <Col xs={24} md={12}>
              <div className="section">
                <Button
                  block
                  size='large'
                  style={{transition: 'all 0.3s linear'}}
                  onClick={() => handleChange(null, Types.LIMPAR_STATE_POR_COMPLETO)}
                >
                  <Link style={{transition: 'all 0s linear'}} to='/'>
                    <FontAwesomeIcon icon={faArrowLeft} /> Novo Pedido
                  </Link>
                </Button>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className='section'>
                <Button
                block
                href='https://onsurance.me'
                size='large'
                >
                  <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: '5px'}} /> Sair
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  resultado: selectResultado
});

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type))
});

export default connect(mapStateToProps, mapDispatchToProps)(CompraAssistencia);
import React from 'react'
import { Row, Col, Card } from 'antd'
import ImgCarro from '../../../assets/carro.png'
import ImgMoto from '../../../assets/moto.png'
import ImgCaminhonete from '../../../assets/caminhonete.png'
import './quote-item.style.scss'
import moment from 'moment';

const CotacaoItem = ({ cotacao, onClick, selected }) => {
  const { Created_Time, Minute_fee, Brand, Third_Party_Coverage,
    Factory, Activation_Credit, Year_Model, Vehicle_Type, Franchise, Subject,
    Plate, Model } = cotacao
  return (
    <Col xs={24}>
      <Card hoverable onClick={onClick} className={selected ? 'selected' : null}>
        <Row gutter={6}>
          <Col xs={24} sm={24}>
            <div className="card-header">
              <p>
                <b>Data da cotação</b>: {moment(Created_Time).format("DD/MM/YYYY [às] hh:mm")}
              </p>
            </div>
          </Col>
        </Row>
        <Row gutter={6}>
          <Col xs={24} sm={24}>
            <div className='assistencia-image-container'>
              {
                Vehicle_Type.toLowerCase() === 'carro'
                ?
                  <img src={ImgCarro} alt='Carro' />
                :
                Vehicle_Type.toLowerCase() === 'moto'
                ?
                  <img src={ImgMoto} alt='Moto' />
                :
                  <img src={ImgCaminhonete} alt='Caminhonete' />
              }
            </div>
          </Col>
          <Col xs={24}>
            <div className="image-description">
              <p style={{ textAlign: 'center' }}><b>{Brand} {Model}</b></p>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <div className='data-col-item'>
              <div>
                <p>
                  <b>Valor do minuto</b>: {Minute_fee}.
                </p>
                <p>
                  <b>Número da cotação</b>: {Subject}
                </p>
                <p>
                  <b>Placa</b>: {Plate}.
                </p>
                <p>
                  <b>Fabricação</b>: {Factory}.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12}>
            <div className='data-col-item'>
              <div>
                <p>
                  <b>Crédito inicial</b>: {Activation_Credit}.
                </p>
                <p>
                  <b>Cobertura para Terceiros</b>: {Third_Party_Coverage}.
                </p>
                <p>
                  <b>Ano</b>: {Year_Model}.
                </p>
                <p>
                  <b>Franquia</b>: {Franchise}.
                </p>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={24}>
            {
              selected
              ?
              <p className='assistencia-remove-button'>Remover</p>
              :
              <p className='assistencia-select-button'>Selecionar</p>
            }
          </Col>
        </Row>
      </Card>
    </Col>
  )
}

export default CotacaoItem;
// React
import React, { useEffect } from 'react'
// Redux
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectBillingData } from '../../redux/billing-data/billing-data.selector'
import { selectMetaData } from '../../redux/checkout/checkout.selector'
// Components
import { Jumbotron, PageTitle } from '../../components'
import PagamentoBoleto from './pagamento-boleto'
import PagamentoCartao from './pagamendo-cartao'
// Ant Design
import { Row, Col, message } from 'antd'
// SCSS
import './billing-data.style.scss'

/**
 * Página do formulário de dados do pagamento.
 */

// Rota: /billing-data
const BillingDataPage = ({
  history, metaData, match,
}) => {
  const { params: { paymentMethod }} = match

  useEffect(() => {
    document.title = 'Dados para cobrança - Onsurance'
    if (metaData === '') {
      message.open({
        content: 'Atualize os dados para continuar',
        duration: 4,
        type: 'info',
      })
      history.replace('/')
    }
    if (!(paymentMethod === 'boleto' || paymentMethod === 'cartao')) history.push('/page-not-found')
  }, [metaData, history, paymentMethod]);

  if (metaData === '') return <></>
  return (
    <Row className="billing-data-page">
      <Col xs={24}>
        <PageTitle>
          Dados para a cobrança
        </PageTitle>
      </Col>
      <Col xs={24}>
        <Jumbotron>
          Complete seus dados abaixo:
        </Jumbotron>
      </Col>
      {
        paymentMethod === 'boleto'
        ?
        <PagamentoBoleto paymentMethod={paymentMethod} />
        :
        <PagamentoCartao paymentMethod={paymentMethod} />
      }
    </Row>
  );
}

const mapStateToProps = createStructuredSelector({
  billingData: selectBillingData,
  metaData: selectMetaData
})

export default connect(mapStateToProps)(BillingDataPage);
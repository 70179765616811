import React, { useState } from 'react';
import { Modal, Checkbox, Button } from 'antd';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect'
import { selectMetaData } from '../../redux/checkout/checkout.selector';
import { selectIsAssistencia } from '../../redux/assistencia24horas/assistencia.selector';
import { selectIsRecharge } from '../../redux/credit-recharge/credit-recharge.selector';
import { Types, handleChange } from '../../redux/action';
import AsaasSVG from '../../../assets/asaas.svg';
import './modal-confirmation.scss';

const ModalRechargeAssistencia = ({ juros, checked, onChange, visible, setVisible, handleChange, paymentMethod }) => {
  return (
    <Modal
      title="Regras deste parcelamento:"
      visible={visible}
      closable={false}
      maskClosable={false}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }} key='footer'>
          <div style={{ flex: '1 0 auto' }}>
            <div style={{ display: 'flex', justifyContent: 'start', height: '100%', alignItems: 'center' }}>
              <img src={AsaasSVG} alt='Asaas' style={{ width: '100%', maxWidth: '80px' }} />
            </div>
          </div>
          <div style={{ flex: '1 0 auto'}}>
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
              <Button onClick={setVisible}>
                Cancelar
              </Button>
              <Button
                type='primary'
                disabled={!checked}
                onClick={() => {
                  handleChange('SIM', Types.BILLING_DATA_IS_PARCELADO);
                  setVisible();
                }}
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      ]}
    >
      <ul className='modal-ul'>
        <li>
          O pagamento parcelado possui juros de <b>{juros.toLocaleString('pt-BR')}%</b> A.M.
        </li>
        {
          paymentMethod === 'boleto'
          ?
            <li>
              Parcelamentos de <b>até 3 vezes no Boleto, sem juros</b>!
            </li>
          :
            null
        }
        {
          paymentMethod === 'boleto'
          ?
            <li>
              Acima de 3x, acrescemos uma taxa de <b>{(3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} por boleto</b>.
            </li>
          :
            null
        }
        <li>
          Os pagamentos são realizados pela plataforma Asaas.
        </li>
      </ul>
      <Checkbox
        onChange={onChange}
        checked={checked}
      >Concordo com estas condições.</Checkbox>
    </Modal>
  );
}

const ModalConfirmation = ({ metaData, visible, setVisible, handleChange, isAssistencia, isRecharge, paymentMethod }) => {
  const { entrada, juros } = metaData;
  const [checked, check] = useState(false);

  const onChange = e => {
    check(!checked);
  }

  if(isRecharge || isAssistencia)
    return (
      <ModalRechargeAssistencia
        visible={visible}
        checked={checked}
        handleChange={handleChange}
        juros={juros}
        onChange={e => onChange(e)}
        setVisible={setVisible}
        paymentMethod={paymentMethod}
      />
    );
  else
    return (
      <Modal
        title="Regras deste parcelamento:"
        visible={visible}
        closable={false}
        maskClosable={false}
        footer={[
          <div style={{ display: 'flex', justifyContent: 'start', flexWrap: 'wrap' }} key='footer'>
            <div style={{ flex: '1 0 auto' }}>
              <div style={{ display: 'flex', justifyContent: 'start', height: '100%', alignItems: 'center' }}>
                <img src={AsaasSVG} alt='Asaas' style={{ width: '100%', maxWidth: '80px' }} />
              </div>
            </div>
            <div style={{ flex: '1 0 auto'}}>
              <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button onClick={setVisible}>
                  Cancelar
                </Button>
                <Button
                  type='primary'
                  disabled={!checked}
                  onClick={() => {
                    handleChange('SIM', Types.BILLING_DATA_IS_PARCELADO);
                    setVisible();
                  }}
                >
                  Ok
                </Button>
              </div>
            </div>
          </div>
        ]}
      >
        <ul className='modal-ul'>
          {
            paymentMethod === 'boleto'
            ?
              <li>
                Para fazer o pagamento parcelado é cobrada uma <b>entrada de { entrada.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }</b>
              </li>
            :
              null
          }
          <li>
            O pagamento parcelado possui juros de <b>{juros.toLocaleString('pt-BR')}%</b> A.M.
          </li>
          {
            paymentMethod === 'boleto'
            ?
              <li>
                Parcelamentos de <b>até 3 vezes no Boleto, sem juros</b>!
              </li>
            :
              null
          }
          {
            paymentMethod === 'boleto'
            ?
              <li>
                Acima de 3x, acrescemos uma taxa de <b>{(3).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})} por boleto</b>.
              </li>
            :
              null
          }
          <li>
            Os pagamentos são realizados pela plataforma Asaas.
          </li>
        </ul>
        <Checkbox
          onChange={onChange}
          checked={checked}
        >Concordo com estas condições.</Checkbox>
      </Modal>
    );
}

const mapStateToProps = createStructuredSelector({
  metaData: selectMetaData,
  isAssistencia: selectIsAssistencia,
  isRecharge: selectIsRecharge
});

const mapDispatchToProps = dispatch => ({
  handleChange: (item, type) => dispatch(handleChange(item, type))
})

export default connect(mapStateToProps, mapDispatchToProps)(ModalConfirmation);
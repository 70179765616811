import { Types, base64Decoder } from '../action'

// Nosso Estado defualt, ou seja o valor inicial.
// Iniciamos os valores para não ocorrer nenhum erro na hora em que fazemos a busca
// Desses valores.
const INITIAL_STATE = {
  nome: '',
  sobrenome: '',
  telefone: '',
  cep: '',
  itens: [],
  removedItens: [],
  itensRecomendados: [],
  loading: false,
  metaData: '',
  valorMontante: null,
  referralId: null,
  indicacoes: [],
  selectedIndication: null,
  confirmacaoPedido: {
    initial: true,
    desc: '',
    cobranca: {
      invoiceUrl: '',
      boleto: '',
      pedidoWoo: null,
      status: '',
      parcelas: '',
      vencimento: ''
    },
    cobrancas: [],
    code: null
  }
}

//Mock Data:
// const INITIAL_STATE = {
//   nome: 'Gabriel',
//   sobrenome: 'de Oliveira Lopes',
//   telefone: '61982352349',
//   cep: '71996-215',
//   itens: [
//     {
//       nome: "Crédito inicial para Carro",
//       tipo: "Carro",
//       valor: 1199
//     },
//     {
//       nome: "Assistência 24 Horas",
//       tipo: "Ass24",
//       valor: 249
//     }
//   ],
//   removedItens: [],
//   itensRecomendados: [],
//   indicacoes: [
//     {B_nus: 200,Owner:{name: "Gabriel Lopes", "id":"3209222000016740001","email":"gabriel.lopes@onsurance.me"},"Email":"testemoto@onsurance.me","$currency_symbol":"R$","Telefone":"61982352349","$review_process":{"approve":false,"reject":false,"resubmit":false},"Data_de_indica_o":"2020-10-26","Name":"Gabriel Lopes","Last_Activity_Time":"2020-11-26T10:30:20-03:00","$review":null,"$state":"save","Unsubscribed_Mode":null,"$process_flow":false,"Currency":"BRL","id":"3209222000029527008","$approved":true,"Status":"Convite enviado","$approval":{"delegate":false,"approve":false,"reject":false,"resubmit":false},"Modified_Time":"2020-11-26T10:30:19-03:00","Created_Time":"2020-11-26T10:30:19-03:00","Unsubscribed_Time":null,"$editable":true,"$orchestration":false,"$in_merge":false,"Indicante":{"name":"Vitor Melo","id":"3209222000027622075"},"Tag":[],"$approval_state":"approved"},{"B_nus":200,"Owner":{"name":"Gabriel Lopes","id":"3209222000016740001","email":"gabriel.lopes@onsurance.me"},"Email":"testemoto@onsurance.me","$currency_symbol":"R$","Telefone":"61982352349","$review_process":{"approve":false,"reject":false,"resubmit":false},"Data_de_indica_o":"2020-09-28","Name":"Gabriel de Oliveira Lopes","Last_Activity_Time":"2020-10-28T10:37:11-03:00","$review":null,"$state":"save","Unsubscribed_Mode":null,"$process_flow":false,"Currency":"BRL","id":"3209222000028118312","$approved":true,"Status":"Realizou cotação","$approval":{"delegate":false,"approve":false,"reject":false,"resubmit":false},"Modified_Time":"2020-10-28T10:37:11-03:00","Created_Time":"2020-10-28T10:36:04-03:00","Unsubscribed_Time":null,"$editable":true,"$orchestration":false,"$in_merge":false,"Indicante":{"name":"Gabriel Lopes teste","id":"3209222000021439048"},"Tag":[],"$approval_state":"approved"}
//   ],
//   selectedIndication: null,
//   loading: false,
//   metaData: {
//     ass24: 249,
//     entrada: 498,
//     juros: 2.19
//   },
//   valorMontante: null,
//   referralId: null,
//   confirmacaoPedido: {
//     // touched: true,
//     initial: true,
//     desc: '',
//     cobranca: {
//       invoiceUrl: "",
//       pedidoWoo: null,
//       status: "",
//       vencimento: ""
//     },
//     cobrancas: [],
//     code: null
//   }
// }

// Esse é o nosso Reducer.
// Recebe o estado(state) como primeiro parâmetro e a action como segundo parâmetro.
// Caso o tipo(type) da action for algum correspondente a esse reducer, o estado vai sofrer
// Alterações conforme especificado no bloco.
// Caso o tipo não corresponder, retornamos o estado sem nenhuma alteração.
const CheckoutReducer = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case Types.SET_CHECKOUT_ITENS: {
      let temAssist = false;
      const { itens, indicacoes } = action.payload;
      let selectedIndication = null;

      itens.forEach(iten => {
        if (iten.tipo === 'Ass24') {
          temAssist = true;
          return;
        }
        if (iten.tipo === 'RecargaSimples') {
          temAssist = true;
          return;
        }
        if (iten.tipo === 'RecargaTrocaDeVeiculo') {
          temAssist = true;
          return;
        }
        if (iten.tipo === 'RecargaAjusteInicial') {
          temAssist = true;
          return;
        }
      });

      if(indicacoes !== undefined && indicacoes !== null) {
        console.log('Indicacoes =>', indicacoes);
        if(indicacoes.length === 1) {
          selectedIndication = indicacoes[0];
        }
      }

      if (temAssist === false) {
        return {
          ...state,
          ...action.payload,
          itensRecomendados: [{
            nome: "Assistência 24 Horas",
            tipo: "Ass24",
            valor: 249
          }],
          removedItens: state.removedItens,
          loading: false,
          referralId: state.referralId,
          selectedIndication
        }
      }
      return {
        ...state,
        ...action.payload,
        removedItens: [],
        itensRecomendados: [],
        loading: false,
        referralId: state.referralId,
        selectedIndication
      }
    }
    
    case Types.REQUEST_END:
      return {
        ...state,
        loading: false
      }

    case Types.REQUEST_START:
      return {
        ...state,
        loading: true
      }

    case Types.REQUEST_JUROS_ENTRADA_END:
      return {
        ...state,
        metaData: action.payload
      }

    case Types.CHECKOUT_VALOR_MONTANTE:
      return {
        ...state,
        valorMontante: action.payload,
        loading: false
      }

    case Types.CHECKOUT_CONFIRMACAO_PEDIDO:
      return {
        ...state,
        confirmacaoPedido: action.payload,
        loading: false
      }

    case Types.CHECKOUT_REMOVE_CART_ITEM:
      return {
        ...state,
        itens: action.payload
      }

    case Types.CHECKOUT_ADD_REMOVED_ITEM:
      return {
        ...state,
        removedItens: [...state.removedItens, action.payload]
      }

    case Types.CHECKOUT_ADD_CART_ITEM:
      return {
        ...state,
        itens: [...state.itens, action.payload]
      }

    case Types.CHECKOUT_REMOVE_REMOVED_ITEM:
      return {
        ...state,
        removedItens: action.payload
      }

    case Types.CHECKOUT_RECOMENDADOS_ADD:
      return {
        ...state,
        itensRecomendados: [],
        itens: [...state.itens, action.payload]
      }

    case Types.CHECKOUT_AFFILIATE_ID:
      return {
        ...state,
        referralId: action.payload
      }

    case Types.CHECKOUT_DADOS_PARA_RECARGA:
      return {
        ...state,
        cep: base64Decoder(action.payload.cep),
        nome: base64Decoder(action.payload.nome),
        sobrenome: base64Decoder(action.payload.sobrenome),
        telefone: base64Decoder(action.payload.telefone)
      }

    case Types.DATA_EMAIL_ASSISTENCIA:
      return {
        ...state,
        nome: action.payload.nome,
        sobrenome: action.payload.sobrenome,
        telefone: action.payload.telefone,
        cep: action.payload.cep
      }

    case Types.SET_INDICATED:
      return {
        ...state,
        selectedIndication: action.payload
      }

    case Types.LIMPAR_STATE_POR_COMPLETO:
      return INITIAL_STATE

    default:
      return state;
  }
}

export default CheckoutReducer;
import { createSelector } from 'reselect'

export const selectCreditRecharge = state => state.creditRecharge;

// Retorna um boolean indicando se é uma recarga.
export const selectIsRecharge = createSelector(
  [selectCreditRecharge],
  data => data.isRecharge
);

// Retorna o email da recarga.
export const selectRechargeEmail = createSelector(
  [selectCreditRecharge],
  data => data.emailRecharge
);

export const selectTypeOfRecharge = createSelector(
  [selectCreditRecharge],
  data => data.typeOfRecharge
);

export const selectMinValueOfRecharge = createSelector(
  [selectCreditRecharge],
  data => data.minValueRecharge
);

export const selectCotacaoNumberRecharge = createSelector(
  [selectCreditRecharge],
  data => data.cotacaoNumberRecharge
);

export const selectValueOfRecharge = createSelector(
  [selectCreditRecharge],
  data => data.valueOfRecharge
);

export const selectLoadingRecharge = createSelector(
  [selectCreditRecharge],
  data => data.loadingRecharge
);
import React from 'react';
import moment from 'moment';
import { Types } from '../../redux/action'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-regular-svg-icons'
import './indicante-item.scss';

export default function IndicanteItem ({ item, active, onClick }) {
    const nomeArray = item.Indicante.name.split(' ');
    return (
        <div className={`indicante-item-container ${active ? 'active' : null}`}
            onClick={() => onClick(item, Types.SET_INDICATED)}
        >
            <div className="indicante-item-description">
                <p style={{ color: active ? '#1890FF' : '#000' }}>{`${nomeArray[0]} ${nomeArray[nomeArray.length - 1]}`}</p>
                <p style={{ color: active ? '#1890FF' : '#000' }}>{moment(item.Created_Time).format("DD/MM/YYYY [às] HH:mm:ss")}</p>
                <p style={{ color: active ? '#1890FF' : '#000' }}>Bônus de <b>{item.B_nus}</b> horas</p>
            </div>
            <FontAwesomeIcon color={active ? '#1890FF' : '#000'} style={{ height: 74, width: 64, marginLeft: 10 }} icon={faUser} />
        </div>
    );
}
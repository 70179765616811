export default {
  IS_RECHARGE: 'IS_RECHARGE',
  EMAIL_RECHARGE: 'EMAIL_RECHARGE',
  TYPE_OF_RECHARGE: 'TYPE_OF_RECHARGE',
  VALUE_OF_RECHARGE: 'VALUE_OF_RECHARGE',
  MIN_VALUE_RECHARGE: 'MIN_VALUE_RECHARGE',
  LOADING_RECHARGE: 'LOADING_RECHARGE',
  COTACAO_NUMBER_RECHARGE: 'COTACAO_NUMBER_RECHARGE',
  RESET_OPTS_RECARGA: 'RESET_OPTS_RECARGA',
  CHECKOUT_DADOS_PARA_RECARGA: 'CHECKOUT_DADOS_PARA_RECARGA',
  LIMPAR_STATE_POR_COMPLETO: 'LIMPAR_STATE_POR_COMPLETO',
  SELECT_VEHICLE_FROM_VEHICLES_ARRAY: 'SELECT_VEHICLE_FROM_VEHICLES_ARRAY',
  SELECT_QUOTE_FROM_QUOTES_ARRAY: 'SELECT_QUOTE_FROM_QUOTES_ARRAY',
  RESET_OPTS_QUOTE_RECARGA: 'RESET_OPTS_QUOTE_RECARGA',
  NEW_VEHICLE_FROM_COT: 'NEW_VEHICLE_FROM_COT',
  SET_WALLET_CREDITS_RECHARGE: 'SET_WALLET_CREDITS_RECHARGE',
  SET_IS_TAXED: 'SET_IS_TAXED'
}
import { Types, handleChange, base64Decoder, baseURL } from '../action'
import CreditRechargeTypes from './credit-recharge.types'
import { fetchGet, fetchPost } from '../utils'
import { notification, message } from 'antd'

// Recebe o E-mail do usuário e devolve os seus dados de confirmação para compra de créditos.
export const requestDadosParaRecarga = (email, history) => async dispatch => {
  document.body.style.overflow = 'hidden';
  dispatch(handleChange(null, Types.REQUEST_START))
  if (email === '') email = 'seuemail@onsurance.me'
  await fetchGet(`${baseURL}/checkclient/${email}`)
  .then(data => {
    const { message } = data
    switch (data.code) {
      case 400:
        notification.error({
          message: 'Usuário não encontrado',
          description: message
        });
        dispatch(handleChange(null, Types.REQUEST_END));
        document.body.style.overflow = 'unset';
        break;

        default:
          const { cpf } = data
          dispatch(handleChange(cpf === undefined ? '' : base64Decoder(cpf), Types.BILLING_DATA_CPF));
          dispatch(handleChange(data, Types.CHECKOUT_DADOS_PARA_RECARGA));
          dispatch(handleChange(null, Types.REQUEST_END));
          document.body.style.overflow = 'unset';
          history.push('/confirmar-dados');
        break;
    }
  })
  .catch(e => {
    notification.error({
      message: 'Falha na comunicação',
      description: 'Estamos com um problema ao conectar com nossos servidores, tente novamente mais tarde'
    });
    dispatch(handleChange(null, Types.REQUEST_END))
    document.body.style.overflow = 'unset';
  })
}

// Action utilizada para verificar o tipo da recarga e agir de acordo com sua regra de negócio
export const onConfirmRechardPressed = history => async (dispatch, getState) => {
  const { 
    creditRecharge: {
      typeOfRecharge, valueOfRecharge, cotacaoNumberRecharge, emailRecharge, minValueRecharge, isTaxed,
      vehicleSelectForChange: { placa }
    }
  } = getState();

  if (minValueRecharge !== null) { // Caso ele já ter realizado a requisição do valor mínimo para recarga.
    switch (typeOfRecharge) {
      case 'TROCA DE VEICULO': {
        dispatch(handleChange({
          itens: [
            {
              nome: 'Recarga de troca de veículo',
              tipo: 'RecargaTrocaDeVeiculo',
              valor: valueOfRecharge,
              isTaxed: isTaxed
            }
          ]
        }, Types.SET_CHECKOUT_ITENS));
        break;
      }

      case 'AJUSTE INICIAL': {
        dispatch(handleChange({
          itens: [
            {
              nome: 'Recarga de ajuste inicial',
              tipo: 'RecargaAjusteInicial',
              valor: valueOfRecharge
            }
          ]
        }, Types.SET_CHECKOUT_ITENS));
        break;
      }
    
      default: {
        dispatch(handleChange({
          itens: [
            {
              nome: 'Recarga de créditos simples',
              tipo: 'RecargaSimples',
              valor: valueOfRecharge
            }
          ]
        }, Types.SET_CHECKOUT_ITENS));
        break;
      }
    }
    history.push('/payment-method');
    window.scrollTo({top: 0, behavior: 'smooth'});
    return;
  }

  /*
   *  Vai ser verificado qual o tipo da recarga que o usuário vai fazer;
   *  Caso seja diferente de recarga simples, vai ser realizado uma consulta no back-end para
   *  verificar o quanto de crédito inicial vai ser necessário ser efetuado; 
   */
  switch (typeOfRecharge) {
    case 'AJUSTE INICIAL': {
      dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
      const body = {
        email: emailRecharge,
        cotacao: cotacaoNumberRecharge
      }
      await fetchPost(`${baseURL}/initialadjustmentvalue`, body)
      .then(data => {
        const { code } = data
        switch (code) {
          case 400: {
            const { message } = data
            notification.warning({
              message
            });
            dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
            return;
          }

          default: {
            const { minValue, veiculo, walletCredits } = data
            dispatch(handleChange(minValue, CreditRechargeTypes.MIN_VALUE_RECHARGE))
            dispatch(handleChange(minValue, CreditRechargeTypes.VALUE_OF_RECHARGE))
            dispatch(handleChange(veiculo, CreditRechargeTypes.NEW_VEHICLE_FROM_COT));
            dispatch(handleChange(walletCredits, CreditRechargeTypes.SET_WALLET_CREDITS_RECHARGE));
            dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
            break;
          }
        }
      })
      .catch(e => {
        console.error(e);
        dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
        notification.error({
          message: 'Falha na comunicação',
          description: 'Estamos com um problema ao conectar com nossos servidores, tente novamente mais tarde'
        });
      });
      return
    }

    case 'TROCA DE VEICULO': {
      dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
      const body = {
        email: emailRecharge,
        cotacao: cotacaoNumberRecharge,
        placa: placa
      }
      await fetchPost(`${baseURL}/vehiclechangeminvalue`, body)
      .then(data => {
        const { code } = data
        switch (code) {
          case 400: {
            const { message } = data
            notification.warning({
              message
            });
            dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
            return;
          }
        
          default:{
            const { minValue, veiculo, isTaxed } = data;
            dispatch(handleChange(minValue, CreditRechargeTypes.MIN_VALUE_RECHARGE));
            dispatch(handleChange(minValue, CreditRechargeTypes.VALUE_OF_RECHARGE));
            dispatch(handleChange(veiculo, CreditRechargeTypes.NEW_VEHICLE_FROM_COT));
            dispatch(handleChange(isTaxed, CreditRechargeTypes.SET_IS_TAXED));
            dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
            break;
          }
        }
      })
      .catch(e => {
        console.error(e);
        dispatch(handleChange(null, CreditRechargeTypes.LOADING_RECHARGE));
        notification.error({
          message: 'Falha na comunicação',
          description: 'Estamos com um problema ao conectar com nossos servidores, tente novamente mais tarde'
        });
      });
      return
    }
  
    default: {
      dispatch(handleChange({
        itens: [
          {
            nome: 'Recarga de créditos simples',
            tipo: 'RecargaSimples',
            valor: valueOfRecharge
          }
        ]
      }, Types.SET_CHECKOUT_ITENS));
      history.push('/payment-method');
      window.scrollTo({top: 0, behavior: 'smooth'});
      return;
    }
  }
}

// Action para selecionar o veículo para a recarga de troca.
export const onVehicleOfChangeRechargeSelected = item => dispatch => {
  dispatch(handleChange(item, CreditRechargeTypes.SELECT_VEHICLE_FROM_VEHICLES_ARRAY));
  const { modelo } = item
  message.success({
    content: `${modelo} selecionado`,
    duration: 2
  })
}

// Action para remover o veículo selecionado da recarga de troca.
export const unselectVehicleFromRecharge = () => dispatch => {
  dispatch(handleChange(null, CreditRechargeTypes.RESET_OPTS_RECARGA));
  message.success({
    content: 'Veículo removido',
    duration: 2
  })
}

// Action para selecionar a cotação para a recarga de troca.
export const onQuoteClickOfChangeRechargeSelected = item => dispatch => {
  dispatch(handleChange(item, CreditRechargeTypes.SELECT_QUOTE_FROM_QUOTES_ARRAY));
  const { Subject } = item
  message.success({
    content: `Cotação ${Subject} selecionada`,
    duration: 2
  })
}

// Action para remover a cotação selecionada da recarga de troca.
export const unselectQuoteFromRecharge = () => dispatch => {
  dispatch(handleChange(null, CreditRechargeTypes.RESET_OPTS_QUOTE_RECARGA));
  message.success({
    content: 'Cotação removida',
    duration: 2
  })
}